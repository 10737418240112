import * as React from "react"
import { Code, Menu, X } from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import PremiumTutorialModal from "../Dashboard/PremiumTutorialModal"
import { useTheme } from "../../Contexts/ThemeContext"
import { Sun, Moon } from "lucide-react"
export default function SubscriptionHeader() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [mounted, setMounted] = React.useState(false)
  const isDesktop = useMediaQuery("(min-width: 640px)")
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const navigate = useNavigate()  
  
  const { isDarkMode, toggleDarkMode } = useTheme();

  React.useEffect(() => {
    setMounted(true)
  }, [])

  const menuItems = [
    {
      label: "Home",
      href: "/",
      color: "text-purple-500"
    },
    { 
      label: "Get Help 24/7", 
      href: "https://t.me/codeforcambodia_support", 
      color: "text-orange-500"
    },
  ]

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  if (!mounted) return null

  return (
    <div className="font-mono">
      <PremiumTutorialModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />

      <div className="bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800">
        <div className="container mx-auto px-4">
          <div className="flex flex-col sm:flex-row items-center justify-between py-2 space-y-2 sm:space-y-0">
            <p className="text-xs sm:text-sm text-white font-medium Nokora flex items-center flex-wrap justify-center">
              <span className="animate-pulse bg-yellow-300 text-black font-semibold rounded-full px-2 sm:px-3 py-0.5 mr-2 mb-1 sm:mb-0">
                ⚡ FLASH SALE!
              </span>
              <span className="bg-green-400 text-white font-semibold rounded-full px-2 sm:px-3 py-0.5 mr-2 mb-1 sm:mb-0">
                $4 OFF!
              </span>
              <span className="bg-orange-300 text-black font-semibold rounded-full px-2 sm:px-3 py-0.5 mr-2 mb-1 sm:mb-0">
                CODE4FUTURE
              </span>
              <span>
                តម្លៃពិសេសត្រឹមតែ <span className="font-bold mx-1 line-through">$6.99</span>
                <span className="font-bold mx-1 text-yellow-200">$2.99/month</span> ប៉ុណ្ណោះ!
              </span>
            </p>
            <button 
              onClick={() => navigate("/subscription")}
              className="text-sm sm:text-base Nokora bg-yellow-300 text-black font-semibold hover:bg-yellow-200 px-3 sm:px-4 py-1 sm:py-1.5 rounded-full transition-all duration-200 flex items-center group animate-bounce"
            >
              បង់ឥឡូវនេះ! 🔥
              <span className="transform translate-x-0 group-hover:translate-x-1 transition-transform duration-200 ml-1">→</span>
            </button>
          </div>
        </div>
      </div>

      <header className={`${isDarkMode ? "bg-customBodyDarken2" : "bg-white"} py-2 sm:py-4 shadow-sm transition-shadow duration-300 ease-in-out hover:shadow-md`}>
        <div className="container mx-auto flex items-center justify-between px-4">
          <Link to="/" className="group flex items-center space-x-2">
            <Code className="h-6 w-6 sm:h-8 sm:w-8 text-orange-500 transition-transform duration-300 ease-in-out group-hover:rotate-12" />
            <span className="text-lg sm:text-xl font-semibold">
              <span className={`${isDarkMode ? "text-blue-400" : "text-blue-500"}  transition-colors duration-300 ease-in-out group-hover:text-blue-600`}>Code</span>
              <span className={`${isDarkMode ? "text-blue-400" : "text-blue-500"}  transition-colors duration-300 ease-in-out group-hover:text-blue-600`}>For</span>
              <span className={`${isDarkMode ? "text-blue-400" : "text-blue-500"}  transition-colors duration-300 ease-in-out group-hover:text-blue-600`}>Cambodia</span>
              <span className="text-orange-500 transition-colors duration-300 ease-in-out group-hover:text-orange-600">;</span>
            </span>
          </Link>

          {isDesktop ? (
            <nav className="flex items-center space-x-6">
              {menuItems.map((item) => (
                <Link
                  key={item.href}
                  to={item.href}
                  className={`text-base ${item.color} hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-y-0.5`}
                >
                  {item.label}
                </Link>
              ))}

              <Link
                to="/subscription"
                className="rounded-md bg-red-500 px-6 py-2 text-base font-medium text-white transition-all duration-300 ease-in-out hover:bg-red-600 hover:-translate-y-0.5 hover:shadow-sm"
              >
                🔥 Limited Time Offer
              </Link>
              <button onClick={toggleDarkMode} >
                {isDarkMode ? <Moon className="text-white size-5 opacity-75 hover:opacity-40 transition-all duration-100" /> : <Sun className="text-gray-600 opacity-50 hover:opacity-90 size-5 hover:text-gray-600 transition-all duration-100" />}
              </button>
            </nav>
          ) : (
            <button
              onClick={toggleMenu}
              className="rounded-md p-2 text-purple-500 hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300 ease-in-out hover:rotate-180"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
              <span className="sr-only">Toggle menu</span>
            </button>
          )}
        </div>

        {!isDesktop && (
          <nav className={`container mx-auto mt-2 space-y-2 px-4 overflow-hidden transition-all duration-300 ease-in-out ${isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
            }`}>
            {menuItems.map((item, index) => (
              <Link
                key={item.href}
                to={item.href}
                className={`block py-1.5 text-sm ${item.color} hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1`}
                onClick={toggleMenu}
                style={{ transitionDelay: `${index * 50}ms` }}
              >
                {item.label}
              </Link>
            ))}

            <div className="py-2">
              <button onClick={toggleDarkMode} className={`flex items-center text-sm text-base  ${isDarkMode ? "text-customBodyText" : "text-gray-600 "}  gap-2 hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1`}>
                {isDarkMode ? <Moon className="text-white size-5 opacity-75 hover:opacity-40 transition-all duration-100" /> : <Sun className="text-gray-600 opacity-50 hover:opacity-90 size-5 hover:text-gray-600 transition-all duration-100" />}
                {isDarkMode ? "Dark mode" : "Light mode"}
              </button>

            </div>
            <Link
              to="/subscription"
              className="block text-center rounded-md bg-red-500 px-4 py-1.5 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-red-600 hover:-translate-y-0.5 hover:shadow-sm"
              onClick={toggleMenu}
              style={{ transitionDelay: `${menuItems.length * 50}ms` }}
            >
              🔥 Limited Time Offer
            </Link>
          </nav>
        )}
      </header>
    </div>
  )
}

function useMediaQuery(query) {
  const [matches, setMatches] = React.useState(false)

  React.useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [matches, query])

  return matches
}