import React, { useEffect, useState } from 'react'
import { Check, X, ChevronDown, ChevronUp } from 'lucide-react'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'

SyntaxHighlighter.registerLanguage('html', html)

export default function VerificationResult({ verificationResult, error, isOpen, onClose }) {
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  if (!isOpen) return null

  const renderVerificationResult = () => {
    if (!verificationResult) return null
    const { correct, message, output } = verificationResult
    return (
      <div className="mt-4 font-mono text-sm space-y-4">
        <div className="flex items-center space-x-2">
          {correct ? (
            <Check className="text-green-500 flex-shrink-0" size={20} />
          ) : (
            <X className="text-red-500 flex-shrink-0" size={20} />
          )}
          <span className={`font-semibold ${correct ? 'text-green-700' : 'text-red-700'}`}>
            {correct ? 'Success!' : 'Error'}
          </span>
        </div>
        <p className={`${correct ? 'text-green-600' : 'text-red-600'}`}>{message}</p>
        {output && (
          <div className="mt-4">
            <div className="bg-gray-100 rounded-t border border-gray-200 p-2 flex justify-between items-center">
              <span className="font-semibold text-gray-700">Output</span>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-blue-500 hover:text-blue-700 focus:outline-none"
              >
                {isExpanded ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </button>
            </div>
            <div className={`bg-gray-100 rounded-b border-x border-b border-gray-200 overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[600px]' : 'max-h-8'}`}>
              <SyntaxHighlighter 
                language="html" 
                style={docco} 
                customStyle={{
                  margin: 0,
                  padding: '1rem',
                  maxHeight: isExpanded ? '600px' : '12rem',
                  overflow: 'auto'
                }}
                wrapLines={true}
                lineProps={{style: {whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}}
              >
                {output}
              </SyntaxHighlighter>
            </div>
            {!isExpanded && (
              <button
                onClick={() => setIsExpanded(true)}
                className="mt-2 text-blue-500 hover:text-blue-700 focus:outline-none"
              >
                Show More
              </button>
            )}
          </div>
        )}
      </div>
    )
  }

  const renderError = () => {
    if (!error) return null
    return (
      <div className="font-mono text-sm space-y-4">
        <h2 className="text-lg font-bold text-red-600">Error</h2>
        <p className="text-gray-700">{error}</p>
      </div>
    )
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" >
      <div className="bg-white w-full max-w-2xl rounded-lg shadow-lg overflow-hidden ">
        <div className="bg-gray-100 px-4 py-2 flex items-center justify-between border-b border-gray-200 ">
          <div className="flex space-x-2">
            <div className="w-3 h-3 rounded-full bg-red-500" onClick={onClose}/>
            <div className="w-3 h-3 rounded-full bg-yellow-500" onClick={onClose}/>
            <div className="w-3 h-3 rounded-full bg-green-500" onClick={onClose}/>
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <X size={20} />
          </button>
        </div>
        <div className="p-6 max-h-[80vh] overflow-y-auto">
          <h2 className="text-xl text-black font-semibold mb-6 font-mono">
            {error ? 'Error' : 'Verification Result'}
          </h2>
          {error ? renderError() : renderVerificationResult()}
        </div>
      </div>
    </div>
  )
}