'use client'

import React, { useEffect, useState } from 'react';
import { Terminal, Code, FileText } from 'lucide-react';
import parse from 'html-react-parser';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';
import Footer from '../../Components/Footer';
import options from '../../Components/Lessons/OptionsQuill';
import { useTheme } from '../../Contexts/ThemeContext';
import Draggable from 'react-draggable';
import JupyterLiteEmbed from '../../Components/Lessons/Jupyter/JupyterLab';
import DraggableJupyter from '../../lib/DraggableJupyter';
const JupyterTab = React.memo(({ active, onClick, children, onDrag, onActiveDrags, activeDrags }) => {
  const { isDarkMode } = useTheme();
  const [dragged, setDragged] = useState(false);

  const handleDragStart = () => {
    setDragged(false); // Reset the dragged state
    onActiveDrags(true);
  };
  const handleDrag = () => {
    setDragged(true); // Mark as dragged
  };
  const handleDragStop = (e) => {
    onActiveDrags(false);
    setDragged(false);
    onDrag(dragged);
  }
  return (
    <Draggable onStart={handleDragStart} onStop={handleDragStop} onDrag={handleDrag}>
      <button
        onClick={onClick}
        className={`drop-target px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm z-10 ${isDarkMode ? "bg-customBodyDarken1AndHalf" : ""
          } font-medium border-b-2 flex-1 ${active
            ? "text-blue-600 border-blue-500"
            : `${isDarkMode
              ? "text-gray-300 hover:text-gray-200"
              : "text-gray-600 hover:text-gray-800"
            } border-transparent`
          } ${activeDrags ? "cursor: cursor-grabbing" : ""}`}

      >
        {children}
      </button>
    </Draggable>
  );
})
const LessonTab = React.memo(({ active, onClick, children, isWindow }) => {
  const { isDarkMode } = useTheme();
  return (
    <>
      <button
        onClick={onClick}
        className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm ${isDarkMode ? "bg-customBodyDarken1AndHalf" : ""
          } font-medium border-b-2 flex-1 ${active
            ? "text-blue-600 border-blue-500"
            : `${isDarkMode
              ? "text-gray-300 hover:text-gray-200"
              : "text-gray-600 hover:text-gray-800"
            } border-transparent`
          }`}
        disabled={isWindow}
      >
        {children}
      </button>
    </>
  );
})
export default function JupyterLesson({ lesson, isSubscribed }) {
  const [activeSection, setActiveSection] = useState(lesson?.instructions ? 'instructions' : 'notebook');
  const { isDarkMode } = useTheme();
  const [isDrag, setIsDrag] = useState(false);
  const [fullTab, setFullTab] = useState(false);
  const [activeDrags, setActiveDrags] = useState(false);
  const handleIsDragChange = (value) => {
    setIsDrag(value);
  };

  const handleFullTab = (value) => {
    setFullTab(value);
  }
  const handleActiveDrags = (value) => {
    setActiveDrags(value);
  }
  useEffect(() => {
    if (fullTab) {
      setActiveSection('instructions');
    }
  }, [fullTab])

  return (
    <div className={`flex flex-col ${isDarkMode ? "bg-customBody" : "bg-white"}  `}>
      {isSubscribed ? <CodeForCambodiaHeader /> : <FreeTrialHeader />}
      <div className={`flex flex-col ${isDarkMode ? "bg-customBody h-screen" : "bg-white h-screen"} bg-white text-gray-800 font-sans `}>
        {lesson?.instructions && !isDrag && (
          <div className="border-b border-gray-200 flex w-full">
            <div className={`flex-1 flex ${isDarkMode ? "bg-customBody" : "bg-white"} `}>
              <LessonTab
                active={activeSection === 'instructions'}
                activeSection={activeSection}
                onClick={() => setActiveSection('instructions')}
              >
                Instructions
              </LessonTab>
            </div>
            <div className={`flex-1 flex ${isDarkMode ? "bg-customBody" : "bg-white"} `}>
              <JupyterTab
                active={activeSection === 'notebook'}
                onClick={() => setActiveSection('notebook')}
                activeSection={activeSection}
                onDrag={handleIsDragChange}
                onActiveDrags={handleActiveDrags}
                activeDrags={activeDrags}
              >
                Jupyter Notebook
              </JupyterTab>
            </div>
          </div>
        )
        }
        {lesson?.instructions && !fullTab && isDrag && (
          <div className="border-b border-gray-200 flex w-full">
            <div className={`flex-1 flex ${isDarkMode ? "bg-customBody" : "bg-white"} `}>
              <LessonTab
                active={activeSection === 'instructions'}
                activeSection={activeSection}
                onClick={() => setActiveSection('instructions')}
                isWindow={true}
              >
                Instructions
              </LessonTab>
            </div>
          </div>
        )}
        {
          activeSection === 'instructions' && !fullTab && isDrag && (
            <DraggableJupyter onFullTab={handleFullTab} />
          )
        }
        {activeSection === 'instructions' && !fullTab && (
          <div className={`flex-grow overflow-auto p-4 ${isDarkMode && "bg-customBody text-customBodyText"} `}>
            <div className="prose max-w-none ">
              {parse(lesson.instructions, options)}
            </div>
          </div>
        )
        }
        {activeSection === 'notebook' && !isDrag && !fullTab && (
          <div className={`flex-grow ${isDarkMode ? "bg-customBody" : "bg-white"}`}>
            {
              activeDrags ? "" : <JupyterLiteEmbed />
            }
          </div>
        )
        }
        {
          activeSection === 'notebook' && isDrag && !fullTab && (
            <>
              <div className={`overflow-auto p-4 ${isDarkMode && "bg-customBody text-customBodyText"} `}>
                <div className="prose max-w-none ">
                  {parse(lesson.instructions, options)}
                </div>
              </div>
              <DraggableJupyter onFullTab={handleFullTab} />
            </>
          )
        }
        {lesson?.instructions && fullTab && (
          <div className="border-b border-gray-200 flex w-full">
            <div className={`flex-1 flex ${isDarkMode ? "bg-customBody" : "bg-white"} `}>
              <LessonTab
                active={activeSection === 'instructions'}
                activeSection={activeSection}
                onClick={() => {
                  setActiveSection('instructions');
                }}
              >
                Instructions
              </LessonTab>
            </div>
            <div className={`flex-1 flex ${isDarkMode ? "bg-customBody" : "bg-white"} `}>
              <JupyterTab
                active={activeSection === 'notebook'}
                onClick={() => {
                  setActiveSection('notebook');
                  handleFullTab(false);
                }}
                activeSection={activeSection}
                onDrag={handleIsDragChange}
                onActiveDrags={handleActiveDrags}
                activeDrags={activeDrags}
              >
                Jupyter Notebook
              </JupyterTab>
            </div>
          </div>
        )
        }
        {activeSection === 'instructions' && fullTab && (
          <div className={`flex-grow overflow-auto p-4 ${isDarkMode && "bg-customBody text-customBodyText"} `}>
            <div className="prose max-w-none ">
              {parse(lesson.instructions, options)}
            </div>
          </div>
        )
        }
      </div>

      <Footer />
    </div >
  );
}