import React, { useEffect } from 'react'
import Editor from '@monaco-editor/react'

const ReusableEditor = ({
    isDragging,
    handleDragStart,
    handleDrag,
    handleDragStop,
    isMobileView,
    editorRef,

    activeCodeTab,
    code,
    onChange,
    handleEditorMount,
    options = {},
    ...props 
}) => {
    const language = {
        c: 'c',
        cpp: 'cpp',
        cxx: 'cpp', // Alias
        cc: 'cpp', // Alias
        cplusplus: 'cpp', // Alias
        csharp: 'csharp',
        cs: 'csharp', // Alias
        dotnet: 'csharp', // Alias for .NET
        css: 'css',
        html: 'html',
        java: 'java',
        javascript: 'javascript',
        js: 'javascript', // Alias
        json: 'json',
        python: 'python',
        py: 'python', // Alias
        sh: 'shell', // Alias
        bash: 'shell', // Alias for Bash
        typescript: 'typescript',
        ts: 'typescript', // Alias
    }[activeCodeTab] //assigned language based on activeCodeTab
    
    const defaultOptions = {
        automaticLayout: true,
    }
    const mergedOptions = options ? { ...defaultOptions, ...options } : {}; //Merge default Options with Options
    return (
        <Editor
            height="100%"
            defaultLanguage={language}
            language={language}
            value={code}
            onChange={onChange}
            onMount={handleEditorMount}
            options={mergedOptions}
            {...props} //pass additional props 
            
            />
    );
}
export default ReusableEditor