import React, { useEffect, useState, useRef } from 'react';
import Device from '../../Components/Lessons/Networking/Device';
import Connection from '../../Components/Lessons/Networking/Connection';
import Toolbar from '../../Components/Lessons/Networking/Toolbar';
import DebugPanel from '../../Components/Lessons/Networking/DebugPanel';
import CLI from '../../Components/Lessons/Networking/CLI';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import PacketAnalyzer from '../../Components/Lessons/Networking/wireshark/PacketAnalyzer';
import options from '../../Components/Lessons/OptionsQuill';
import parse from 'html-react-parser';
import { useNetworkManager } from '../../Components/Lessons/Networking/logic/packet-tracer/useNetworkState';
import { Monitor } from 'lucide-react';
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';
import { useTheme } from '../../Contexts/ThemeContext';
const Tab = React.memo(({ active, onClick, children }) => {
  const {isDarkMode} = useTheme();
  return(
  <button
    onClick={onClick}
    className={`${isDarkMode?"bg-customBodyDarken1AndHalf ":""} px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm font-medium border-b-2 flex-1 ${
      active
        ? 'text-blue-600 border-blue-500'
        : ` ${isDarkMode?"text-customHeaderText":"text-gray-500"}  hover:text-gray-800 border-transparent `
    }`}
  >
    {children}
  </button>
)});

const MIN_WIDTH = 768; // Minimum width in pixels
const MIN_HEIGHT = 600; // Minimum height in pixels

function useScreenSize() {
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsScreenTooSmall(
        window.innerWidth < MIN_WIDTH || window.innerHeight < MIN_HEIGHT
      );
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isScreenTooSmall;
}

const ScreenSizeWarning = () => {
  const {isDarkMode} = useTheme();
  return(
  <div className={`min-h-screen ${isDarkMode?"bg-customBody":"bg-white"}  flex items-center justify-center p-4`}>
    <div className={`max-w-md w-full ${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-white"}  rounded-lg shadow-lg p-6 border-2 border-blue-100`}>
      <div className="flex items-center justify-center mb-4">
        <Monitor className="w-12 h-12 text-blue-500" />
      </div>
      <h2 className={`text-xl  ${isDarkMode?"text-white font-semibold":'text-gray-600 font-bold'} text-center mb-2 Nokora`}>
        ទំហំអេក្រង់តូចពេក
      </h2>
      <p className={`${isDarkMode?"text-white":'text-gray-600'} text-center mb-4 Nokora`}>
        សូមប្រើអេក្រង់ដែលមានទំហំយ៉ាងតិច {MIN_WIDTH}x{MIN_HEIGHT}px ដើម្បីប្រើប្រាស់ឧបករណ៍សិក្សាបណ្តាញនេះ។
      </p>
      <div className={`flex justify-center items-center gap-2 text-sm ${isDarkMode?"text-customBodyText":"text-gray-500"}`}>
        <Monitor className="w-4 h-4" />
        <span>Min: {MIN_WIDTH}x{MIN_HEIGHT}px</span>
      </div>
    </div>
  </div>
)};

const NetworkingLesson = (lesson) => {
  const [devices, setDevices] = useState(new Map());
  const [connections, setConnections] = useState(new Map());
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [connectionMode, setConnectionMode] = useState(false);
  const [connectionStart, setConnectionStart] = useState(null);
  const [activeDevice, setActiveDevice] = useState(null);
  const [draggedDevice, setDraggedDevice] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [debug, setDebug] = useState({ socketId: null, lastAction: null, lastError: null });
  const canvasRef = useRef(null);
  const [activeSection, setActiveSection] = useState(lesson?.lesson?.instructions ? 'instructions' : 'Packet Tracer');
  const [contextMenu, setContextMenu] = useState({ show: false, x: 0, y: 0, type: null, id: null });
  const [selectedConnection, setSelectedConnection] = useState(null);
  const {isDarkMode} = useTheme();
  
  console.log(lesson);

  const { networkState, addDevice, addConnection, updateDevicePosition, deleteConnection, executeCommand, reset } = useNetworkManager();

  const isScreenTooSmall = useScreenSize();

  if (isScreenTooSmall) {
    return (
      <>
        {lesson.isSubscribed ? <Header/> : <FreeTrialHeader/>}
        <ScreenSizeWarning />
        <Footer />
      </>
    );
  }

  const handleDeviceDoubleClick = (device) => {
    if (!isDragging) {
      console.log('Opening CLI for device:', device);
      setActiveDevice(device);
    }
  };

  const handleCanvasClick = (e) => {
    if (!selectedDevice || !canvasRef.current) return;
    
    e.stopPropagation();
    
    const rect = canvasRef.current.getBoundingClientRect();
    const position = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };
  
    const newDevice = {
      id: `${selectedDevice}-${Date.now()}`,
      type: selectedDevice,
      position
    };
  
    console.log('Adding device:', newDevice);
    const device = addDevice(newDevice);
    if (device) {
      setDevices(prev => new Map(prev).set(device.id, device));
      setDebug(prev => ({ ...prev, lastAction: `Added device: ${device.type}` }));
    }
    setSelectedDevice(null);
  };
  
  const handleDeviceClick = (e, deviceId) => {
    e.stopPropagation();
    if (!isDragging && connectionMode) {
      if (!connectionStart) {
        setConnectionStart(deviceId);
        setDebug(prev => ({ ...prev, lastAction: `Connection started from: ${deviceId}` }));
      } else if (connectionStart !== deviceId) {
        const connection = {
          id: `${connectionStart}-${deviceId}`,
          fromDevice: connectionStart,
          toDevice: deviceId
        };
  
        console.log('Adding connection:', connection);
        const result = addConnection(connection);
        if (result) {
          setConnections(prev => new Map(prev).set(result.id, result));
          setDebug(prev => ({ ...prev, lastAction: `Connection completed to: ${deviceId}` }));
        }
        setConnectionStart(null);
      }
    }
  };

  const handleDeviceDragStart = (e, device) => {
    if (!connectionMode) {
      setIsDragging(true);
      setDraggedDevice(device);
      e.dataTransfer.setData('text/plain', ''); // Required for Firefox
    } else {
      e.preventDefault(); // Prevent dragging in connection mode
    }
  };

  const handleDeviceDragEnd = (e) => {
    if (!draggedDevice || !canvasRef.current) return;
  
    const rect = canvasRef.current.getBoundingClientRect();
    const newPosition = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };
  
    const updated = updateDevicePosition({
      deviceId: draggedDevice.id,
      position: newPosition
    });
  
    if (updated) {
      setDevices(prev => {
        const newMap = new Map(prev);
        const updatedDevice = {
          ...draggedDevice,
          position: newPosition
        };
        newMap.set(draggedDevice.id, updatedDevice);
        return newMap;
      });
    }
  
    setDraggedDevice(null);
    setTimeout(() => setIsDragging(false), 100);
  };

  const handleReset = () => {
    const success = reset();
    if (success) {
        setDevices(new Map());
        setConnections(new Map());
        setSelectedDevice(null);
        setConnectionMode(false);
        setConnectionStart(null);
        setActiveDevice(null);
        setDraggedDevice(null);
        setSelectedConnection(null);
        setDebug(prev => ({ ...prev, lastAction: 'Network reset' }));
    }
  };

  const handleContextMenu = (e, type, id) => {
    e.preventDefault();
    setContextMenu({
      show: true,
      x: e.clientX,
      y: e.clientY,
      type,
      id
    });
  };

  const handleDeleteDevice = (deviceId) => {
    // Delete all connections associated with this device
    Array.from(connections.values())
      .filter(conn => conn.fromDevice === deviceId || conn.toDevice === deviceId)
      .forEach(conn => handleDeleteConnection(conn.id));

    // Delete the device
    setDevices(prev => {
      const newDevices = new Map(prev);
      newDevices.delete(deviceId);
      return newDevices;
    });
    setContextMenu({ show: false, x: 0, y: 0, type: null, id: null });
  };

  const handleDeleteConnection = (connectionId) => {
    const success = deleteConnection(connectionId);
    if (success) {
      setConnections(prev => {
        const newConnections = new Map(prev);
        newConnections.delete(connectionId);
        return newConnections;
      });
      setContextMenu({ show: false, x: 0, y: 0, type: null, id: null });
    }
  };

  return (
    <>
      {lesson.isSubscribed ? <Header/> : <FreeTrialHeader/>}
      <div className={`flex flex-col min-h-[75vh] ${isDarkMode?"bg-customBody text-customBodyText" : " bg-white text-gray-800"} font-sans`}>
        <div className="flex border-b border-gray-200">
          {lesson?.lesson?.instructions && (
            <Tab
              active={activeSection === 'instructions'}
              onClick={() => setActiveSection('instructions')}
            >
              Instructions
            </Tab>
          )}
          <Tab
            active={activeSection === 'Packet Tracer'}
            onClick={() => setActiveSection('Packet Tracer')}
          >
            Packet Tracer
          </Tab>
          <Tab
            active={activeSection === 'Wireshark'}
            onClick={() => setActiveSection('Wireshark')}
          >
            Wireshark
          </Tab>
        </div>

        {activeSection === 'instructions' && lesson?.lesson?.instructions ? (
          <div className="flex-grow overflow-auto p-4 pb-32">
            <div className="prose max-w-none overflow-y-auto">{parse(lesson.lesson.instructions, options)}</div>
          </div>
        ) : activeSection === 'Packet Tracer' ? (
          <div className="flex flex-1 overflow-hidden">
            <Toolbar
              selectedDevice={selectedDevice}
              connectionMode={connectionMode}
              onDeviceSelect={setSelectedDevice}
              onConnectionModeToggle={() => {
                setConnectionMode(!connectionMode);
                setConnectionStart(null);
                setSelectedDevice(null);
              }}
              onReset={handleReset}
              className="sticky top-0 left-0"
            />

            <div 
              ref={canvasRef}
              className="flex-1 relative overflow-hidden cursor-crosshair bg-[linear-gradient(rgba(0,0,0,0.05)1px,transparent_1px),linear-gradient(90deg,rgba(0,0,0,0.05)1px,transparent_1px)] bg-[size:20px_20px]"
              onClick={handleCanvasClick}
            >
              <svg className="absolute inset-0 w-full h-full pointer-events-none">
                {Array.from(connections.values()).map((connection) => (
                  <Connection
                    key={connection.id}
                    connection={connection}
                    fromDevice={devices.get(connection.fromDevice)}
                    toDevice={devices.get(connection.toDevice)}
                    onContextMenu={handleContextMenu}
                  />
                ))}
              </svg>

              {Array.from(devices.values()).map((device) => (
                <Device
                  key={device.id}
                  device={device}
                  connectionMode={connectionMode}
                  connectionStart={connectionStart}
                  onDeviceClick={handleDeviceClick}
                  onDeviceDoubleClick={handleDeviceDoubleClick}
                  onDragStart={handleDeviceDragStart}
                  onDragEnd={handleDeviceDragEnd}
                  onContextMenu={handleContextMenu}
                />
              ))}
            </div>

            <DebugPanel 
              debug={debug}
              devices={devices}
              connections={connections}
              connectionMode={connectionMode}
              connectionStart={connectionStart}
            />

            {activeDevice && (
              <CLI 
                device={activeDevice} 
                onClose={() => setActiveDevice(null)}
                executeCommand={executeCommand}
              />
            )}

          </div>
        ) : (
          <div className="p-4 overflow-auto flex-1">
            <article className="prose prose-sm sm:prose lg:prose-lg max-w-none">
              <PacketAnalyzer/>
            </article>
          </div>
        )}
      </div>
      <Footer/>
      {contextMenu.show && (
        <>
          <div
            className="fixed z-50 bg-white shadow-lg rounded-lg py-2 min-w-[150px]"
            style={{ left: contextMenu.x, top: contextMenu.y }}
          >
            {contextMenu.type === 'device' && (
              <button
                onClick={() => handleDeleteDevice(contextMenu.id)}
                className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
              >
                Delete Device
              </button>
            )}
            {contextMenu.type === 'connection' && (
              <button
                onClick={() => handleDeleteConnection(contextMenu.id)}
                className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
              >
                Delete Connection
              </button>
            )}
          </div>
          <div
            className="fixed inset-0 z-40"
            onClick={() => setContextMenu({ show: false, x: 0, y: 0, type: null, id: null })}
          />
        </>
      )}
    </>
  );
};

export default NetworkingLesson;