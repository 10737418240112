import React from 'react';
import { Zap, Send, Code } from 'lucide-react';
import { Link } from 'react-router-dom';
const OptInPage = () => {
  const handleTelegramClick = () => {
    window.open('https://t.me/codeforcambodia_support', '_blank');
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row">
      {/* Left Column - Content */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8 lg:p-16">
        <div className="max-w-xl space-y-8">
          {/* Logo */}
          <Link to="/" className="group flex items-center space-x-2">
            <Code className="h-8 w-8 text-orange-500 transition-transform duration-300 ease-in-out group-hover:rotate-12" />
            <span className="text-xl font-semibold">
                <span className="text-blue-500 transition-colors duration-300 ease-in-out group-hover:text-blue-600">Code</span>
                <span className="text-blue-500 transition-colors duration-300 ease-in-out group-hover:text-blue-600">For</span>
                <span className="text-blue-500 transition-colors duration-300 ease-in-out group-hover:text-blue-600">Cambodia</span>
                <span className="text-orange-500 transition-colors duration-300 ease-in-out group-hover:text-orange-600">;</span>
            </span>
          </Link>

          {/* Main Content */}
          <div className="space-y-6">
            <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 Nokora">
              រៀនកូដ FREE 7 ថ្ងៃដំបូង!
            </h1>
            
            <p className="text-xl text-gray-600 Nokora">
              រៀនកូដគ្រប់កម្រិតពី Python, Java, C ដល់ជំនាញ App Development, Web Development, AI/Machine Learning, Data Science និងច្រើនទៀត!
            </p>

            {/* CTA Form */}
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col space-y-4">
                <button
                  onClick={handleTelegramClick}
                  className="px-8 py-3 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2"
                >
                  <span className="Nokora">ចុះឈ្មោះតាម Telegram</span>
                  <Send className="w-4 h-4" />
                </button>
                <p className="text-sm text-gray-600 text-center Nokora">
                  របៀបចុះឈ្មោះ:
                </p>
                <ol className="list-decimal list-inside Nokora text-sm text-gray-600 space-y-2">
                  <li>ចុច button ខាងលើហើយវាយ '/start_trial'</li>
                  <li>រួចវាយ email ដែលសុំ</li>
                  <li>ក្រោយមកសុំរងចាំ admin activate គណនេយ្យ pro ឲ</li>
                </ol>
              </div>
              <p className="text-sm text-gray-500">
                FREE . មិនត្រូវការ credit card
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Right Column - Testimonial */}
      <div className="w-full lg:w-1/2 bg-blue-500 flex items-center justify-center p-8 lg:p-16">
        <div className="max-w-xl">
          {/* Testimonial Card */}
          <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 text-white">
            <div className="space-y-6">
              <div className="flex items-start">
                <svg className="w-12 h-12 text-white/60" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"/>
                </svg>
              </div>
              
              <p className="text-xl sm:text-2xl leading-relaxed Nokora">
                ខ្ញុំពេញចិត្តនឹងការបង្រៀនដោយអនុវត្តភ្លាមៗណាស់លើ CodeForCambodia! ខ្ញុំបានរៀនភាសា Python ហើយយល់យ៉ាងងាយស្រួល ហើយឥឡូវកំពុងតែរៀនជំនាញ AI ថ្មីជាមួយ Python!
              </p>

              <div className="pt-4">
                <p className="font-semibold">Sok Dara</p>
                <p className="text-white/80">Computer Science @ Norton</p>
              </div>
            </div>
          </div>

          {/* Mock Newsletter Preview */}
          <div className="absolute bottom-0 right-0 w-64 h-96 bg-white rounded-2xl shadow-xl transform rotate-6 hidden lg:block">
            <div className="p-4">
              <img src="https://codeforcambodia.s3.us-east-005.backblazeb2.com/Screenshot+2025-01-02+at+6.55.33+in+the+evening.png" alt="Course preview" className="w-full h-40 object-cover rounded-lg mb-4" />
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptInPage;