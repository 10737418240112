import React from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { useTheme } from '../../Contexts/ThemeContext';
const PasswordInput = ({
  name,
  value,
  onChange,
  showPassword,
  setShowPassword,
  error,
  className
}) => {
  const { isDarkMode } = useTheme();
  return (
    <div>
      <label htmlFor={name} className={`block text-sm font-medium ${isDarkMode?"text-customHeaderText":"text-gray-700 "} mb-1`}>
        <span className="text-orange-500">let</span> {name} <span className="text-orange-500">=</span>
      </label>
      <div className="relative">
        <input
          id={name}
          name={name}
          type={showPassword ? "text" : "password"}
          required
          value={value}
          onChange={onChange}
          className={className}
        />
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
        >
          {showPassword ? (
            <EyeOff className="h-5 w-5 text-gray-400" />
          ) : (
            <Eye className="h-5 w-5 text-gray-400" />
          )}
        </button>
      </div>
      {error && (
        <p className="mt-1 text-xs text-red-600">{error}</p>
      )}
    </div>
  );
}

export default PasswordInput;
