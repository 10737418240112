import React from 'react'
import { motion } from 'framer-motion'
import { useTheme } from '../../Contexts/ThemeContext'
import axios from 'axios'
import { useState, useEffect } from 'react'
export default function CommunityJoinSection({openModal}) {
  const [totalUsers, setTotalUsers] = useState(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/leaderboard/total-users');
        setTotalUsers(response.data[0].total_users);
      } catch (error) {
        console.error('Error fetching total users:', error);
      }
    };

    fetchTotalUsers();
  }, []);
  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8 rounded-lg relative mb-8">
      <div className="max-w-3xl mx-auto text-center relative z-10">
        <h2 className="text-2xl md:text-4xl font-extrabold mb-4 Nokora leading-tight">
          <span className="text-blue-600">ចូលរួមជាមួយសហគមន៍របស់យើង</span>{' '}
          <span className={`${isDarkMode?"text-customBodyText":"text-gray-800"}`}>ដើម្បីក្លាយជាផ្នែកមួយនៃ</span>
          <span className={`${isDarkMode?"text-customBodyText":"text-gray-800"}`}>ការផ្លាស់ប្តូរ</span>{' '}
          <span className="text-blue-600">វាសនាយុវជននៅកម្ពុជា</span>
        </h2>
        <p className="text-xl text-gray-500 Nokora">ជាមួយនឹង៖</p>
        <div className="relative inline-block mx-16 my-4">
          <motion.div
            className="text-7xl sm:text-8xl font-bold text-blue-600"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            {totalUsers !== null ? `${totalUsers}+` : 'Loading...'}
          </motion.div>
        </div>
        <p className="mt-4 text-xl text-gray-500 Nokora">សិស្សកំពុងរៀនសរសេរកូដរួចហើយ</p>
        <div className="mt-8">
          <motion.button
            onClick={openModal}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 Nokora"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Donate to CodeForCambodia
          </motion.button>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-12 ">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full h-1/2 bg-blue-600" />
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-purple-500 transform -skew-y-3 w-full h-8 flex items-center justify-center">
            <span className="text-white font-semibold text-sm px-4 whitespace-nowrap Nokora">
              ដើម្បីអានាគតយើង * ដើម្បីអានាគតយើង * ដើម្បីអានាគតយើង * ដើម្បីអានាគតយើង

            </span>
          </div>
        </div>
      </div>
    </div>
  )
}