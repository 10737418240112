import { X } from "lucide-react";

export const CodingModal = ({ showHelpModal, setShowHelpModal }) => {
  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="help-modal-title"
      onClick={() => setShowHelpModal(false)}
      className="fixed  p-4 inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50"
    >
      <div
        className="bg-gray-400 rounded-lg w-[32rem] shadow-xl border border-gray-700 overflow-hidden font-mono"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center justify-between bg-gray-200 px-4 py-2 border-b border-gray-700">
          <div className="flex space-x-2">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
          <h2 className="text-lg font-semibold text-green-800">Beta Feature</h2>
          <button
            onClick={() => setShowHelpModal(false)}
            className="text-gray-400 hover:text-gray-800 transition-colors duration-200"
            aria-label="Close modal"
          >
            <X size={20} />
          </button>
        </div>
        <div className="p-6 bg-gray-200 ">
          <p className="text-green-800 mb-4 Nokora text-lg leading-relaxed">
            $ echo "បច្ចុប្បន្នគេហទំព័រនេះស្ថិតក្នុងកម្រិតបេតា។ ប្រសិនបើអ្នកជួបប្រទះកំហុស ឬជឿថាចម្លើយរបស់អ្នកត្រឹមត្រូវ សូមបន្ត។ យើងកំពុងធ្វើការលើការកែលម្អ និងកោតសរសើរចំពោះការអត់ធ្មត់របស់អ្នក!"
          </p>
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => setShowHelpModal(false)}
              className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-500 transition-colors duration-200"
            >
              $ exit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};