'use client'

import React, { useState, useEffect, Suspense, memo, useCallback, useMemo } from 'react'
import axios from 'axios'
import { LogOut, BarChart2, User, Home, Terminal, Code, ChevronRight, Trophy } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

// Assume these components are defined elsewhere
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import Footer from '../Components/Footer'
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation'
import LearningCourses from '../Components/Dashboard/LearningCourses'
import ViewAllCoursesButton from '../Components/Dashboard/ViewAllCoursesButton'
import DashboardComponent from '../Components/Dashboard/DashboardComponent'
import { MultiStepLoader } from '../Components/ui/multi-step-loader'
import Advertisement from '../Components/HomePage/Advertisement'
import PremiumTutorialModal from '../Components/Dashboard/PremiumTutorialModal'
import { useTheme } from '../Contexts/ThemeContext'
import ChallengeBanner from '../Components/Banners/ChallengeBanner'
import SubscriptionBanner from '../Components/Banners/SubscriptionBanner'
import { clearSubscriptionCache } from '../Components/Stripe/fetchSubscriptionStatus'

// Lazy load components
const Profile = React.lazy(() => import('../Components/Dashboard/Profile'))
const ViewAllCoursesModal = React.lazy(() => import('../Components/Modals/ViewAllCoursesModal'))
const FeedbackForm = React.lazy(() => import('../Components/Dashboard/FeedbackForm'))
const ComingSoonModal = React.lazy(() => import('../Components/Dashboard/ComingSoonModalProps'))

// Memoize child components
const MemoizedLearningCourses = memo(LearningCourses)
const MemoizedDashboardComponent = memo(DashboardComponent)
const MemoizedViewAllCoursesButton = memo(ViewAllCoursesButton)

const POLL_INTERVAL = 30000 // Poll every 30 seconds for progress updates

export default function Dashboard({ currentStreak = 0, longestStreak = 0 }) {
  const [state, setState] = useState({
    userProgress: [],
    allCourses: [],
    loading: true,
    progressLoading: true,
    error: null,
    isModalOpen: false,
    showComingSoonModal: false,
    selectedInactiveCourse: null,
    userData: null,
    lastActive: null,
    learningPaths: [],
    currentlyLearningPaths: [],
    completedPaths: [],
    recommendedPaths: [],
    lastUpdate: Date.now(),
    activeTab: 'progress'
  })

  const [showPremiumTutorialModal, setShowPremiumTutorialModal] = useState(false);

  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  const { isDarkMode } = useTheme()
  // Memoize API instance
  const api = useMemo(() => {
    const headers = { 'Authorization': `Bearer ${token}` }
    const baseURL = 'https://codeforcambodia.codes/codecademy'

    return {
      get: (endpoint) => axios.get(`${baseURL}${endpoint}`, { headers }),
      post: (endpoint, data) => axios.post(`${baseURL}${endpoint}`, data, { headers }),
      put: (endpoint, data) => axios.put(`${baseURL}${endpoint}`, data, { headers })
    }
  }, [token])

  useEffect(() => {
    const fetchUserStreak = async () => {
      try {
        const response = await api.get('/user/streak');
        setState((prev) => ({
          ...prev,
          lastActive: response.data.last_active_time,
        }));
      } catch (error) {
        setState(prev => ({
          ...prev,
          error: 'Error fetching user profile',
          loading: false
        }))
      }
    };
    fetchUserStreak();
  }, [api]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await api.get('/user/profile')
        setState(prev => ({
          ...prev,
          userData: response.data,
          loading: false
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          error: 'Error fetching user profile',
          loading: false
        }))
      }
    }

    fetchUserProfile()
  }, [api])

  const lastLearnedCourse = useMemo(() =>
    state.userProgress.length > 0
      ? state.userProgress.reduce((prev, current) =>
        new Date(prev.last_updated_at) > new Date(current.last_updated_at) ? prev : current
      )
      : null,
    [state.userProgress, state.lastUpdate]
  )

  const startedCourses = useMemo(() =>
    state.userProgress.filter(course =>
      course.progress_percentage > 0 && course.progress_percentage < 100
    ),
    [state.userProgress, state.lastUpdate]
  )

  const [recommendedCourses, setRecommendedCourses] = useState([]);

  useEffect(() => {
    const headers = { 'Authorization': `Bearer ${token}` }

    const fetchRecommendations = async () => {
      if (!state.allCourses.length) return;

      try {

        const response = await api.post('/ai/recommend-courses', { userProgress: state.userProgress, allCourses: state.allCourses }, { headers });
        if (response.data.success && response.data.recommendations) {
          setRecommendedCourses(response.data.recommendations);
        }
      } catch (error) {
        console.error('Error fetching course recommendations:', error);
        // Fallback to original logic if API fails
        const fallbackRecommendations = state.allCourses
          .filter(course => !startedCourses.some(progress =>
            progress.course_id === course.id
          ))
          .slice(0, 2);
        setRecommendedCourses(fallbackRecommendations);
      }
    };

    fetchRecommendations();
  }, [state.allCourses, state.userProgress, startedCourses, api]);

  // Process learning paths
  const processLearningPaths = useCallback(async (paths) => {
    const progressPromises = paths.map(async (path) => {
      try {
        const progressResponse = await api.get(`/learningpath/overall-progress/${path.id}`)
        return { ...path, progress: progressResponse.data.progress }
      } catch (error) {
        console.log(`No progress found for path ${path.id}:`, error)
        return { ...path, progress: null }
      }
    })

    return Promise.all(progressPromises)
  }, [api])

  // Categorize paths - now memoized based on paths input
  const categorizePaths = useCallback((pathsWithProgress) => {
    return {
      currentlyLearning: pathsWithProgress.filter(path =>
        path.progress && path.progress.overall_progress > 0 && path.progress.overall_progress < 100
      ),
      completed: pathsWithProgress.filter(path =>
        path.progress && parseFloat(path.progress.overall_progress) === 100
      ),
      recommended: pathsWithProgress.filter(path =>
        !path.progress || parseFloat(path.progress.overall_progress) === 0
      )
    }
  }, [])
  // Fetch progress updates
  const fetchProgressUpdates = useCallback(async () => {
    if (state.progressLoading) return; // Prevent concurrent fetches

    setState(prev => ({ ...prev, progressLoading: true }))
    try {
      const [progressResponse, pathsProgressResponse] = await Promise.all([
        api.get('/user/progress'),
        api.get('/path')
      ])

      const pathsWithProgress = await processLearningPaths(pathsProgressResponse.data.learningPaths)
      const categorizedPaths = categorizePaths(pathsWithProgress)

      setState(prev => ({
        ...prev,
        userProgress: progressResponse.data.courses,
        learningPaths: pathsWithProgress,
        currentlyLearningPaths: categorizedPaths.currentlyLearning,
        completedPaths: categorizedPaths.completed,
        recommendedPaths: categorizedPaths.recommended,
        lastUpdate: Date.now(),
        progressLoading: false
      }))
    } catch (error) {
      console.error('Error fetching progress updates:', error)
      setState(prev => ({ ...prev, progressLoading: false }))
    }
  }, [api, processLearningPaths, categorizePaths])

  // Initial data fetch effect
  useEffect(() => {
    let mounted = true
    let pollInterval;

    const fetchInitialData = async () => {
      try {
        const [progressResponse, coursesResponse, learningPathsResponse] = await Promise.all([
          api.get('/user/progress'),
          api.get('/fetch/courses'),
          api.get('/path')
        ])

        if (!mounted) return

        const pathsWithProgress = await processLearningPaths(learningPathsResponse.data.learningPaths)
        const categorizedPaths = categorizePaths(pathsWithProgress)

        setState(prev => ({
          ...prev,
          userProgress: progressResponse.data.courses,
          allCourses: coursesResponse.data,
          learningPaths: pathsWithProgress,
          currentlyLearningPaths: categorizedPaths.currentlyLearning,
          completedPaths: categorizedPaths.completed,
          recommendedPaths: categorizedPaths.recommended,
          loading: false,
          progressLoading: false,
          lastUpdate: Date.now()
        }))

        // Only start polling after initial data is loaded
        pollInterval = setInterval(fetchProgressUpdates, POLL_INTERVAL)
      } catch (err) {
        if (mounted) {
          setState(prev => ({
            ...prev,
            error: 'Error fetching data',
            loading: false,
            progressLoading: false
          }))
          handleLogout()
        }
      }
    }

    fetchInitialData()

    return () => {
      mounted = false
      if (pollInterval) clearInterval(pollInterval)
    }
  }, [api, fetchProgressUpdates, processLearningPaths, categorizePaths])

  const handleProfileUpdate = useCallback(async (updatedData) => {
    try {
      await api.put('/user/profile', updatedData)
      setState(prev => ({
        ...prev,
        userData: {
          ...prev.userData,
          ...updatedData
        }
      }))
    } catch (error) {
      console.error('Error updating profile:', error)
      throw error
    }
  }, [api])

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token')

    clearSubscriptionCache()

    window.location.href = '/'
  }, [])

  const handleCourseClick = useCallback((course) => {
    try {
      if (!course.status) {
        setState(prev => ({
          ...prev,
          selectedInactiveCourse: course,
          showComingSoonModal: true
        }))
      } else {
        if (!course.id) throw new Error('Invalid course ID');
        navigate(`/modules/${course.id}/course`)
      }
    } catch (error) {
      console.error('Error navigating to course:', error);
      navigate('/courses');
    }
  }, [navigate])

  const handleTabChange = useCallback((tabName) => {
    setState(prev => ({ ...prev, activeTab: tabName }))
  }, [])

  const handlePremiumOpenClose = () => {
    setShowPremiumTutorialModal(!showPremiumTutorialModal);
  };

  if (state.error) return <div className="flex items-center justify-center h-screen text-red-500">{state.error}</div>

  return (
    <div className={`min-h-screen ${isDarkMode ? "bg-customBody" : "bg-gray-50"}  text-gray-800 font-sans flex flex-col`}>
      <CodeForCambodiaHeader />
      <PremiumTutorialModal isOpen={showPremiumTutorialModal} onClose={() => setShowPremiumTutorialModal(false)} />
      <MultiStepLoader loading={state.loading} />
      <Suspense fallback={<div className="w-full h-12 bg-gray-200 animate-pulse rounded-md" />}>
        <FeedbackForm />
      </Suspense>

      {/* Challenge & Workspace Section */}
      <SubscriptionBanner />

      <main className={`flex-grow container mx-auto px-4 py-8 ${isDarkMode ? "bg-customBodyDarken1" : "bg-white"}`}>
        <nav className="mb-8">
          <ul className="flex flex-wrap sm:flex-nowrap border-b border-gray-200">
            {[
              { name: 'progress', icon: <BarChart2 className="w-4 h-4 sm:w-5 sm:h-5" /> },
              { name: 'dashboard', icon: <Home className="w-4 h-4 sm:w-5 sm:h-5" /> },
              { name: 'profile', icon: <User className="w-4 h-4 sm:w-5 sm:h-5" /> }
            ].map((tab) => (
              <motion.li key={tab.name} className="flex-1" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <button
                  onClick={() => handleTabChange(tab.name)}
                  className={`w-full py-2 px-2 sm:px-4 focus:outline-none flex items-center justify-center sm:justify-start transition-colors duration-200 ${
                    state.activeTab === tab.name
                      ? `border-b-2 ${isDarkMode?"text-customBodyTextBlue":"text-blue-600"} border-blue-500 `
                      : `${isDarkMode?" text-customBodyText" : "text-gray-600"}  hover:text-blue-500`
                  }`}
                  aria-current={state.activeTab === tab.name ? 'page' : undefined}
                >
                  {tab.icon}
                  <span className="ml-1 sm:ml-2 text-sm sm:text-base hidden sm:inline">
                    {tab.name.charAt(0).toUpperCase() + tab.name.slice(1)}
                  </span>
                </button>
              </motion.li>
            ))}
          </ul>
        </nav>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className=" px-6 overflow-hidden relative"
        >
          <AnimatePresence mode="wait">
            {state.activeTab === 'dashboard' && (
              <motion.div
                key="dashboard"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="space-y-6"
              >
                <MemoizedDashboardComponent
                  currentStreak={currentStreak}
                  longestStreak={longestStreak}
                  lastActive={state.lastActive}
                  name={state.userData?.first_name || ''}
                />


              </motion.div>
            )}

            {state.activeTab === 'progress' && (
              <motion.div
                key="progress"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="space-y-6"
              >
                <Suspense fallback={<div className="w-full h-64 bg-gray-200 animate-pulse rounded-md" />}>
                  <MemoizedLearningCourses
                    lastLearnedCourse={lastLearnedCourse}
                    startedCourses={startedCourses}
                    recommendedCourses={recommendedCourses}
                    handleCourseClick={handleCourseClick}
                    learningPaths={state.learningPaths}
                    handlePremiumOpenClose={handlePremiumOpenClose}
                    currentlyLearningPaths={state.currentlyLearningPaths}
                    completedPaths={state.completedPaths}
                    recommendedPaths={state.recommendedPaths}
                    isLoading={state.progressLoading}
                  />
                  <MemoizedViewAllCoursesButton />
                </Suspense>
              </motion.div>
            )}

            {state.activeTab === 'profile' && (
              <motion.div
                key="profile"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
              >
                <Suspense fallback={<div className="w-full h-24 bg-gray-200 animate-pulse rounded-md" />}>
                  <Profile
                    userData={state.userData}
                    onUpdateProfile={handleProfileUpdate}
                    isLoading={state.loading}
                  />
                </Suspense>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <Advertisement />
      </main>

      <Footer />

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="fixed bottom-6 right-6 bg-gradient-to-br from-orange-500 to-red-600 text-white p-4 rounded-full shadow-lg hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-all duration-300 flex items-center justify-center font-medium w-14 h-14"
        aria-label="Logout from dashboard"
        onClick={handleLogout}
      >
        <LogOut className="w-6 h-6" />
      </motion.button>

      <Suspense fallback={<div className="w-full h-64 bg-gray-200 animate-pulse rounded-md" />}>
        <ViewAllCoursesModal
          allCourses={state.allCourses}
          isOpen={state.isModalOpen}
          onClose={() => setState(prev => ({ ...prev, isModalOpen: false }))}
        />
      </Suspense>

      <Suspense fallback={<div className="w-full h-48 bg-gray-200 animate-pulse rounded-md" />}>
        <ComingSoonModal
          isOpen={state.showComingSoonModal}
          onClose={() => setState(prev => ({ ...prev, showComingSoonModal: false }))}
          course={state.selectedInactiveCourse}
        />
      </Suspense>
    </div>
  )
}