'use client'

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { PhpWeb } from 'php-wasm/PhpWeb.mjs'
import Editor from '@monaco-editor/react'
import Header from '../../Headers/CodeForCambodiaHeader'
import Footer from '../../Footer'
import parse from 'html-react-parser'
import Confetti from 'react-confetti'
import axios from 'axios'
import ReusableEditor from '../../Challenge/Editor/ReusableEditor'
import { PhpReusablePreview } from "../../Challenge/Editor/ReusablePreview"
import { useTheme } from '../../../Contexts/ThemeContext'
import {
  RefreshCw,
  Maximize2,
  Minimize2,
  Play,
  Check,
  ToggleLeft,
  Terminal,
  Code,
  FileText,
  Lock,
} from 'lucide-react'
import { DraggableCore } from 'react-draggable'
import options from '../OptionsQuill'
import { debounce } from 'lodash'
import { NavItem } from './ComponentsHeader'
import FreeTrialHeader from '../../Headers/FreeTrialHeader'

const Button = ({ onClick, disabled, children, className, icon: Icon }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-150 flex items-center ${className}`}
  >
    {Icon && <Icon size={16} className="mr-2" />}
    {children}
  </button>
)

const FeedbackMessage = ({ type, message }) => {
  const styles = {
    success: {
      container: 'bg-green-100 border-green-500 text-green-700',
      icon: '✓',
    },
    error: {
      container: 'bg-red-100 border-red-500 text-red-700',
      icon: '✕',
    },
  }

  const style = styles[type]

  return (
    <div
      className={`
        mt-2 p-4 border-l-4
        flex items-center gap-2
        ${style.container}
      `}
    >
      <span className="text-xl font-bold">{style.icon}</span>
      <span>{message}</span>
    </div>
  )
}

const CodeSection = ({
  phpCode,
  setPhpCode,
  output,
  error,
  isLoading,
  runPhpCode,
  verifyCode,
  isVerifying,
  editorRef,
  handleEditorMount,
  verificationStatus,
  successMessage,
  refreshCode,
}) => {
  const [splitPosition, setSplitPosition] = useState(50)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const dragRef = useRef(null)
  const containerRef = useRef(null)
  const outputRef = useRef(null)
  const {isDarkMode} = useTheme()
  const handleDragStart = useCallback((e) => {
    setIsDragging(true)
    const containerRect = containerRef.current.getBoundingClientRect()
    setStartX(e.clientX - containerRect.left)
  }, [])

  const handleDrag = useCallback(
    (e) => {
      if (!isDragging || !containerRef.current) return

      const containerRect = containerRef.current.getBoundingClientRect()
      const containerWidth = containerRect.width
      const currentX = e.clientX - containerRect.left
      const deltaX = currentX - startX

      requestAnimationFrame(() => {
        const newPosition = splitPosition + (deltaX / containerWidth) * 100
        const clampedPosition = Math.min(Math.max(newPosition, 20), 80)
        setSplitPosition(clampedPosition)
        setStartX(currentX)
      })
    },
    [isDragging, splitPosition, startX]
  )

  const handleDragStop = useCallback(() => {
    setIsDragging(false)
    if (editorRef.current) {
      setTimeout(() => {
        editorRef.current.layout()
      }, 50)
    }
  }, [])

  // Effect to update editor layout on resize
  useEffect(() => {
    const handleResize = debounce(() => {
      if (editorRef.current) {
        editorRef.current.layout()
      }
    }, 100)

    window.addEventListener('resize', handleResize)
    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Modify the output HTML to prevent default form submissions
  const modifiedOutput = useMemo(() => {
    if (!output) return ''
    // Use a DOM parser to modify the form's action attribute
    const parser = new DOMParser()
    const doc = parser.parseFromString(output, 'text/html')
    const forms = doc.querySelectorAll('form')
    forms.forEach((form) => {
      form.setAttribute('action', '')
      form.setAttribute('onsubmit', 'return false;')
    })
    return doc.body.innerHTML
  }, [output])

  const handleFormSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const formObject = {}
    formData.forEach((value, key) => {
      formObject[key] = value
    })

    // Re-run the PHP code, passing the form data
    runPhpCode(formObject)
  }

  useEffect(() => {
    if (outputRef.current) {
      const forms = outputRef.current.querySelectorAll('form')
      forms.forEach((form) => {
        form.addEventListener('submit', handleFormSubmit)
      })

      // Cleanup on unmount or when output changes
      return () => {
        forms.forEach((form) => {
          form.removeEventListener('submit', handleFormSubmit)
        })
      }
    }
  }, [modifiedOutput])

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-grow relative" ref={containerRef}>
        <div
          style={{
            width: `${splitPosition}%`,
            transition: isDragging ? 'none' : 'width 0.1s ease-out',
          }}
          className="h-full"
        >
          <ReusableEditor
            activeCodeTab="php"
            code={phpCode}
            onChange={(value) => setPhpCode(value || '')}
            handleEditorMount={handleEditorMount}

            options={{
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              fontSize: 14,
              lineNumbers: 'on',
              renderLineHighlight: 'line',
              tabSize: 2,
              fontFamily: 'JetBrains Mono, monospace',
              padding: { top: 10 },
            }}
            theme={`${isDarkMode?"vs-dark":"light"}`}
          />
        </div>
        {/* Drag Handle */}
        <DraggableCore
          onStart={handleDragStart}
          onDrag={handleDrag}
          onStop={handleDragStop}
          nodeRef={dragRef}
        >
          <div
            ref={dragRef}
            className={`
              absolute top-0 bottom-0
              w-4 -ml-2
              cursor-col-resize
              group
              flex items-center justify-center
              z-10
              ${isDragging ? 'select-none' : ''}
            `}
            style={{
              left: `${splitPosition}%`,
              transition: isDragging ? 'none' : 'left 0.1s ease-out',
            }}
          >
            <div
              className={`
                w-1 h-8
                bg-gray-300
                group-hover:bg-blue-400
                rounded
                transition-colors duration-150
                ${isDragging ? 'bg-blue-500' : ''}
              `}
            />
          </div>
        </DraggableCore>

        {/* Output */}
        <div
          style={{
            width: `${100 - splitPosition}%`,
            transition: isDragging ? 'none' : 'width 0.1s ease-out',
          }}
          className="h-full bg-white overflow-auto border-l"
        >

          <div className="p-4" ref={outputRef}>
           
            <PhpReusablePreview
              className={"font-mono text-sm whitespace-pre-wrap"}
              outputHtml={modifiedOutput}
            />
            
            {verificationStatus === 'success' && (
              <div className="mt-4 p-4 bg-green-50 border-l-4 border-green-500 text-green-700 rounded">
                {successMessage}
              </div>
            )}
            {verificationStatus === 'error' && (
              <div className="mt-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Controls */}
      <div className={`h-12 border-t ${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-50"}  flex items-center px-4 gap-2`}>
        <Button
          onClick={() => runPhpCode()}
          disabled={isLoading}
          icon={Play}
          className={`${isDarkMode?"text-customBodyText hover:text-gray-500":"text-black hover:text-gray-600"}  ${isLoading ? 'opacity-50' : ''}`}
        >
          {isLoading ? 'Running...' : 'Run PHP'}
        </Button>

        <Button
          onClick={verifyCode}
          disabled={isVerifying}
          icon={Check}
          className={`${isDarkMode?"text-customBodyText hover:text-gray-500":"text-black hover:text-gray-600"} ${isVerifying ? 'opacity-50' : ''}`}
        >
          {isVerifying ? 'Verifying...' : 'Verify Code'}
        </Button>

        <Button
          onClick={refreshCode}
          className="ml-auto bg-gray-500 hover:bg-gray-600 text-white"
          icon={RefreshCw}
        >
          Reset
        </Button>
      </div>
    </div>
  )
}

const SampleSection = ({ sampleCode }) => {
  const [phpCode] = useState(sampleCode || '')
  const [output, setOutput] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const phpRef = useRef(null)
  const outputRef = useRef(null)

  useEffect(() => {
    const initPhp = async () => {
      try {
        const php = new PhpWeb()
        phpRef.current = php

        php.addEventListener('ready', () => {
          console.log('PHP-WASM is ready in SampleSection!')
          runPhpCode()
        })

        php.addEventListener('output', (event) => {
          setOutput((prevOutput) => prevOutput + event.detail)
        })

        php.addEventListener('error', (event) => {
          setError((prevError) => prevError + event.detail)
        })
      } catch (err) {
        console.error('Failed to initialize PHP-WASM in SampleSection:', err)
        setError('Failed to initialize PHP-WASM. Please check your setup.')
      }
    }

    initPhp()
  }, [])

  const runPhpCode = async (formData = {}) => {
    if (phpRef.current && phpCode) {
      setIsLoading(true)
      setOutput('')
      setError('')
      try {
        await phpRef.current.run(phpCode, {
          post: formData,
        })
      } catch (err) {
        console.error('Failed to run PHP code in SampleSection:', err)
        setError('Failed to run PHP code. Please check your code and try again.')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const formObject = {}
    formData.forEach((value, key) => {
      formObject[key] = value
    })

    // Re-run the PHP code, passing the form data
    runPhpCode(formObject)
  }

  useEffect(() => {
    if (outputRef.current) {
      // Modify the form's action attribute and prevent default submission
      const parser = new DOMParser()
      const doc = parser.parseFromString(output, 'text/html')
      const forms = doc.querySelectorAll('form')
      forms.forEach((form) => {
        form.setAttribute('action', '')
        form.setAttribute('onsubmit', 'return false;')
      })
      const modifiedOutput = doc.body.innerHTML
      setOutput(modifiedOutput)

      const formsInOutput = outputRef.current.querySelectorAll('form')
      formsInOutput.forEach((form) => {
        form.addEventListener('submit', handleFormSubmit)
      })

      // Cleanup on unmount or when output changes
      return () => {
        formsInOutput.forEach((form) => {
          form.removeEventListener('submit', handleFormSubmit)
        })
      }
    }
  }, [output])

  return (
    <div className="flex flex-col h-full ">
      <div className="bg-white rounded-lg overflow-hidden border border-gray-200 h-full">
        {/* Browser Window Header */}
        <div className="bg-gray-100 border-b border-gray-200">
          {/* Traffic Lights and Tab Bar */}
          <div className="flex items-center px-4 py-2 gap-2">
            <div className="flex items-center gap-1.5">
              <div className="w-3 h-3 rounded-full bg-[#FF5F56] border border-[#E0443E]"></div>
              <div className="w-3 h-3 rounded-full bg-[#FFBD2E] border border-[#DEA123]"></div>
              <div className="w-3 h-3 rounded-full bg-[#27C93F] border border-[#1AAB29]"></div>
            </div>
          </div>

          {/* Address Bar */}
          <div className="px-4 pb-2 flex items-center gap-2">
            <div className="flex-grow flex items-center bg-white rounded-md border border-gray-200 px-3 py-1">
              <div className="flex items-center gap-2 w-full">
                <Lock className="w-4 h-4 text-gray-400" />
                <span className="text-sm text-gray-500 truncate">http://localhost/sample-output.php</span>
              </div>
            </div>
            <button onClick={() => runPhpCode()} className="p-1 rounded hover:bg-gray-200" title="Refresh">
              <RefreshCw className="w-4 h-4 text-gray-600" />
            </button>
          </div>
        </div>

        {/* Browser Content */}
        <div className="bg-white h-full overflow-auto p-6" ref={outputRef}>
          {isLoading ? (
            <div className="flex items-center justify-center gap-2 text-gray-600">
              <div className="animate-spin w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full"></div>
              <span>Loading sample output...</span>
            </div>
          ) : (
            <div
              className="font-mono text-sm whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: output }}
            />
          )}
          {error && <FeedbackMessage type="error" message={error} />}
        </div>
      </div>
    </div>
  )
}

export default function PHPLessonModern({ lesson, onToggleDesign, isSubscribed }) {
  const [activeTab, setActiveTab] = useState(lesson?.instructions ? 'instructions' : 'code')
  const [phpCode, setPhpCode] = useState(lesson?.starter_php || '')
  const [output, setOutput] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [verificationStatus, setVerificationStatus] = useState(null)

  const [isFullScreen, setIsFullScreen] = useState(false)
  const phpRef = useRef(null)
  const editorRef = useRef(null)

  const [windowDimensions, setWindowDimensions] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  })
  const { isDarkMode } = useTheme()
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const verifyCode = async () => {
    setIsVerifying(true)
    setError('')
    setSuccessMessage('')
    setVerificationStatus(null)

    try {
      const { data } = await axios.post(
        'https://codeforcambodia.codes/codecademy/compile/verify-php',
        {
          code: phpCode,
          expectedOutput: lesson?.sample_output || '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (data.correct) {
        setShowConfetti(true)
        setVerificationStatus('success')
        setSuccessMessage('🎉 Great job! Your code works correctly!')

        setTimeout(() => {
          setShowConfetti(false)
        }, 5000)
      } else {
        setVerificationStatus('error')
        setError(data.message || "Your code output doesn't match the expected result. Keep trying!")
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message
      setVerificationStatus('error')
      setError('Error: ' + errorMessage)
      console.error('Verification error:', err)
    } finally {
      setIsVerifying(false)
    }
  }

  useEffect(() => {
    const initPhp = async () => {
      try {
        const php = new PhpWeb()
        phpRef.current = php

        php.addEventListener('ready', () => {
          console.log('PHP-WASM is ready!')
        })

        php.addEventListener('output', (event) => {
          setOutput((prevOutput) => prevOutput + event.detail)
        })

        php.addEventListener('error', (event) => {
          setError((prevError) => prevError + event.detail)
        })
      } catch (err) {
        console.error('Failed to initialize PHP-WASM:', err)
        setError('Failed to initialize PHP-WASM. Please check your setup.')
      }
    }

    initPhp()
  }, [])

  const runPhpCode = async (formData = {}) => {
    if (phpRef.current) {
      setIsLoading(true)
      setOutput('')
      setError('')
      setVerificationStatus(null)
      try {
        await phpRef.current.run(phpCode, {
          post: formData,
        })
      } catch (err) {
        console.error('Failed to run PHP code:', err)
        setError('Failed to run PHP code. Please check your code and try again.')
      } finally {
        setIsLoading(false)
      }
    } else {
      setError('PHP-WASM is not initialized. Please wait or refresh the page.')
    }
  }

  const handleEditorMount = (editor, monaco) => {
    editorRef.current = editor
    setTimeout(() => {
      editor.layout()
    }, 0)
  }

  const refreshCode = () => {
    setPhpCode(lesson?.starter_php || '')
    setOutput('')
    setError('')
    setSuccessMessage('')
    setVerificationStatus(null)
  }

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  return (
    <div className={`${isFullScreen ? 'h-screen  pb-0 md:pb-14' : 'min-h-screen pb-0 md:pb-12'} flex flex-col ${isDarkMode?"bg-customBody":"bg-white text-black"} `}>
      {!isFullScreen ? (isSubscribed ? <Header/> : <FreeTrialHeader/>) : null}
      {showConfetti && (
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          recycle={false}
          numberOfPieces={300}
          gravity={0.3}
          colors={['#ffd700', '#98ff98', '#87ceeb', '#dda0dd', '#f08080']}
          tweenDuration={5000}
        />
      )}
      <div className={`flex-grow ${isFullScreen ? 'h-full' : 'h-[calc(100vh-8rem-2rem)]'}`}>
        <div className="h-full flex flex-col">
          {/* Top Bar with Fullscreen Toggle */}
          <div className={`h-12 ${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-50 border-b "}  flex items-center justify-between px-4`}>
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-1">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
              </div>
              <span className={`text-sm ${isDarkMode?"text-customHeaderText":"text-gray-600 "} ml-4`}>{lesson?.lesson_name || 'PHP Lesson'}</span>
            </div>
            <div className={`flex items-center gap-3 `}>
              <Button
                onClick={onToggleDesign}
                className={`p-2 rounded-md ${isDarkMode?"text-customHeaderText hover:bg-gray-700":"text-gray-600 hover:bg-gray-100"} `}
                icon={ToggleLeft}
              />
              <Button
                onClick={toggleFullScreen}
                className={`p-2 rounded-md ${isDarkMode?"text-customHeaderText hover:bg-gray-700":"text-gray-600 hover:bg-gray-100"} `}
                icon={isFullScreen ? Minimize2 : Maximize2}
              />
              <Button
                onClick={refreshCode}
                className={`flex items-center gap-2 px-3 py-1.5 text-sm ${isDarkMode?"text-customHeaderText hover:bg-gray-700":"text-gray-600 hover:bg-gray-100"} rounded`}
                icon={RefreshCw}
              >
                Reset
              </Button>
            </div>
          </div>

          <div className={`flex flex-grow overflow-hidden `}>
            {/* Left Sidebar */}
            <div className={`w-48 border-r flex flex-col ${isDarkMode?"bg-customBodyDarken1AndHalf":""}`}>
              <div className="p-3">
                {lesson?.instructions && (
                  <NavItem
                    icon={FileText}
                    label="Instructions"
                    active={activeTab === 'instructions'}
                    onClick={() => setActiveTab('instructions')}
                  />
                )}
                <NavItem
                  icon={Code}
                  label="Code"
                  active={activeTab === 'code'}
                  onClick={() => setActiveTab('code')}
                />
                <NavItem
                  icon={Terminal}
                  label="Sample"
                  active={activeTab === 'sample'}
                  onClick={() => setActiveTab('sample')}
                />
              </div>
            </div>

            {/* Main Content */}
            <div className="flex-grow flex flex-col">
              {/* Content Area */}
              <div className="flex-grow overflow-hidden ">
                {activeTab === 'instructions' && lesson?.instructions && (
                  <div className="h-full overflow-auto p-4">
                    <div className={`prose max-w-none ${isDarkMode?"text-customBodyText":""} `}>{parse(lesson.instructions, options)}</div>
                  </div>
                )}

                {activeTab === 'code' && (
                  <CodeSection
                    phpCode={phpCode}
                    setPhpCode={setPhpCode}
                    output={output}
                    error={error}
                    isLoading={isLoading}
                    runPhpCode={runPhpCode}
                    verifyCode={verifyCode}
                    isVerifying={isVerifying}
                    editorRef={editorRef}
                    handleEditorMount={handleEditorMount}
                    verificationStatus={verificationStatus}
                    successMessage={successMessage}
                    refreshCode={refreshCode}
                  />
                )}

                {activeTab === 'sample' && (
                  <div className="h-full p-4">
                    <SampleSection sampleCode={lesson?.sample_output} />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Status Bar */}
          <div className={`h-6 ${isDarkMode?"bg-customBodyDarken1AndHalf text-customBodyText":"bg-gray-100 text-gray-600"}  border-t px-3 flex items-center text-xs `}>
            <span>PHP</span>
            <span className="mx-2">•</span>
            <span>Ready</span>
          </div>
        </div>
      </div>
      {!isFullScreen && <Footer />}
    </div>
  )
}
