import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { ArrowRight } from 'lucide-react'
import { useTheme } from '../../Contexts/ThemeContext';
export default function CTASection() {
  const { isDarkMode } = useTheme();
  const TypeWriter = ({ text, className }) => {
    const [displayText, setDisplayText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
      if (index < text.length) {
        const timer = setTimeout(() => {
          setDisplayText((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        }, 50);
        return () => clearTimeout(timer);
      }
    }, [index, text]);

    return (
      <span className={className}>
        {displayText}
        <motion.span
          animate={{ opacity: [0, 1, 0] }}
          transition={{ repeat: Infinity, duration: 1.5 }}
          className="inline-block w-1 h-5 bg-gray-600 ml-2"
        />
      </span>
    );
  };

  return (
    <div className={`text-gray-800 p-4 sm:p-8 relative ${isDarkMode? "bg-customBody" : "bg-white "} overflow-hidden`}>
      {/* Background lines */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {Array.from({ length: 10 }).map((_, index) => (
          <div
            key={index}
            className={`absolute w-full h-px ${isDarkMode?"bg-customBodyDarken2":"bg-gray-100"} `}
            style={{ top: `${index * 10}%` }}
          />
        ))}
      </div>

      <div className="max-w-4xl mx-auto relative z-10 justify-center flex flex-col items-center">
        <div className="flex flex-col items-center justify-center">
          <motion.h1
            className="text-lg sm:text-xl md:text-2xl font-bold text-center mb-4 text-gray-900 font-mono"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="text-orange-500 Nokora">"នរណាក៏អាចកូដបានដែរ"</span>;
          </motion.h1>
          <TypeWriter
            text="រៀនឥឡូវដោយ FREE!"
            className="text-lg sm:text-xl md:text-2xl font-bold text-center mb-8 text-green-600 Nokora"
          />
        </div>

        <motion.button
          onClick={() => window.location.href = '/login'}
          className=" bg-blue-500 hover:bg-blue-600 text-white py-3 sm:py-4 px-4 sm:px-6 rounded-lg transition duration-300 ease-in-out text-center text-base lg:text-lg flex items-center justify-center font-mono"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <span className="text-yellow-300">function</span>{' '}
          <span className="text-white">startLearning</span>
          <span className="text-yellow-300">() {`{`}</span>
          <ArrowRight className="ml-2" size={24} />
          <span className="text-yellow-300">{`}`}</span>
        </motion.button>
      </div>
    </div>
  )
}