import React from 'react';
import { Newspaper, ExternalLink, Award, Users } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTheme } from '../../Contexts/ThemeContext';
export default function NewsCompanyMention() {
  const { isDarkMode } = useTheme();
  const pressFeatures = [
    {
      name: "CC Times KH",
      logo: "https://cdn.cc-times.com/kh.cc-times.com/listings/1/original/CCTimeKH_Head-Logo_H56px.png?1580469165",
      link: "https://kh.cc-times.com/posts/21554",
    },
    {
      name: "Radio France Internationale KH",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/RFI_logo_2013.svg/768px-RFI_logo_2013.svg.png",
      link: "https://www.rfi.fr/km/%E1%9E%80%E1%9E%98%E1%9F%92%E1%9E%98%E1%9E%9C%E1%9E%B7%E1%9E%92%E1%9E%B8%E1%9E%95%E1%9F%92%E1%9E%9F%E1%9F%81%E1%9E%84%E1%9E%91%E1%9F%80%E1%9E%8F/%E1%9E%94%E1%9E%91%E1%9E%99%E1%9E%80%E1%9E%80%E1%9E%B6%E1%9E%9A%E1%9E%8E%E1%9F%8D/20241208-%E1%9E%94%E1%9F%89%E1%9E%B6%E1%9E%84-%E1%9E%9C%E1%9E%9A%E1%9F%88%E1%9E%A7%E1%9E%8F%E1%9F%92%E1%9E%8F%E1%9E%98%E1%9F%96-%E1%9E%80%E1%9E%BC%E1%9E%93%E1%9E%81%E1%9F%92%E1%9E%98%E1%9F%82%E1%9E%9A%E1%9E%82%E1%9E%BA%E1%9E%86%E1%9F%92%E1%9E%9B%E1%9E%B6%E1%9E%8F-%E1%9E%8F%E1%9F%82%E1%9E%93%E1%9F%85%E1%9E%81%E1%9F%92%E1%9E%9C%E1%9F%87%E1%9E%B1%E1%9E%80%E1%9E%B6%E1%9E%9F-%E1%9E%93%E1%9E%B7%E1%9E%84%E1%9E%80%E1%9E%93%E1%9F%92%E1%9E%9B%E1%9F%82%E1%9E%84%E1%9E%9A%E1%9F%80%E1%9E%93%E1%9E%8A%E1%9F%8F%E1%9E%9B%E1%9F%92%E1%9E%A2",
    },
  ];

  return (
    <div className={`w-full bg-gradient-to-b ${isDarkMode?"bg-customBodyDarken1AndHalf":"from-white to-gray-50"}  py-16`}>
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col items-center">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="flex flex-col items-center mb-8"
          >
            <div className={`flex  items-center space-x-2 mb-2`}>
              <Award className="w-6 h-6 text-yellow-500" />
              <span className={`text-lg font-semibold ${isDarkMode?"text-customHeaderText":"text-gray-900"} `}>
                Featured In
              </span>
            </div>
          </motion.div>

          <div className="w-full max-w-4xl">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {pressFeatures.map((press, index) => (
                <motion.div
                  key={press.name}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="col-span-2 md:col-span-1 "
                >
                  <a 
                    href={press.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full"
                  >
                    <div className="group relative bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex items-center justify-between p-6">
                      <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-indigo-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl" />
                      <div className="relative flex flex-col space-y-2 flex flex-col items-start justify-start">
                        <img 
                          src={press.logo}
                          alt={press.name}
                          className="h-16 object-contain"
                        />
                        <span className="text-sm font-medium text-gray-900">{press.name}</span>
                        <div className="flex items-center space-x-2">
                          <span className="text-sm text-blue-500 group-hover:text-blue-600 Nokora">អានអត្ថបទ</span>
                          <ExternalLink className="w-4 h-4 text-gray-400 group-hover:text-blue-500 transition-colors" />
                        </div>
                      </div>
                    </div>
                  </a>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}