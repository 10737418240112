import React from 'react';
import { Router, Monitor, HardDriveIcon, RefreshCw } from 'lucide-react';
import { useTheme } from '../../../Contexts/ThemeContext';

const ToolbarButton = ({ type, isSelected, onClick, icon }) => {
  const {isDarkMode} = useTheme();
    const getDeviceIcon = () => {
        switch (type) {
          case 'router': return <Router className="w-5 h-5" title="Router" />;
          case 'switch': return <HardDriveIcon className="w-5 h-5" title="Switch" />;
          case 'pc': return <Monitor className="w-5 h-5" title="PC" />;
          case 'connect': return <div title="Connect">{icon}</div>;
          case 'reset': return <div title="Reset">{icon}</div>;
          default: return null;
        }
      };

      const getTitle = () => {
        return type.charAt(0).toUpperCase() + type.slice(1);
      };
    
      return (
        <button
          className={`p-2 rounded-md transition-all duration-200 flex flex-col items-center gap-1
            ${isSelected 
              ? 'bg-blue-100 text-blue-600 shadow-sm' 
              : `${isDarkMode?"bg-customBodyDarken1AndHalf hover:bg-gray-700":"bg-white text-gray-600 hover:bg-gray-50"} `}`}
          onClick={onClick}
          title={getTitle()}
        >
          {getDeviceIcon()}
          <span className="text-xs">{getTitle()}</span>
        </button>
      );
};

export default ToolbarButton;