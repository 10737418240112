// SandpackContent.js

import React, { useMemo, useEffect } from 'react';

import {
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackPreview,
  useActiveCode,
} from "@codesandbox/sandpack-react";
import { useTheme } from '../../../Contexts/ThemeContext';
function SandpackContent({ code, setCode }) {
  const baseFiles = useMemo(
    () => ({
      "/styles.css": `
        body { margin: 0; padding: 0; }
        #root { height: 100vh; }
      `,
      "/App.js": code,
    }),
    [code]
  );
  const { isDarkMode } = useTheme();

  return (
    <SandpackProvider
      template="react"
      files={baseFiles}
      theme={`${isDarkMode ? "dark" : "light"}`}
      options={{
        showNavigator: true,
        showTabs: false,
        showLineNumbers: true,
        showInlineErrors: true,
        visibleFiles: ["/App.js"],
      }}
    >
      <InnerSandpackContent setCode={setCode} />
    </SandpackProvider>
  );
}

function InnerSandpackContent({ setCode }) {
  const { code } = useActiveCode();
  const { isDarkMode } = useTheme();
  useEffect(() => {
    setCode(code);
  }, [code, setCode]);

  return (
    <div className="h-full w-full overflow-hidden absolute top-0">
      <SandpackLayout >
        <SandpackCodeEditor
          showTabs={false}
          readOnly={false}
          showInlineErrors={true}
          showLineNumbers={true}
        />
        <SandpackPreview showNavigator={true} />
      </SandpackLayout>
      <div className={`flex items-center justify-center p-6 ${isDarkMode?"bg-customBody":""} `}>
        <p className={`${isDarkMode?"text-customBodyText":"text-gray-600"} flex items-center gap-2`}>
          <svg
            className="w-5 h-5 text-blue-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
          For a smoother experience, please click "Open Sandbox" or use your local machine.
        </p>
      </div>
    </div>
  );
}

export default SandpackContent;
