import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { User, Mail, Calendar, Users, Edit2, Save, X, Gift, Settings } from 'lucide-react';
import CodeLoadingAnimation from '../CodeLoadingAnimation';
import { useTheme } from '../../Contexts/ThemeContext';
const Profile = ({ userData, onUpdateProfile, isLoading }) => {
  // Memoize editedData to avoid unnecessary recalculations
  const initialEditedData = useMemo(() => ({
    first_name: '',
    last_name: '',
    age: '',
    gender: '',
    email: ''
  }), []);

  const [editedData, setEditedData] = useState(initialEditedData);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [showPreferences, setShowPreferences] = useState(false);
  const { isDarkMode } = useTheme();
  // Update local state when props change
  useEffect(() => {
    if (userData) {
      setEditedData(userData);
    }
  }, [userData]);

  // Memoize handleInputChange to avoid recreating the function on every render
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setEditedData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side validation
    if (!editedData.first_name || !editedData.last_name) {
      setError('First name and Last name are required.');
      return;
    }
    if (editedData.age && (editedData.age < 0 || editedData.age > 150)) {
      setError('Please enter a valid age.');
      return;
    }

    try {
      const { first_name, last_name, age, gender } = editedData;
      await onUpdateProfile({ first_name, last_name, age, gender });
      setIsEditing(false);
      setError(null);
    } catch (err) {
      setError('Error updating user profile');
    }
  };

  const handleCancel = () => {
    setEditedData(userData);
    setIsEditing(false);
    setError(null);
  };

  const handleShowPythonDownload = () => {
    localStorage.removeItem('pythonDropdownHidden');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  if (isLoading) {
    return <div className="flex justify-center"><CodeLoadingAnimation /></div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-600">{error}</div>;
  }

  return (
    <div className="container px-4 font-mono">


      <div className="text-base 2xl:text-xl">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-4 sm:gap-0">
          <h3 className={`text-sm sm:text-base 2xl:text-xl font-semibold flex items-center ${isDarkMode?"text-gray-200":""}`}>
            <User className="inline mr-2 text-blue-600 w-4 h-4 sm:w-5 sm:h-5" />
            <span className="text-blue-600 Nokora text-center">ព័ត៌មានផ្ទាល់ខ្លួន</span>() {'{'}
          </h3>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
            <button
              onClick={() => setShowPreferences(!showPreferences)}
              className="text-purple-600 hover:text-purple-800 flex items-center justify-center sm:justify-start w-full sm:w-auto px-3 py-1.5 sm:px-0 sm:py-0 border border-purple-200 sm:border-0 rounded-md sm:rounded-none"
            >
              <Settings className="mr-1" size={16} />
              <span className="text-sm">Preferences</span>
            </button>
            <button
              onClick={() => isEditing ? handleCancel() : setIsEditing(true)}
              className="text-blue-600 hover:text-blue-800 flex items-center justify-center sm:justify-start w-full sm:w-auto px-3 py-1.5 sm:px-0 sm:py-0 border border-blue-200 sm:border-0 rounded-md sm:rounded-none"
            >
              {isEditing ? <X className="mr-1" size={16} /> : <Edit2 className="mr-1" size={16} />}
              <span className="text-sm">{isEditing ? 'Cancel' : 'Edit'}</span>
            </button>
          </div>
        </div>

        {showPreferences && (
          <div className="mb-6 p-4 bg-white rounded-lg border border-gray-200">
            <h4 className="text-lg font-semibold mb-4 flex items-center">
              <Gift className="mr-2 text-yellow-500" />
              Hidden Features
            </h4>
            <div className="space-y-2">
              <button
                onClick={handleShowPythonDownload}
                className="w-full text-left px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-md flex items-center"
              >
                <Gift className="mr-2 h-4 w-4 text-blue-500" />
                Show Python Download Option
              </button>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4 2xl:text-xl text-base ml-6">
          {error && <p className="text-red-600">{error}</p>}

          <div>
            <label htmlFor="email" className={`block text-sm font-medium ${isDarkMode?"text-customBodyText":"text-gray-700"}`}>
              <span className="text-orange-500">let</span> email <span className="text-orange-500">=</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={editedData.email || ''}
              disabled={true}
              className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label htmlFor="first_name" className={`block text-sm font-medium ${isDarkMode?"text-customBodyText":"text-gray-700"}`}>
              <span className="text-orange-500">let</span> firstName <span className="text-orange-500">=</span>
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={editedData.first_name || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label htmlFor="last_name" className={`block text-sm font-medium ${isDarkMode?"text-customBodyText":"text-gray-700"}`}>
              <span className="text-orange-500">let</span> lastName <span className="text-orange-500">=</span>
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={editedData.last_name || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label htmlFor="age"className={`block text-sm font-medium ${isDarkMode?"text-customBodyText":"text-gray-700"}`}>
              <span className="text-orange-500">let</span> age <span className="text-orange-500">=</span>
            </label>
            <input
              type="number"
              id="age"
              name="age"
              value={editedData.age || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label htmlFor="gender" className={`block text-sm font-medium ${isDarkMode?"text-customBodyText":"text-gray-700"}`}>
              <span className="text-orange-500">let</span> gender <span className="text-orange-500">=</span>
            </label>
            <select
              id="gender"
              name="gender"
              value={editedData.gender || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          {isEditing && (
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="text-green-400">return</span> updatedProfile();
              </button>
            </div>
          )}
        </form>
      </div>

      <div className={`mt-4 2xl:text-xl text-lg font-bold ${isDarkMode?"text-gray-200":""}`}>{'}'}</div>
    </div>
  );
};

export default Profile;