import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeft, Plus, Users, Search, Edit2, Trash2, Code, Terminal, GitBranch, Calendar, Clock, Trophy } from 'lucide-react';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import { MultiStepLoader } from '../../Components/ui/multi-step-loader';
import ConfirmationModal from '../../Components/ConfirmationModal';
import UnauthorizedPage from '../../Components/UnauthorizedPage';
import { useTheme } from '../../Contexts/ThemeContext';
const WorkspacePage = () => {
    const [workspaces, setWorkspaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [formData, setFormData] = useState({ title: '', challengeId: '' });
    const [editingWorkspace, setEditingWorkspace] = useState(null);
    const [challenges, setChallenges] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedChallenge, setSelectedChallenge] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [workspaceToDelete, setWorkspaceToDelete] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [workspacesRes, challengesRes] = await Promise.all([
                    axios.get('https://codeforcambodia.codes/codecademy/challenges/workspace', {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }),
                    axios.get('https://codeforcambodia.codes/codecademy/challenges/challenge')
                ]);

                setWorkspaces(workspacesRes.data.data);
                setChallenges(challengesRes.data.data.filter(c => c.status === 'active'));
            } catch (err) {
                setError('Failed to load workspaces');
                console.error('Error:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCreate = async (e) => {
        e.preventDefault();
        try {
            setIsCreating(true);
            const response = await axios.post(
                'https://codeforcambodia.codes/codecademy/challenges/workspace',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            const [workspacesRes, challengesRes] = await Promise.all([
                axios.get('https://codeforcambodia.codes/codecademy/challenges/workspace', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }),
                axios.get('https://codeforcambodia.codes/codecademy/challenges/challenge')
            ]);

            setWorkspaces(workspacesRes.data.data);
            setChallenges(challengesRes.data.data.filter(c => c.status === 'active'));
            setShowCreateModal(false);
            setFormData({ title: '', challengeId: '' });
        } catch (error) {
            console.error('Error creating workspace:', error);
        } finally {
            setIsCreating(false);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.put(
                `https://codeforcambodia.codes/codecademy/challenges/workspace/${editingWorkspace.id}`,
                {
                    title: formData.title,
                    settings: {}
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            setWorkspaces(workspaces.map(w =>
                w.id === editingWorkspace.id
                    ? { ...w, title: formData.title }
                    : w
            ));
            setShowEditModal(false);
            setEditingWorkspace(null);
            setFormData({ title: '', challengeId: '' });
        } catch (error) {
            console.error('Error updating workspace:', error);
        }
    };

    const handleDelete = async (workspaceId) => {
        try {
            await axios.delete(
                `https://codeforcambodia.codes/codecademy/challenges/workspace/${workspaceId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            setWorkspaces(workspaces.filter(w => w.id !== workspaceId));
            setShowDeleteModal(false);
            setWorkspaceToDelete(null);
        } catch (error) {
            console.error('Error deleting workspace:', error);
        }
    };

    const filteredWorkspaces = workspaces.filter(workspace =>
        workspace.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedChallenge === '' || workspace.challenge_id === selectedChallenge)
    );
    if (!localStorage.getItem('token')) {
        return (
            <UnauthorizedPage />
        )
    }
    return (
        <div className={`min-h-screen ${isDarkMode ? "bg-customBody text-customBodyText" : "bg-gray-100 text-gray-900"}    font-mono`}>
            <Header />
            <MultiStepLoader loading={loading} />
            <div className="max-w-4xl mx-auto px-4 py-8 h-screen">
                {/* Back Button */}
                <button
                    onClick={() => navigate('/challenge')}
                    className={`mb-6 flex items-center gap-2 ${isDarkMode ? "text-customBodyText hover:text-customHeaderText" : "text-gray-600 hover:text-gray-900 "} `}
                >
                    <ArrowLeft className="w-4 h-4" />
                    <span className="Nokora">មើលការប្រកួត</span>
                </button>

                {/* Header */}
                <div className="mb-8">
                    <div className="flex items-center gap-3 mb-4">
                        <Trophy className="w-6 h-6 text-blue-500" />
                        <h1 className="text-2xl font-bold Nokora">កន្លែងធ្វើការរបស់អ្នក</h1>
                    </div>
                    <p className={` ${isDarkMode ? "text-customBodyText" : "text-gray-600 "}  Nokora`}>
                        បង្កើត និងគ្រប់គ្រងកន្លែងធ្វើការកូដរបស់អ្នកសម្រាប់ការប្រកួតរបស់ Code For Cambodia។ កន្លែងធ្វើការនីមួយៗមាន project ដែលកវាជាកន្លែងសរសេរកូដមួយដើម្បីបង្កើត website សម្រាប់ប្រកួត។
                    </p>
                </div>

                {/* Search and Filter */}
                <div className="mb-6 flex flex-col sm:flex-row gap-4">
                    <div className="relative flex-grow">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                        <input
                            type="text"
                            placeholder="ស្វែងរកកន្លែងធ្វើការ..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 Nokora text-gray-800"
                        />
                    </div>
                    <select
                        value={selectedChallenge}
                        onChange={(e) => setSelectedChallenge(e.target.value)}
                        className={`border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 Nokora ${isDarkMode ? "text-gray-800" : ""}`}
                    >
                        <option value="">ការប្រកួតទាំងអស់</option>
                        {challenges.map(challenge => (
                            <option key={challenge.id} value={challenge.id}>
                                {challenge.title}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Workspaces List */}
                <div className="space-y-4">
                    {filteredWorkspaces.map(workspace => {
                        const challenge = challenges.find(c => c.id === workspace.challenge_id);
                        return (
                            <div
                                key={workspace.id}
                                className={`bg-white ${isDarkMode?"text-gray-700":""} dark:bg-gray-800 rounded-lg p-4 flex flex-col sm:flex-row items-start sm:items-center justify-between hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors`}
                            >
                                <div className="flex items-center gap-4 mb-4 sm:mb-0">
                                    <div className="h-12 w-12 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold">
                                        {workspace.title.substring(0, 2).toUpperCase()}
                                    </div>
                                    <div>
                                        <h3 className="font-medium Nokora">{workspace.title}</h3>
                                        <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                            <div className="flex items-center">
                                                <GitBranch className="w-4 h-4 mr-1" />
                                                <span className="Nokora">{challenge ? challenge.title : 'គ្មានការប្រកួត'}</span>
                                            </div>
                                            <div className="flex items-center mt-1">
                                                <Calendar className="w-4 h-4 mr-1" />
                                                <span className="Nokora">បង្កើត៖ {new Date(workspace.created_at).toLocaleDateString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <button
                                        onClick={() => {
                                            setEditingWorkspace(workspace);
                                            setFormData({ title: workspace.title });
                                            setShowEditModal(true);
                                        }}
                                        className="p-2 text-gray-600 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400"
                                        aria-label="កែប្រែកន្លែងធ្វើការ"
                                    >
                                        <Edit2 className="h-5 w-5" />
                                    </button>
                                    <button
                                        onClick={() => {
                                            setWorkspaceToDelete(workspace);
                                            setShowDeleteModal(true);
                                        }}
                                        className="p-2 text-gray-600 hover:text-red-500 dark:text-gray-400 dark:hover:text-red-400"
                                        aria-label="លុបកន្លែងធ្វើការ"
                                    >
                                        <Trash2 className="h-5 w-5" />
                                    </button>
                                    <ConfirmationModal
                                        isOpen={showDeleteModal}
                                        onClose={() => {
                                            setShowDeleteModal(false);
                                            setWorkspaceToDelete(null);
                                        }}
                                        onConfirm={() => handleDelete(workspaceToDelete?.id)}
                                        title="Delete Workspace"
                                        confirmText="Delete"
                                        cancelText="Cancel"
                                        confirmButtonClass="bg-red-600 hover:bg-red-700"
                                    >
                                        <p className="Nokora">តើអ្នកប្រាកដថាចង់លុបកន្លែងធ្វើការនេះមែនទេ?</p>
                                    </ConfirmationModal>
                                    <button
                                        onClick={() => navigate(`/projects/${workspace.id}`)}
                                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors Nokora"
                                    >
                                        ចូលរួម
                                    </button>
                                </div>
                            </div>
                        );
                    })}

                    {/* Create New Workspace */}
                    <button
                        onClick={() => setShowCreateModal(true)}
                        className={`w-full p-4 flex items-center gap-4 text-gray-600  hover:text-gray-900 rounded-lg hover:bg-gray-50 transition-colors`}
                    >
                        <div className="h-12 w-12 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                            <Plus className="h-6 w-6" />
                        </div>
                        <span className={`Nokora ${isDarkMode?"text-customBodyText ":""}`}>បង្កើតកន្លែងធ្វើការថ្មី</span>
                    </button>
                </div>

                {filteredWorkspaces.length === 0 && (
                    <div className="text-center py-12 text-gray-600 dark:text-gray-400 Nokora">
                        មិនមានកន្លែងធ្វើការទេ។ បង្កើតថ្មីដើម្បីចាប់ផ្តើម!
                    </div>
                )}

                {/* Instructions */}
                <div className="mt-8 p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                    <h3 className="font-bold text-lg text-gray-900 dark:text-white flex items-center gap-2 mb-3 Nokora">
                        <Code className="w-5 h-5" />
                        របៀបប្រើប្រាស់កន្លែងធ្វើការ
                    </h3>
                    <div className="grid grid-cols-2 gap-3 text-sm text-gray-600 dark:text-gray-400">
                        <p className="flex items-center gap-2 Nokora">
                            <Plus className="w-4 h-4" />
                            បង្កើតកន្លែងធ្វើការសម្រាប់ការប្រកួត
                        </p>
                        <p className="flex items-center gap-2 Nokora">
                            <GitBranch className="w-4 h-4" />
                            បង្កើត project ក្នុងកន្លែងធ្វើការសម្រាប់សរសេរកូដ
                        </p>
                        <p className="flex items-center gap-2 Nokora">
                            <Terminal className="w-4 h-4" />
                            សង់ website អ្នក
                        </p>
                        <p className="flex items-center gap-2 Nokora">
                            <Trophy className="w-4 h-4" />
                            Submit project របស់អ្នកដើម្បីប្រកួត
                        </p>
                    </div>
                </div>

                {/* Create Modal */}
                {showCreateModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className={` text-gray-700 bg-white dark:bg-gray-800 rounded-lg p-6 w-96 max-w-full`}>
                            <h2 className="text-xl font-bold mb-4 flex items-center Nokora">
                                <Plus className="w-5 h-5 mr-2 text-blue-500" />
                                បង្កើតកន្លែងធ្វើការថ្មី
                            </h2>
                            <form onSubmit={handleCreate} className="space-y-4">
                                <div>
                                    <label htmlFor="workspace-title" className="block text-sm font-medium mb-1 Nokora">ចំណងជើង</label>
                                    <input
                                        id="workspace-title"
                                        type="text"
                                        value={formData.title}
                                        onChange={e => setFormData({ ...formData, title: e.target.value })}
                                        className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 Nokora"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="workspace-challenge" className="block text-sm font-medium mb-1 Nokora">ការប្រកួត</label>
                                    <select
                                        id="workspace-challenge"
                                        value={formData.challengeId}
                                        onChange={e => setFormData({ ...formData, challengeId: e.target.value })}
                                        className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 Nokora"
                                        required
                                    >
                                        <option value="">ជ្រើសរើសការប្រកួត</option>
                                        {challenges.map(challenge => (
                                            <option key={challenge.id} value={challenge.id}>
                                                {challenge.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex justify-end gap-2">
                                    <button
                                        type="button"
                                        disabled={isCreating}
                                        onClick={() => setShowCreateModal(false)}
                                        className="px-4 py-2 border rounded-md hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors Nokora disabled:opacity-50"
                                    >
                                        បោះបង់
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={isCreating}
                                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors Nokora disabled:opacity-50"
                                    >
                                        បង្កើតកន្លែងធ្វើការ
                                    </button> 
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {/* Edit Modal */}
                {showEditModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className={` ${isDarkMode?"bg-customBody":"bg-white"} dark:bg-gray-800 rounded-lg p-6 w-96 max-w-full`}>
                            <h2 className="text-xl font-bold mb-4 flex items-center Nokora">
                                <Edit2 className="w-5 h-5 mr-2 text-blue-500" />
                                កែប្រែកន្លែងធ្វើការ
                            </h2>
                            <form onSubmit={handleUpdate} className="space-y-4">
                                <div>
                                    <label htmlFor="edit-workspace-title" className="block text-sm font-medium mb-1 Nokora">ចំណងជើង</label>
                                    <input
                                        id="edit-workspace-title"
                                        type="text"
                                        value={formData.title}
                                        onChange={e => setFormData({ ...formData, title: e.target.value })}
                                        className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 Nokora text-gray-700"
                                        required
                                    />
                                </div>
                                <div className="flex justify-end gap-2">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setShowEditModal(false);
                                            setEditingWorkspace(null);
                                            setFormData({ title: '', challengeId: '' });
                                        }}
                                        className={`px-4 py-2 border rounded-md ${isDarkMode?"hover:text-gray-700":""} hover:bg-gray-50 transition-colors Nokora`}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors Nokora"
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default WorkspacePage;