import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTheme } from '../../Contexts/ThemeContext';
const CourseReportCard = ({ averageGrade }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isDarkMode } = useTheme();
  if (!averageGrade || !averageGrade.quizzes_taken) return null;

  const getGradeColor = (grade) => {
    if (grade >= 90) return 'text-green-600';
    if (grade >= 80) return 'text-blue-600';
    if (grade >= 70) return 'text-yellow-600';
    return 'text-red-600';
  };

  const getLetterGrade = (grade) => {
    if (grade >= 90) return 'A';
    if (grade >= 80) return 'B';
    if (grade >= 70) return 'C';
    if (grade >= 60) return 'D';
    return 'F';
  };

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`mb-4 sm:mb-8 p-3 sm:p-4 ${isDarkMode?"bg-customBodyDarken1AndHalf border border-gray-800":"bg-white border border-gray-200"} rounded-lg shadow-sm Nokora`}>
      <div
        className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2 cursor-pointer"
        onClick={toggleExpand}
      >
        <div className="flex flex-col sm:flex-row sm:items-center mb-2 sm:mb-0">
          <h3 className={`text-lg sm:text-xl  ${isDarkMode?"text-customBodyText font-medium":"text-gray-800 font-bold"} mb-1 sm:mb-0 sm:mr-2`}>ពិន្ទុមធ្យម</h3>
          <div className="flex items-center">
            <span className={`text-xl sm:text-2xl font-bold mr-2 ${getGradeColor(parseFloat(averageGrade.average_grade))}`}>
              ({getLetterGrade(parseFloat(averageGrade.average_grade))})
            </span>
            <span className={`text-base sm:text-xl font-bold ${getGradeColor(parseFloat(averageGrade.average_grade))}`}>
              {averageGrade.average_grade}%
            </span>
          </div>
        </div>
        <button
          className="text-blue-600 hover:text-blue-800 transition-colors duration-200 mt-2 sm:mt-0"
          onClick={toggleExpand}
        >
          {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      </div>

      {isExpanded && (
        <div className="mt-4 pt-4 border-t border-gray-200">
          <div className="grid grid-cols-2 gap-3 sm:gap-4">
            <div>
              <p className="text-xs sm:text-sm text-purple-600">Quizzes Taken:</p>
              <p className="font-semibold text-sm sm:text-base">{averageGrade.quizzes_taken}</p>
            </div>
            <div>
              <p className="text-xs sm:text-sm text-purple-600">Total Quizzes:</p>
              <p className="font-semibold text-sm sm:text-base">{averageGrade.total_quizzes}</p>
            </div>
            <div>
              <p className="text-xs sm:text-sm text-purple-600">Completion:</p>
              <p className="font-semibold text-sm sm:text-base">{(averageGrade.quizzes_taken / averageGrade.total_quizzes * 100).toFixed(2)}%</p>
            </div>
            <div>
              <p className="text-xs sm:text-sm text-purple-600">Grade:</p>
              <p className={`font-semibold text-sm sm:text-base ${getGradeColor(parseFloat(averageGrade.average_grade))}`}>
                {getLetterGrade(parseFloat(averageGrade.average_grade))}
              </p>
            </div>
          </div>
          <div className="mt-4">
            <p className={`text-xs sm:text-sm ${isDarkMode?"text-customBodyText":"text-gray-600"}`}>Grade Scale:</p>
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-2 mt-2 text-xs sm:text-sm">
              <div className="text-green-600">A: 90-100%</div>
              <div className="text-blue-600">B: 80-89%</div>
              <div className="text-yellow-600">C: 70-79%</div>
              <div className="text-orange-600">D: 60-69%</div>
              <div className="text-red-600">F: 0-59%</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseReportCard;