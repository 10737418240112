import React, { useState } from 'react';
import { Maximize2, Minimize2 } from 'lucide-react';
import CodeLoadingAnimation from '../../CodeLoadingAnimation';

const JupyterLiteEmbed = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    const iframeElement = document.querySelector('.jupyter-iframe');
    
    if (!document.fullscreenElement) {
      if (iframeElement.requestFullscreen) {
        iframeElement.requestFullscreen();
      } else if (iframeElement.webkitRequestFullscreen) {
        iframeElement.webkitRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  return (
    <div className="w-full h-full ">
      <div className="relative w-full h-full">
        {isLoading && (
          <div className="absolute inset-0 z-10">
            <CodeLoadingAnimation />
          </div>
        )}
        <div className="absolute top-2 right-2 z-20">
          <button
            onClick={toggleFullscreen}
            className="p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-150"
          >
            {isFullscreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
          </button>
        </div>
        <iframe
          src='https://uddom1234.github.io/jupytercfc/lab/index.html'
          className="w-full h-full border-0 jupyter-iframe"
          onLoad={() => setIsLoading(false)}
          allow="clipboard-read; clipboard-write"
          title="JupyterLite Notebook"
        />
      </div>
    </div>
  );
};

export default JupyterLiteEmbed;