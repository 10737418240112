import React, { useState, useEffect, Suspense } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { AlertCircle, Home, Loader } from 'lucide-react';
import axios from 'axios';
import LessonNavigation from '../Components/Lessons/LessonNavigation';
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation';
import ProgressStatus from '../Components/Lessons/WebDev/ProgressStatus';
import { checkCourseAccess } from '../Components/functions/courseAccess';
import ReactLesson from './Contents/ReactLesson';
import ReactNativeLesson from './Contents/ReactNativeLesson';
import NetworkingLesson from './Contents/NetworkingLesson';
import { fetchSubscriptionStatus, getLastKnownSubscription } from '../Components/Stripe/fetchSubscriptionStatus';
import FlutterLesson from './Contents/FlutterLesson';
import CustomLesson from './Contents/CustomLesson';
import PythonPyodide from './Contents/PythonPyodide';
import { useTheme } from '../Contexts/ThemeContext';
import ExpressLesson from './Contents/ExpressLesson';
import JupyterLesson from './Contents/JupyterLesson';
import ScratchLesson from './Contents/ScratchLesson';

// Lazy-loaded components
const DocumentLesson = React.lazy(() => import('./Contents/DocumentLesson'));
const CodingLesson = React.lazy(() => import('./Contents/CodingLesson'));
const QuizLesson = React.lazy(() => import('./Contents/QuizLesson'));
const WebDevLesson = React.lazy(() => import('./Contents/WebDevLesson'));
const SQLLesson = React.lazy(() => import('./Contents/SQLLesson'));
const PHPLesson = React.lazy(() => import('./Contents/PHPLesson'));
const VideoLesson = React.lazy(() => import('./Contents/VideoLesson'));
const ChatBot = React.lazy(() => import('../Components/Lessons/ChatBot'));

const LessonContentPage = () => {
  const {isDarkMode} = useTheme();
  const [lesson, setLesson] = useState(null);
  const [modules, setModules] = useState([]);
  const [currentModule, setCurrentModule] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progressLoading, setProgressLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userProgress, setUserProgress] = useState(null);
  const { courseId, moduleId, lessonId } = useParams();
  const navigate = useNavigate();
  const [progressStatus, setProgressStatus] = useState({
    isLoading: false,
    error: null
  });
  const [courseAccessStatus, setCourseAccessStatus] = useState({ isFree: true, hasAccess: true });
  const [isSubscribed, setIsSubscribed] = useState(false);

  const fetchModules = async (courseId) => {
    const response = await fetch(`https://codeforcambodia.codes/codecademy/fetch/courses/${courseId}/modules`);
    if (!response.ok) {
      throw new Error('Failed to fetch modules data');
    }
    const modulesData = await response.json();
    if (!Array.isArray(modulesData) || modulesData.length === 0) {
      throw new Error('No modules found');
    }
    return modulesData.sort((a, b) => a.module_index - b.module_index);
  };

  const fetchLessons = async (courseId, moduleId) => {
    const cacheKey = `lessons_${courseId}_${moduleId}`;
    const cached = localStorage.getItem(cacheKey);
    const cacheTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    const CACHE_DURATION = 1000 * 60 * 30; // 30 minutes

    if (cached && cacheTimestamp && Date.now() - parseInt(cacheTimestamp) < CACHE_DURATION) {
      return JSON.parse(cached);
    }

    const response = await fetch(`https://codeforcambodia.codes/codecademy/fetch/courses/${courseId}/modules/${moduleId}/lessons`);
    if (!response.ok) {
      throw new Error('Failed to fetch lessons data');
    }
    const lessonsData = await response.json();
    if (!Array.isArray(lessonsData)) {
      throw new Error('Invalid lessons data');
    }

    const sortedData = lessonsData.sort((a, b) => a.lesson_index - b.lesson_index);
    
    // Cache the sorted data
    localStorage.setItem(cacheKey, JSON.stringify(sortedData));
    localStorage.setItem(`${cacheKey}_timestamp`, Date.now().toString());
    
    return sortedData;
  };

  const fetchLessonContent = async (courseId, moduleId, lessonId) => {
    const response = await fetch(`https://codeforcambodia.codes/codecademy/fetch/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch lesson content');
    }
    return await response.json();
  };

  // Modified to run in background without blocking content display
  const fetchUserProgress = async () => {
    setProgressStatus(prev => ({ ...prev, isLoading: true, error: null }));
    try {
      const response = await axios.get('https://codeforcambodia.codes/codecademy/user/progress', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          course_id: courseId,
        },
      });
      if (response.data && response.data.progress) {
        setUserProgress(response.data.progress);
      } else {
        setUserProgress({ module_index: 0, lesson_index: 0 });
      }
    } catch (error) {
      console.error('Error fetching user progress:', error);
      setProgressStatus(prev => ({ ...prev, error: error.message }));
      setUserProgress({ module_index: 0, lesson_index: 0 });
    } finally {
      setProgressStatus(prev => ({ ...prev, isLoading: false }));
      setProgressLoading(false);
    }
  };

  // Modified saveProgress to run in background
  const saveProgress = async (progressPercentage) => {
    if (!currentModule || !lesson || !userProgress) return;

    const currentModuleIndex = currentModule.module_index;
    const currentLessonIndex = lesson.lesson_index;

    const isNewProgress =
      currentModuleIndex > userProgress.module_index ||
      (currentModuleIndex === userProgress.module_index && currentLessonIndex > userProgress.lesson_index);

    if (isNewProgress) {
      setProgressStatus(prev => ({ ...prev, isLoading: true, error: null }));
      try {
        await axios.post(
          'https://codeforcambodia.codes/codecademy/user/progress',
          {
            course_id: courseId,
            module_id: moduleId,
            lesson_id: lessonId,
            progress_percentage: progressPercentage,
          },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setUserProgress({
          module_index: currentModuleIndex,
          lesson_index: currentLessonIndex,
        });
      } catch (error) {
        console.error('Error saving progress:', error);
        setProgressStatus(prev => ({ ...prev, error: error.message }));
      } finally {
        setProgressStatus(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      let subscriptionData = getLastKnownSubscription();
      if (!subscriptionData) {
        subscriptionData = await fetchSubscriptionStatus(token);
      }

      setIsSubscribed(subscriptionData.hasSubscription);
    };

    checkSubscriptionStatus();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Check course access first (fast)
        const accessStatus = await checkCourseAccess(courseId);
        console.log(accessStatus);
        setCourseAccessStatus(accessStatus);

        // If no access, stop loading other content
        if (!accessStatus.isFree && !accessStatus.hasAccess) {
          setError('មេរៀននេះត្រូវការ premium');
          setLoading(false);
          return;
        }

        // Continue with regular content loading
        const [modulesData, lessonsData] = await Promise.all([
          fetchModules(courseId),
          fetchLessons(courseId, moduleId)
        ]);
        
        setModules(modulesData);
        setLessons(lessonsData);

        const foundModule = modulesData.find((module) => module.id === parseInt(moduleId));
        if (!foundModule) throw new Error('Module not found');
        setCurrentModule(foundModule);

        if (lessonId) {
          const lessonData = await fetchLessonContent(courseId, moduleId, lessonId);
          setLesson(lessonData);
          
          // Save progress when landing on or refreshing the page
          saveProgress(0);
        }

        setLoading(false);
        
        // Fetch progress in the background after content is loaded
        fetchUserProgress();

      } catch (err) {
        setError(err.message || 'An unknown error occurred');
        setLoading(false);
      }
    };

    fetchData();
  }, [courseId, moduleId, lessonId]);

  const renderLessonContent = (courseId, moduleId, lessonId) => {
    if (!lesson) return null;

    const commonProps = {
      lesson: lesson,
      onProgressUpdate: saveProgress,
      lessonId: lessonId,
      moduleId: moduleId,
      courseId: courseId,
      isSubscribed: isSubscribed,
    };

    switch (lesson.type) {
      case 'document':
        return <DocumentLesson {...commonProps} />;
      case 'coding':
        return <CodingLesson {...commonProps} />;
      case 'quiz':
        return <QuizLesson {...commonProps} />;
      case 'web':
        return <WebDevLesson {...commonProps} />;
      case 'sql':
        return <SQLLesson {...commonProps} />;
      case 'php':
        return <PHPLesson {...commonProps} />;
      case 'video':
        return <VideoLesson {...commonProps} />;
      case 'react':
        return <ReactLesson {...commonProps} />;
      case 'react-native':
        return <ReactNativeLesson {...commonProps} />;
      case 'networking':
        return <NetworkingLesson {...commonProps} />;
      case 'flutter':
        return <FlutterLesson {...commonProps} />;
      case 'custom':
        return <CustomLesson {...commonProps} />;
      case 'python':
        return <PythonPyodide {...commonProps} />;
      case 'express':
        return <ExpressLesson {...commonProps} />;
      case 'jupyter':
        return <JupyterLesson {...commonProps} />;
      case 'scratch':
        return <ScratchLesson {...commonProps} />;
      default:
        return <p className="text-red-500">Unknown lesson type</p>;
    }
  };

  const navigateToLesson = async (direction) => {
    if (!currentModule || lessons.length === 0) return;

    const sortedLessons = [...lessons].sort((a, b) => a.lesson_index - b.lesson_index);
    const currentLessonIndex = sortedLessons.findIndex((l) => l.id === parseInt(lessonId));
    if (currentLessonIndex === -1) return;

    let newCourseId = courseId;
    let newModuleId = moduleId;
    let newLessonId = null;

    const currentModuleIndex = currentModule.module_index;

    const getNextModule = () => modules.find((m) => m.module_index === currentModuleIndex + 1);
    const getPrevModule = () => modules.find((m) => m.module_index === currentModuleIndex - 1);

    switch (direction) {
      case 'next':
        if (currentLessonIndex + 1 < sortedLessons.length) {
          // Go to next lesson in the sorted array
          newLessonId = sortedLessons[currentLessonIndex + 1].id;
        } else {
          // Try to get the first lesson of the next module
          const nextModule = getNextModule();
          if (nextModule) {
            newModuleId = nextModule.id;
            const newLessons = await fetchLessons(newCourseId, newModuleId);
            if (newLessons.length > 0) {
              const sortedNewLessons = newLessons.sort((a, b) => a.lesson_index - b.lesson_index);
              newLessonId = sortedNewLessons[0].id;
              setLessons(sortedNewLessons);
              setCurrentModule(nextModule);
            }
          }
        }
        break;
      case 'previous':
        if (currentLessonIndex > 0) {
          // Go to previous lesson in the sorted array
          newLessonId = sortedLessons[currentLessonIndex - 1].id;
        } else {
          // Try to get the last lesson of the previous module
          const prevModule = getPrevModule();
          if (prevModule) {
            newModuleId = prevModule.id;
            const newLessons = await fetchLessons(newCourseId, newModuleId);
            if (newLessons.length > 0) {
              const sortedNewLessons = newLessons.sort((a, b) => a.lesson_index - b.lesson_index);
              newLessonId = sortedNewLessons[sortedNewLessons.length - 1].id;
              setLessons(sortedNewLessons);
              setCurrentModule(prevModule);
            }
          }
        }
        break;
      case 'nextModule':
        const nextModule = getNextModule();
        if (nextModule) {
          newModuleId = nextModule.id;
          const newLessons = await fetchLessons(newCourseId, newModuleId);
          if (newLessons.length > 0) {
            const sortedNewLessons = newLessons.sort((a, b) => a.lesson_index - b.lesson_index);
            newLessonId = sortedNewLessons[0].id;
            setLessons(sortedNewLessons);
            setCurrentModule(nextModule);
          }
        }
        break;
      case 'previousModule':
        const prevModule = getPrevModule();
        if (prevModule) {
          newModuleId = prevModule.id;
          const newLessons = await fetchLessons(newCourseId, newModuleId);
          if (newLessons.length > 0) {
            const sortedNewLessons = newLessons.sort((a, b) => a.lesson_index - b.lesson_index);
            newLessonId = sortedNewLessons[0].id;
            setLessons(sortedNewLessons);
            setCurrentModule(prevModule);
          }
        }
        break;
      default:
        break;
    }

    if (newModuleId !== moduleId || newLessonId !== lessonId) {
      try {
        setLoading(true);

        if (newLessonId) {
          const newLessonContent = await fetchLessonContent(newCourseId, newModuleId, newLessonId);
          setLesson(newLessonContent);

          // Update currentModule and lessons if module has changed
          if (newModuleId !== moduleId) {
            const foundModule = modules.find((module) => module.id === parseInt(newModuleId));
            setCurrentModule(foundModule);

            const newLessons = await fetchLessons(newCourseId, newModuleId);
            setLessons(newLessons);
          }
        } else {
          setLesson(null);
        }

        setLoading(false);

        const url = `/courses/${newCourseId}/modules/${newModuleId}/lessons/${newLessonId}`;
        navigate(url);
      } catch (err) {
        setError(err.message || 'An error occurred while navigating');
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <CodeLoadingAnimation />;
  }

  if (error || !currentModule || (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess)) {
    return (
      <div className={`flex flex-col items-center justify-center h-screen ${isDarkMode?"bg-customBody":"bg-gray-100"} `}>
        <div className="flex items-center text-xl font-semibold text-red-600 mb-4 Nokora">
          <AlertCircle className="mr-2" size={24} />
          {(!courseAccessStatus.isFree && !courseAccessStatus.hasAccess) 
            ? 'មេរៀននេះត្រូវការ premium' 
            : (error || 'Module not found')}
        </div>
        <p className={ ` ${isDarkMode?"text-white":" text-gray-600"}  mb-4 Nokora`}>
          {(!courseAccessStatus.isFree && !courseAccessStatus.hasAccess)
            ? 'សូមធ្វើការ Upgrade ទៅកាន់ Premium ដើម្បីទទួលបានមេៀននេះ'
            : 'The requested content could not be found. This might be due to an incorrect URL or the content may have been moved or deleted.'}
        </p>
        <div className="flex space-x-4">
          <Link
            to="/"
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors Nokora"
          >
            <Home className="mr-2" size={20} />
            ទៅទំព័រដើម
          </Link>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors Nokora"
          >
            ត្រឡប់ក្រោយ
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`flex flex-col h-screen ${isDarkMode?"bg-customBody":"bg-white"} `}>
      <Suspense fallback={<CodeLoadingAnimation />}>
        {renderLessonContent(courseId, moduleId, lessonId)}
      </Suspense>
      
      <ProgressStatus 
        isLoading={progressStatus.isLoading}
        error={progressStatus.error}
      />

      <LessonNavigation
        currentModule={currentModule}
        currentLessonId={lessonId}
        modules={modules}
        lessons={lessons}
        currentLessonIndex={lessonId ? lessons.findIndex((l) => l.id === parseInt(lessonId)) : -1}
        courseId={courseId}
        moduleId={moduleId}
        onNavigate={navigateToLesson}
      />
      
      <Suspense fallback={<CodeLoadingAnimation />}>
        <ChatBot />
      </Suspense>
    </div>
  );
};

export default LessonContentPage;
