import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Plus, Trash, Search, FileText, Code, Check, X, ChevronRight, MoreVertical, Terminal, GitBranch, Trophy, ChevronDown, ChevronUp, ArrowLeft, Loader2 } from 'lucide-react';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import ConfirmationModal from '../../Components/ConfirmationModal';
import GeneralModal from '../../Components/Modals/GeneralModal';
import { useTheme } from '../../Contexts/ThemeContext';

const ProjectListPage = () => {
    const { isDarkMode } = useTheme();
    const { workspaceId } = useParams();
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showUnsubmitModal, setShowUnsubmitModal] = useState(false);
    const [newProjectTitle, setNewProjectTitle] = useState('');
    const [challenges, setChallenges] = useState([]);
    const [activeTab, setActiveTab] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [showSettingsMenu, setShowSettingsMenu] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [submissionDescription, setSubmissionDescription] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const [projectToSubmit, setProjectToSubmit] = useState(null);
    const [projectToUnsubmit, setProjectToUnsubmit] = useState(null);
    const [showInstructions, setShowInstructions] = useState(false);
    const [showSubmitErrorModal, setShowSubmitErrorModal] = useState(false);
    const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useState(false);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        fetchProjects();
        fetchChallenges();
    }, [workspaceId]);

    const fetchProjects = async () => {
        try {
            setIsFetching(true);
            const response = await axios.get(
                `https://codeforcambodia.codes/codecademy/challenges/project/${workspaceId}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            setProjects(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsFetching(false);
        }
    };

    const fetchChallenges = async () => {
        try {
            const response = await axios.get('https://codeforcambodia.codes/codecademy/challenges/challenge', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setChallenges(response.data.data);
        } catch (error) {
            console.error('Error fetching challenges:', error);
        }
    };

    const createProject = async (e) => {
        e.preventDefault();
        try {
            setIsCreating(true);
            const response = await axios.post(
                `https://codeforcambodia.codes/codecademy/challenges/project/create/${workspaceId}`,
                {
                    title: newProjectTitle,
                    htmlCode: '',
                    cssCode: '',
                    jsCode: '',
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            await fetchProjects();
            setShowCreateModal(false);
            setNewProjectTitle('');
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsCreating(false);
        }
    };

    const deleteProject = async (projectId) => {
        try {
            await axios.post(
                `https://codeforcambodia.codes/codecademy/challenges/project/delete/workspace/${workspaceId}/project/${projectId}`,
                {},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            setProjects(projects.filter((p) => p.id !== projectId));
            setShowDeleteModal(false);
            setProjectToDelete(null);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const submitProject = async (projectId) => {
        try {
            // First, fetch the project's current code
            const projectResponse = await axios.get(
                `https://codeforcambodia.codes/codecademy/challenges/project/workspace/${workspaceId}/project/${projectId}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            
            const project = projectResponse.data.data;
            
            // Check if HTML is empty
            if (!project.html_code?.trim()) {
                setShowSubmitErrorModal(true);
                setShowSubmitModal(false);
                return;
            }

            // Proceed with submission if validation passes
            await axios.post(
                `https://codeforcambodia.codes/codecademy/challenges/submission/submit`,
                { projectId, description: '' },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            fetchProjects();
            setProjectToSubmit(null);
            setShowSubmitModal(false);
            setShowSubmitSuccessModal(true);
        } catch (error) {
            console.error('Error submitting project:', error);
            setShowGeneralErrorModal(true);
        }
    };
    
    const unsubmitProject = async (projectId) => {
        try {
            const project = projects.find(p => p.id === projectId);
            if (!project || !project.submission_id) {
                throw new Error('No submission found for this project');
            }
            
            await axios.post(
                `https://codeforcambodia.codes/codecademy/challenges/submission/unsubmit/${project.submission_id}`,
                {},
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            fetchProjects();
            setProjectToUnsubmit(null);
            setShowUnsubmitModal(false);
        } catch (error) {
            console.error('Error unsubmitting project:', error);
            alert('Error unsubmitting project');
        }
    };

    const filteredProjects = projects.filter(project => {
        const matchesSearch = project.title.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesTab = activeTab === 'all' || 
            (activeTab === 'submitted' && project.submission_id) ||
            (activeTab === 'pending' && !project.submission_id);
        return matchesSearch && matchesTab;
    });

    return (
        <div className={`${isDarkMode?"bg-customBody":""}`}>
        <Header/>
        <div className={`p-8 max-w-7xl mx-auto min-h-[75vh] ${isDarkMode?"bg-customBody":""}`}>
            <button 
                onClick={() => window.location.href = 'https://codeforcambodia.com/workspace'}
                className={`flex items-center gap-2 ${isDarkMode?"text-customBodyText hover:text-customHeaderText":"text-gray-600 hover:text-gray-900"}  mb-6`}
            >
                <ArrowLeft className="w-5 h-5" />
                <span className={`Nokora`}>ទៅកាន់កន្លែងធ្វើការ</span>
            </button>

            <h1 className={`text-2xl font-semibold mb-6 Nokora ${isDarkMode?"text-customHeaderText":""}`}> Project របស់អ្នក</h1>
            
            {/* Tabs and Search */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
                <div className="flex space-x-8 border-b border-gray-200 w-full sm:w-auto">
                    <button
                        onClick={() => setActiveTab('all')}
                        className={`pb-4 px-2 Nokora ${activeTab === 'all' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
                    >
                        All
                    </button>
                    <button
                        onClick={() => setActiveTab('submitted')}
                        className={`pb-4 px-2 Nokora ${activeTab === 'submitted' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
                    >
                        Submitted
                    </button>
                    <button
                        onClick={() => setActiveTab('pending')}
                        className={`pb-4 px-2 Nokora ${activeTab === 'pending' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
                    >
                        Pending
                    </button>
                </div>
                <div className="flex items-center gap-4 w-full sm:w-auto">
                    <div className="relative flex-grow sm:flex-grow-0">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                        <input
                            type="text"
                            placeholder="ស្វែងរក project ..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10 pr-4 py-2 border rounded-lg w-full sm:w-64 focus:outline-none focus:ring-2 focus:ring-blue-500 Nokora"
                        />
                    </div>
                    <button
                        onClick={() => setShowCreateModal(true)}
                        className="bg-blue-600 hover:bg-blue-700 text-white px-2 py-2 md:px-4 md:py-2 rounded-lg flex items-center Nokora text-xs md:text-base"
                    >
                        <Plus className="w-5 h-5 mr-0 md:mr-2" />
                        បង្កើត Project ថ្មី
                    </button>
                </div>
            </div>

            {/* Instructions */}
            <div className={`mb-8 p-4 rounded-lg ${isDarkMode?"bg-customBodyDarken1":"bg-gray-50"}  dark:bg-gray-800`}>
                <button 
                    onClick={() => setShowInstructions(!showInstructions)}
                    className={`w-full flex items-center justify-between ${isDarkMode?"text-customBodyText":"text-gray-900"} dark:text-white`}
                >
                    <h3 className="font-bold text-lg flex items-center gap-2 Nokora">
                        <Code className="w-5 h-5" />
                        របៀបប្រើប្រាស់ project 
                    </h3>
                    {showInstructions ? (
                        <ChevronUp className="w-5 h-5" />
                    ) : (
                        <ChevronDown className="w-5 h-5" />
                    )}
                </button>
                {showInstructions && (
                    <div className={`grid grid-cols-2 gap-3 text-sm ${isDarkMode?"text-customBodyText":"text-gray-600"}  mt-3`}>
                        <p className="flex items-center gap-2 Nokora">
                            <Plus className="w-4 h-4" />
                            បង្កើត project ថ្មីសម្រាប់ការប្រកួត
                        </p>
                        <p className="flex items-center gap-2 Nokora">
                            <Terminal className="w-4 h-4" />
                            សរសេរកូដរបស់អ្នក
                        </p>
                        <p className="flex items-center gap-2 Nokora">
                            <GitBranch className="w-4 h-4" />
                            Save ការងាររបស់អ្នក
                        </p>
                        <p className="flex items-center gap-2 Nokora">
                            <Trophy className="w-4 h-4" />
                            Submit project របស់អ្នក ចូលប្រកួត
                        </p>
                    </div>
                )}
            </div>

            {/* Projects Table */}
            <div className={`${isDarkMode?"bg-customBody":"bg-white "} rounded-lg shadow h-screen`}>
                <div className={`grid grid-cols-12 gap-4 p-4 border-b border-gray-200 ${isDarkMode?"bg-customBodyDarken1 text-customHeaderText":"bg-gray-50 text-gray-500 border-gray-200 "}  text-sm `}>
                    <div className="col-span-4 Nokora">Name</div>
                    <div className="col-span-2 Nokora">Type</div>
                    <div className="col-span-2 Nokora">Challenge</div>
                    <div className="col-span-2 Nokora">Last Update</div>
                    <div className="col-span-2 Nokora">Action</div>
                </div>
                
                <div className="divide-y divide-gray-200">
                    {isFetching ? (
                        <div className="flex justify-center items-center h-24">
                            <Loader2 className="w-6 h-6 animate-spin text-blue-500" />
                        </div>
                    ) : filteredProjects.map((project) => (
                        <div key={project.id} className={`grid grid-cols-12 gap-4 p-4 items-center  ${isDarkMode?"hover:bg-customBodyDarken1":"hover:bg-gray-50"}`}>
                            <div className="col-span-4 flex items-center gap-3">
                                <FileText className="w-5 h-5 text-blue-500" />
                                <span className={`${isDarkMode?"text-customHeaderText":""} font-medium`}>{project.title}</span>
                            </div>
                            <div className={`col-span-2 text-sm ${isDarkMode?"text-customBodyText":"text-gray-600"}  Nokora`}>
                                Project
                            </div>
                            <div className={`col-span-2 text-sm ${isDarkMode?"text-customBodyText":"text-gray-600"}  Nokora`}>
                                {project.challenge_title || 'មិនទាន់កំណត់'}
                            </div>
                            <div className={`col-span-2 text-sm ${isDarkMode?"text-customBodyText":"text-gray-600"}`}>
                                {new Date(project.updated_at).toLocaleDateString()}
                            </div>
                            <div className="col-span-2 flex items-center justify-between">
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium Nokora ${
                                    project.submission_id 
                                        ? 'bg-green-100 text-green-800'
                                        : 'bg-yellow-100 text-yellow-800'
                                }`}>
                                    {project.submission_id ? 'Submited' : 'Pending'}
                                </span>
                                <div className="flex items-center gap-2">
                                    <button
                                        onClick={() => navigate(`/workspace/${workspaceId}/project/${project.id}`)}
                                        className="text-blue-600 hover:text-blue-700"
                                    >
                                        <ChevronRight className="w-5 h-5" />
                                    </button>
                                    <div className="relative">
                                        <button
                                            onClick={() => setShowSettingsMenu(showSettingsMenu === project.id ? null : project.id)}
                                            className="text-gray-600 hover:text-gray-800"
                                        >
                                            <MoreVertical className="w-5 h-5" />
                                        </button>
                                        {showSettingsMenu === project.id && (
                                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                                                {!project.submission_id && (
                                                    <button
                                                        onClick={() => {
                                                            setShowSettingsMenu(null);
                                                            setProjectToSubmit(project.id);
                                                            setShowSubmitModal(true);
                                                        }}
                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 Nokora"
                                                    >
                                                        Submit
                                                    </button>
                                                )}
                                                {project.submission_id && (
                                                    <button
                                                        onClick={() => {
                                                            setShowSettingsMenu(null);
                                                            setProjectToUnsubmit(project.id);
                                                            setShowUnsubmitModal(true);
                                                        }}
                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 Nokora"
                                                    >
                                                        Unsubmit
                                                    </button>
                                                )}
                                                <button
                                                    onClick={() => {
                                                        setShowSettingsMenu(null);
                                                        setProjectToDelete(project.id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 Nokora"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Create Modal */}
            {showCreateModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg w-96 max-w-full">
                        <h2 className="text-xl font-bold mb-4 Nokora">បង្កើត Project ថ្មី</h2>
                        <form onSubmit={createProject}>
                            <input
                                type="text"
                                placeholder="ឈ្មោះ project "
                                value={newProjectTitle}
                                onChange={(e) => setNewProjectTitle(e.target.value)}
                                className="w-full border p-2 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 Nokora"
                            />
                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    disabled={isCreating}
                                    onClick={() => setShowCreateModal(false)}
                                    className="px-4 py-2 border rounded-lg hover:bg-gray-50 Nokora disabled:opacity-50"
                                >
                                    បោះបង់
                                </button>
                                <button 
                                    type="submit" 
                                    disabled={isCreating}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 Nokora disabled:opacity-50"
                                >
                                    បង្កើត
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <ConfirmationModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={() => deleteProject(projectToDelete)}
                title="លុប project "
                confirmText="លុប"
                confirmButtonClass="bg-red-600 hover:bg-red-700"
            >
                <p className='Nokora'>បើអ្នក delete នឹងបាត់ project។ ច្បាស់អត់?</p>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={showSubmitModal}
                onClose={() => setShowSubmitModal(false)}
                onConfirm={() => submitProject(projectToSubmit)}
                title="Submit project "
                confirmText="Submit"
                confirmButtonClass="bg-green-600 hover:bg-green-700"
            >
                <p className='Nokora'>ពេល submit project នឹងក្លាយជាសាធារណៈ។ ចង់ update គ្រាន់ update code វានឹង update auto</p>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={showUnsubmitModal}
                onClose={() => setShowUnsubmitModal(false)}
                onConfirm={() => unsubmitProject(projectToUnsubmit)}
                title="Unsubmit Project"
                confirmText="Unsubmit"
                confirmButtonClass="bg-red-600 hover:bg-red-700"
            >
                <p className='Nokora'>បើអ្នក unsubmit នឹងបាត់ vote។ ច្បាស់អត់?</p>
            </ConfirmationModal>

            <GeneralModal
                isOpen={showSubmitErrorModal}
                onClose={() => setShowSubmitErrorModal(false)}
                title="Validation Error"
            >
                <p className="Nokora">HTML content is required to submit the project. CSS and JavaScript are optional.</p>
            </GeneralModal>

            <GeneralModal
                isOpen={showSubmitSuccessModal}
                onClose={() => setShowSubmitSuccessModal(false)}
                title="Success"
            >
                <p className="Nokora">Project submitted successfully!</p>
            </GeneralModal>

            <GeneralModal
                isOpen={showGeneralErrorModal}
                onClose={() => setShowGeneralErrorModal(false)}
                title="Error"
            >
                <p className="Nokora">An error occurred while submitting the project. Please try again.</p>
            </GeneralModal>

        </div>
        <Footer/>
        </div>
    );
};

export default ProjectListPage;