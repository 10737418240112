import React, { useEffect, useState } from 'react'
import JupyterLiteEmbed from '../Components/Lessons/Jupyter/JupyterLab'
import Draggable from 'react-draggable'
import { useTheme } from '../Contexts/ThemeContext'
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { ArrowUpLeft } from 'lucide-react';
import ScratchEmbed from '../Components/Lessons/Scratch/ScratchEmbed';
const DraggableJupyter = ({ onFullTab }) => {

    const { isDarkMode } = useTheme();
    const [maxWidth, setMaxWidth] = useState(window.innerWidth);
    const [maxHeight, setMaxHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setMaxWidth(window.innerWidth); // Update max width with viewport width
            setMaxHeight(window.innerHeight); // Update max height with viewport height
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleOnFullTab = () => {
        onFullTab((true));
    };
    return (
        < div className="flex items-center justify-center fixed z-20 " >
            <Draggable handle=".drag-handle"  >
                <div className="relative w-fit">
                    <ResizableBox
                        width={500}
                        height={600}
                        axis='both'
                        minConstraints={[300, 400]}
                        maxConstraints={[maxWidth - 50, maxHeight - 50]}
                        className={`${isDarkMode ? "bg-customBodyDarken1" : "bg-white"} shadow-md border border-gray-300 rounded-md `}
                    >
                        <div className="w-full h-full drag-handle">
                            <button
                                className={`drop-target ​​px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm ${isDarkMode ? "bg-customBodyDarken1AndHalf" : ""} 
                font-medium border-b-2 flex-1 text-blue-600 border-blue-500 border-transparent w-full flex items-center justify-between
                     `}
                            >
                                <ArrowUpLeft onClick={handleOnFullTab} />
                                <div className='text-center'>
                                    Jupyter Notebook
                                </div>
                            </button>
                            <div className="w-[99%] h-[90%] mx-auto">
                                <ScratchEmbed />
                            </div>
                        </div>

                    </ResizableBox>
                </div>
            </Draggable>
        </div >
    )
}


export default DraggableJupyter
