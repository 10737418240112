"use client"

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Clock, Book, ChevronDown, ChevronUp, PlayCircle, RefreshCw, X } from 'lucide-react'
import axios from 'axios'
import {useTheme} from "../../Contexts/ThemeContext"
export default function LearningPathHeader({ learningPath, saveLearningPath, pathProgress, refreshProgress, status }) {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
  const [isResetting, setIsResetting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {isDarkMode} = useTheme();
  const toggleDescription = () => setIsDescriptionExpanded(!isDescriptionExpanded)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  // Function to reset learning path progress
  const resetLearningPathProgress = async () => {
    setIsResetting(true)
    const token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${token}`
    }

    try {
      const response = await axios.delete(`https://codeforcambodia.codes/codecademy/learningpath/progress/${learningPath.id}`, { headers })
      console.log(response.data)
      alert('វឌ្ឍនភាពរបស់អ្នកត្រូវបានកំណត់ឡើងវិញ។')
      if (refreshProgress) {
        refreshProgress()
      }
    } catch (error) {
      console.error('Error resetting progress:', error)
      alert('មិនអាចកំណត់វឌ្ឍនភាពឡើងវិញបានទេ។ សូមព្យាយាមម្តងទៀត។')
    } finally {
      setIsResetting(false)
      closeModal()
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-50"} border border-gray-200 rounded-lg p-6 mb-8 font-mono`}
    >
      <div className={` flex flex-col ${isDarkMode?"bg-customBodyDarken1AndHalf":""} sm:flex-row items-start sm:items-center mb-4`}>
        <img src={learningPath.icon} alt="" className="w-12 h-12 rounded-md mr-4 mb-2 sm:mb-0 " />
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-blue-600 Nokora">{learningPath.learning_path_name}</h1>
      </div>
      <div className="relative mb-6">
        <pre className={`text-sm sm:text-base ${isDarkMode?"text-customBodyText":"text-gray-600"}  whitespace-pre-wrap Nokora ${isDescriptionExpanded ? '' : 'line-clamp-3'}`}>
          {learningPath.description}
        </pre>
        <button 
          onClick={toggleDescription}
          className="text-blue-500 hover:text-blue-700 Nokora text-sm flex items-center mt-2"
        >
          {isDescriptionExpanded ? (
            <>
              <ChevronUp size={16} className="mr-1" />
              <p>បន្ថយ()</p>
            </>
          ) : (
            <>
              <ChevronDown size={16} className="mr-1" />
              <p>បន្ថែម()</p>
            </>
          )}
        </button>
      </div>
      <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
        {status === 0 ? (
          <button 
            disabled
            className="w-full sm:w-auto bg-gray-400 text-white py-3 px-6 rounded-md transition-all duration-300 ease-in-out transform flex items-center justify-center focus:outline-none cursor-not-allowed"
          >
            <Clock className="mr-2" size={20} />
            <span className="Nokora">មកដល់ឆាប់ៗនេះ</span>
          </button>
        ) : (
          <button 
            onClick={saveLearningPath} 
            className="w-full sm:w-auto bg-green-500 hover:bg-green-600 text-white py-3 px-6 rounded-md transition-all duration-300 ease-in-out transform flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            <PlayCircle className="mr-2" size={20} />
            <span className="Nokora">ចាប់ផ្តើមរៀន</span>
          </button>
        )}
        <button 
          onClick={openModal}
          className="mt-2 sm:mt-0 text-gray-500 hover:text-red-500 py-2 rounded-md transition-all duration-300 ease-in-out flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
        >
          <RefreshCw className="mr-1" size={16} />
          <span className="Nokora text-sm">ចាប់ផ្តើមពី 0</span>
        </button>
      </div>
      <p className="text-gray-500 text-sm mt-2 text-center sm:text-left Nokora">ចុចដើម្បីចាប់ផ្តើមការសិក្សារបស់អ្នក</p>

      <div className="mt-4">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm text-gray-600 Nokora">វឌ្ឍនភាពសរុប</span>
          <span className="text-sm font-semibold text-blue-600">{pathProgress ? `${pathProgress.overall_progress}%` : '0%'}</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div 
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
            style={{ width: `${pathProgress ? pathProgress.overall_progress : 0}%` }}
          ></div>
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-lg p-6 max-w-sm w-full m-4"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-800 Nokora">បញ្ជាក់ការកំណត់ឡើងវិញ</h2>
                <button 
                  onClick={closeModal} 
                  className="text-gray-500 hover:text-gray-700"
                  aria-label="Close modal"
                >
                  <X size={24} />
                </button>
              </div>
              <p className="text-gray-600 mb-6 Nokora">
                តើអ្នកប្រាកដជាចង់កំណត់វឌ្ឍនភាពរបស់អ្នកឡើងវិញមែនទេ? សកម្មភាពនេះមិនអាចបញ្ចេញវិញបានទេ។
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors Nokora"
                >
                  បោះបង់
                </button>
                <button
                  onClick={resetLearningPathProgress}
                  disabled={isResetting}
                  className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed Nokora flex items-center"
                >
                  {isResetting && <RefreshCw className="animate-spin mr-2" size={16} />}
                  <span>{isResetting ? 'Resetting...' : 'Reset'}</span>
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}