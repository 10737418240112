import React, { useState } from 'react';
import { Loader, Trophy } from 'lucide-react';
import SubmissionCard from './SubmissionCard';
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import { useTheme } from '../../Contexts/ThemeContext';
const SubmissionsList = ({
    submissions,
    sortBy,
    handleSortChange,
    page,
    setPage,
    castVote,
    removeVote,
    isSearching,
    onSearch,
    onClear,
    searchCount,
    isFetching
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();
    return (
        <div className="max-w-7xl mx-auto px-4 py-8 font-mono">
            {/* Header with Sort Controls */}
            <div className="mb-8 flex flex-col sm:flex-row justify-between  items-start sm:items-center gap-4">
                <h2 className="text-xl font-bold text-gray-900">
                    {isSearching ? 'Search Results' : 'All Submissions'}
                </h2>

                {/* Search and Submissions Section */}
                <div className="flex flex-col items-center justify-center w-3/5 gap-6">
                    <SearchBar
                        onSearch={onSearch}
                        onClear={onClear}
                        isSearching={isSearching}
                        searchCount={searchCount}
                    />
                    {submissions.length === 0 && isSearching && (
                        <div className="text-gray-500 text-center">
                            No submissions found
                        </div>
                    )}
                </div>

                <div className="relative">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg
                            bg-white border border-gray-300 hover:border-gray-400 
                            text-gray-700 transition-all duration-200 ease-in-out
                            focus:outline-none focus:ring-2 focus:ring-gray-200"
                    >
                        {sortBy === 'newest' ? 'Newest' : 'Popular'}
                        <svg
                            className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>

                    {isOpen && (
                        <div className="absolute right-0 mt-2 w-40 z-[100] rounded-lg shadow-lg bg-white border border-gray-100 py-1
                            animate-in fade-in slide-in-from-top-2 duration-200"
                        >
                            <button
                                onClick={() => {
                                    handleSortChange('newest');
                                    setIsOpen(false);
                                }}
                                className={`w-full text-left px-4 py-2 text-sm hover:bg-gray-50 transition-colors
                                    ${sortBy === 'newest' ? 'text-blue-600 font-medium' : 'text-gray-700'}`}
                            >
                                Newest
                            </button>
                            <button
                                onClick={() => {
                                    handleSortChange('votes');
                                    setIsOpen(false);
                                }}
                                className={`w-full text-left px-4 py-2 text-sm hover:bg-gray-50 transition-colors
                                    ${sortBy === 'votes' ? 'text-blue-600 font-medium' : 'text-gray-700'}`}
                            >
                                Popular
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Submissions Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {submissions.map((submission, index) => (
                    // <button onClick={() => navigate(`/submission/${submission.id}`)}>
                    <SubmissionCard
                        key={submission.id}
                        submission={submission}
                        index={index}
                        onVote={castVote}
                        onRemoveVote={removeVote}
                    />
                    // </button>
                ))}
            </div>


            {isFetching ? (
                <div className="flex justify-center items-center h-24">
                    <Loader className="w-6 h-6 animate-spin text-blue-500" />
                </div>
            ) : submissions.length === 0 && (
                <div className="text-center py-16">
                    <Trophy className="w-12 h-12 text-gray-300 mx-auto mb-4" />
                    <p className="text-gray-500 text-lg">
                        No submissions yet. Be the first to participate!
                    </p>
                </div>
            )}

            {/* Pagination */}

            {submissions.length > 0 && (
                <div className="mt-12 flex justify-center gap-2">
                    <button
                        onClick={() => setPage((p) => Math.max(1, p - 1))}
                        disabled={page === 1}
                        className={`px-4 py-2 text-sm font-medium rounded-full transition-colors
                            disabled:opacity-50 disabled:cursor-not-allowed
                            ${isDarkMode ? "text-customBodyText disabled:text-gray-400 hover:text-gray-600" : "text-gray-600 hover:text-gray-900 disabled:text-gray-400"}
                    `}>
                        Previous
                    </button>
                    <span className={`px-4 py-2 text-sm font-medium ${isDarkMode? "text-customBodyText" : "text-gray-600 "}`}>
                        Page {page}
                    </span>
                    <button
                        onClick={() => setPage((p) => p + 1)}
                        disabled={submissions.length < 10}
                        className={`px-4 py-2 text-sm font-medium rounded-full transition-colors
                            disabled:opacity-50 disabled:cursor-not-allowed
                           ${isDarkMode ? "text-customBodyText disabled:text-gray-400 hover:text-gray-600" : "text-gray-600 hover:text-gray-900 disabled:text-gray-400"}`}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default SubmissionsList;