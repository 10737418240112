import React, { useState, useEffect } from 'react';
import { FileText, Code, Play, Maximize2 } from 'lucide-react';
import parse from 'html-react-parser';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';
import Footer from '../../Components/Footer';
import options from '../../Components/Lessons/OptionsQuill';
import { useTheme } from '../../Contexts/ThemeContext';

// Reusable Button component
const Button = React.memo(({ children, onClick, className, icon: Icon }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-3 py-1 sm:py-2 rounded-md text-xs sm:text-sm font-medium transition-colors duration-150 flex items-center ${className}`}
  >
    {Icon && <Icon size={14} className="mr-1 sm:mr-2" />}
    {children}
  </button>
));

export default function CustomLesson({ lesson, isSubscribed }) {
  const [isLoading, setIsLoading] = useState(false);
  const [customHtml, setCustomHtml] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const {isDarkMode} = useTheme();
  const [showExpectedCode, setShowExpectedCode] = useState(false);
  const toggleFullscreen = () => {
    const iframeElement = document.querySelector('.lesson-iframe');
    
    if (!document.fullscreenElement) {
      if (iframeElement.requestFullscreen) {
        iframeElement.requestFullscreen();
      } else if (iframeElement.webkitRequestFullscreen) {
        iframeElement.webkitRequestFullscreen();
      } else if (iframeElement.msRequestFullscreen) {
        iframeElement.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    async function fetchHtmlContent() {
      if (lesson?.file_url) {
        setIsLoading(true);
        try {
          const response = await fetch(lesson.file_url);
          const htmlContent = await response.text();
          setCustomHtml(htmlContent);
        } catch (error) {
          console.error('Error loading custom HTML:', error);
          setCustomHtml('<p>Error loading content. Please try again later.</p>');
        } finally {
          setIsLoading(false);
        }
      }
    }
    
    fetchHtmlContent();
  }, [lesson]);

  return (
    <div className="min-h-screen flex flex-col bg-white text-gray-800 font-sans">
      {isSubscribed ? <CodeForCambodiaHeader /> : <FreeTrialHeader />}

      <div className="flex-grow flex">
        {/* Instructions Panel - 1/3 width */}
        <div className={`w-1/3 ${isDarkMode?"bg-customBodyDarken1 text-white":"bg-white "} overflow-y-auto border-r border-gray-200`}>
          <div className="max-w-none p-4 prose">
            {lesson?.instructions ? 
              parse(lesson.instructions, options) : 
              <p className="text-gray-600 italic">No instructions available for this lesson.</p>
            }
            {lesson?.starter_code && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold text-purple-500 font-mono ">Starter Code</h3>
                <div className="relative">
                  <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg font-mono text-sm overflow-x-auto">
                    {lesson.starter_code}
                  </pre>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(lesson.starter_code);
                      const btn = document.getElementById('copy-starter');
                      btn.innerHTML = 'Copied!';
                      setTimeout(() => {
                        btn.innerHTML = 'Copy';
                      }, 2000);
                    }}
                    id="copy-starter"
                    className="absolute top-2 right-2 px-2 py-1 text-xs font-medium text-gray-300 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition-all duration-200"
                  >
                    Copy
                  </button>
                </div>
              </div>
            )}
            {lesson?.expected_code && (
              <div className="mt-4">
                <button
                  onClick={() => setShowExpectedCode(!showExpectedCode)}
                  className="flex items-center text-lg font-semibold hover:text-blue-600 transition-colors duration-200"
                >
                  <h3 className="text-purple-500 font-mono">Expected Code</h3>
                  <svg
                    className={`font-mono text-purple-500 w-5 h-5 ml-2 transform transition-transform duration-200 ${
                      showExpectedCode ? 'rotate-180' : ''
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                {showExpectedCode && (
                  <div className="relative mt-2">
                    <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg font-mono text-sm overflow-x-auto">
                      {lesson.expected_code}
                    </pre>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(lesson.expected_code);
                        const btn = document.getElementById('copy-expected');
                        btn.innerHTML = 'Copied!';
                        setTimeout(() => {
                          btn.innerHTML = 'Copy';
                        }, 2000);
                      }}
                      id="copy-expected"
                      className="absolute top-2 right-2 px-2 py-1 text-xs font-medium text-gray-300 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition-all duration-200"
                    >
                      Copy
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Custom HTML Panel - 2/3 width */}
        <div className="w-2/3 relative">
          {!isLoading && (
            <button
              onClick={toggleFullscreen}
              className="absolute top-2 right-2 p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-150 z-10"
            >
              <Maximize2 size={20} />
            </button>
          )}
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
            </div>
          ) : (
            <iframe
              srcDoc={customHtml}
              className="w-full h-full border-0 lesson-iframe"
              title="Custom Lesson Content"
              sandbox="allow-scripts allow-same-origin"
            />
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
