import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../../Contexts/ThemeContext';
import {
  FolderIcon,
  FileIcon,
  ChevronRight,
  ChevronDown,
  Trash2,
  Plus,
  File
} from 'lucide-react';
const FileTree = ({ node, path = '', onSelect, selectedFile, onDelete, level = 0 }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { isDarkMode } = useTheme();
  if (node.type === 'file') {
    return (
      <motion.div
        className={`flex items-center py-2 px-3 cursor-pointer group transition-colors duration-200
          ${selectedFile === path
            ? `${isDarkMode ? "bg-customBodyDarken1AndHalf text-white" : "bg-blue-50"} border-l-2 border-blue-500`
            : ` ${isDarkMode ? "text-gray-300" : "hover:bg-gray-50"} border-l-2 border-transparent`
          }`}
        style={{ paddingLeft: `${level * 16 + 8}px` }}
        onClick={() => onSelect(path)}
        whileHover={{ x: 2 }}
      >
        <FileIcon className="w-4 h-4 mr-2.5 text-blue-500/70" />
        <span className={`flex-1 text-sm  ${isDarkMode ? " group-hover:text-white" : "text-gray-600 group-hover:text-gray-900"} `}>{node.name}</span>
        <motion.button
          onClick={(e) => {
            e.stopPropagation();
            onDelete(path);
          }}
          className="opacity-0 group-hover:opacity-100 text-gray-400 hover:text-red-500"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <Trash2 className="w-3.5 h-3.5" />
        </motion.button>
      </motion.div>
    );
  }

  return (
    <div>
      <motion.div
        className={`flex items-center py-2 px-3 cursor-pointer ${isDarkMode ? "text-gray-200 hover:bg-gray-800 " : "text-gray-700 hover:bg-gray-50/80 "} `}
        style={{ paddingLeft: `${level * 16 + 8}px` }}
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ x: 2 }}
      >
        <motion.div
          animate={{ rotate: isOpen ? 90 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronRight className="w-3.5 h-3.5 mr-1.5 text-gray-400" />
        </motion.div>
        <FolderIcon className="w-4 h-4 mr-2.5 text-blue-400" />
        <span className="text-sm font-medium">
          {node.name === '/' ? 'Project Files' : node.name}
        </span>
      </motion.div>
      <AnimatePresence>
        {isOpen && node.children && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            {Object.entries(node.children).map(([name, child]) => (
              <FileTree
                key={`${path}/${name}`}
                node={{ ...child, name }}
                path={`${path}/${name}`}
                onSelect={onSelect}
                selectedFile={selectedFile}
                onDelete={onDelete}
                level={level + 1}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const NewFileInput = ({ onCreateFile }) => {
  const [fileName, setFileName] = useState('');
  const { isDarkMode } = useTheme();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (fileName.trim()) {
      onCreateFile(fileName);
      setFileName('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`p-3 border-t ${isDarkMode ? "bg-customBody " : "bg-gradient-to-b from-white to-gray-50"}  `}>
      <div className="flex gap-2">
        <input
          type="text"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="New file name"
          className="flex-1 px-3 py-1.5 text-sm border rounded-md bg-white
            placeholder:text-gray-400 focus:outline-none focus:ring-2 
            focus:ring-blue-500/50 focus:border-blue-500 shadow-sm
            text-gray-800"
            
        />
        <motion.button
          type="submit"
          className="p-1.5 bg-blue-500 text-white rounded-md shadow-sm
            hover:bg-blue-600 focus:outline-none focus:ring-2 
            focus:ring-blue-500 focus:ring-offset-2"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Plus className="w-4 h-4" />
        </motion.button>
      </div>
    </form>
  );
};

export default function FileExplorer({
  files,
  selectedFile,
  onSelectFile,
  onCreateFile,
  onDeleteFile
}) {
  const { isDarkMode } = useTheme();
  return (
    <div className={`max-h-[600px] hidden sm:flex w-56 lg:w-64 border-r border-gray-200 h-full flex-col 
      ${isDarkMode ? "bg-customBodyDarken1 " : "bg-gradient-to-br from-gray-50 via-white to-gray-50"}
       shadow-lg`}>
      <div className={`p-3 border-b bg-gradient-to-r from-gray-50 to-white`}>
        <div className="flex items-center gap-2.5">
          <File className="w-4 h-4 text-blue-500" />
          <span className={`text-sm font-semibold ${isDarkMode ? "text-gray-800" : "text-gray-700"} `}>Explorer</span>
        </div>
      </div>
      <div className="flex-1 overflow-auto scrollbar-thin scrollbar-thumb-gray-200 
        scrollbar-track-transparent hover:scrollbar-thumb-gray-300">
        <FileTree
          node={files['/']}
          onSelect={onSelectFile}
          selectedFile={selectedFile}
          onDelete={onDeleteFile}
        />
      </div>
      <NewFileInput onCreateFile={onCreateFile} />
    </div>
  );
}