import React, { useState } from 'react';
import { Maximize2, Minimize2 } from 'lucide-react';

const ScratchEmbed = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    const iframeElement = document.querySelector('.scratch-iframe');
    
    if (!document.fullscreenElement) {
      if (iframeElement.requestFullscreen) {
        iframeElement.requestFullscreen();
      } else if (iframeElement.webkitRequestFullscreen) {
        iframeElement.webkitRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  return (
    <div className="w-full h-full">
      <div className="relative w-full h-full">
       
        <div className="absolute top-2 right-2 z-20">
          <button
            onClick={toggleFullscreen}
            className="p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-150"
          >
            {isFullscreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
          </button>
        </div>
        <iframe
          src="https://sheeptester.github.io/scratch-gui/"
          className="w-full h-full border-0 scratch-iframe"
          allow="clipboard-read; clipboard-write"
          title="Scratch Editor"
        />
      </div>
    </div>
  );
};

export default ScratchEmbed;