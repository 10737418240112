'use client'

import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../Contexts/ThemeContext'
export default function FlutterCompiler() {
  const {isDarkMode} = useTheme();
  const [error, setError] = useState('')
  // Default Flutter code to render
  const code = `
import 'package:flutter/material.dart';

void main() {
  runApp(const MyApp());
}

class MyApp extends StatelessWidget {
  const MyApp({super.key});

  @override
  Widget build(BuildContext context) {
    return MaterialApp(
      debugShowCheckedModeBanner: false,
      home: Scaffold(
        body: Container(
          decoration: const BoxDecoration(
            color: Color(0xFFF0F0F0),
          ),
          child: const Center(
            child: Column(
              mainAxisAlignment: MainAxisAlignment.center,
              children: [
                Text(
                  'Hello from Cambodia! 🇰🇭',
                  style: TextStyle(
                    fontSize: 24,
                    fontWeight: FontWeight.bold,
                    color: Color(0xFF333333),
                  ),
                ),
                SizedBox(height: 10),
                Text(
                  'This is a Flutter Web simulation!',
                  style: TextStyle(
                    fontSize: 16,
                    color: Color(0xFF666666),
                  ),
                ),
              ],
            ),
          ),
        ),
      ),
    );
  }
}`

  useEffect(() => {
    compileAndRunCode()
  }, [])

  const compileAndRunCode = async () => {
    try {
      // Create a URL-safe version of the code for DartPad
      const encodedCode = encodeURIComponent(code)
      const dartpadUrl = `https://dartpad.dev/embed-flutter.html?source=${encodedCode}&split=70&theme=dark&run=true`

      // Update the iframe source
      const iframe = document.getElementById('flutter-frame')
      if (iframe) {
        iframe.src = dartpadUrl
      }

      setError('')
    } catch (err) {
      console.error('Detailed error:', err)
      setError(`Compilation Error: ${err.message}`)
    }
  }

  return (
    <div className={`h-full w-full flex  justify-center bg-gradient-to-br ${isDarkMode?"bg-customBody":"from-gray-100 to-gray-200"} p-4`}>
      <div className="relative transform-gpu">
        {/* Phone Frame */}
        <div className="w-[187.5px] h-[406px] bg-black rounded-[30px] p-2 shadow-2xl overflow-hidden relative">
          {/* Screen */}
          <div className="absolute inset-2 bg-white rounded-[22.5px] overflow-hidden">
            {/* Notch */}
            <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[40%] h-3.5 bg-black rounded-b-2xl z-10"></div>
            
            {/* Dynamic Content Container */}
            <div className="absolute inset-0 overflow-hidden">
              <iframe
                id="flutter-frame"
                className="w-full h-full border-none"
                sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
                style={{
                  backgroundColor: 'transparent',
                  pointerEvents: 'auto'
                }}
              />
              {error && (
                <div className="absolute inset-0 bg-red-50 p-4">
                  <div className="text-red-500 font-mono text-sm whitespace-pre-wrap">
                    {error}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
