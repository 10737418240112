import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Play, Square } from 'lucide-react';
import { usePacketAnalyzer } from '../logic/wireshark/usePacketAnalyzer';
import { useTheme } from '../../../../Contexts/ThemeContext';
const PacketAnalyzer = () => {
  const { networkData, isMonitoring, startMonitoring, stopMonitoring } = usePacketAnalyzer();
  const [selectedPacket, setSelectedPacket] = useState(null);
  const startTimeRef = useRef(null);
  const [filters, setFilters] = useState('');
  const [displayMode, setDisplayMode] = useState('packets');
  const [viewConfig, setViewConfig] = useState({
    showHex: true,
    showTimeDelta: true,
    colorCode: true
  });
  const {isDarkMode} = useTheme();
  const [stats, setStats] = useState({
    avgBytes: 0,
    totalPackets: 0,
    packetsPerSecond: 0,
    bytesPerSecond: 0,
    protocols: {},
    topTalkers: {},
    errorPackets: 0,
    tcpConnections: 0,
    retransmissions: 0,
    connectionStates: {
      SYN_SENT: 0,
      SYN_RECEIVED: 0,
      ESTABLISHED: 0,
      FIN_WAIT_1: 0,
      FIN_WAIT_2: 0,
      TIME_WAIT: 0
    }
  });
  

  const updateStats = (packets) => {
    const now = Date.now();
    if (!startTimeRef.current) {
      startTimeRef.current = now;
    }
    
    const timeWindow = (now - startTimeRef.current) / 1000;
    const totalBytes = packets.reduce((sum, p) => sum + p.bytes, 0);
    const protocols = {};
    const talkers = {};
    const tcpStates = {
      SYN_SENT: 0,
      SYN_RECEIVED: 0,
      ESTABLISHED: 0,
      FIN_WAIT_1: 0,
      FIN_WAIT_2: 0,
      TIME_WAIT: 0
    };
    let retransmissions = 0;

    packets.forEach(packet => {
      protocols[packet.protocol] = (protocols[packet.protocol] || 0) + 1;
      
      const srcAddr = packet.srcIP || packet.address;
      const dstAddr = packet.dstIP || packet.destination;
      talkers[srcAddr] = (talkers[srcAddr] || 0) + packet.bytes;
      talkers[dstAddr] = (talkers[dstAddr] || 0) + packet.bytes;

      if (packet.protocol === 'TCP' && packet.state) {
        tcpStates[packet.state] = (tcpStates[packet.state] || 0) + 1;
      }

      if (packet.flags?.RTR) {
        retransmissions++;
      }
    });

    setStats({
      avgBytes: totalBytes / packets.length || 0,
      totalPackets: packets.length,
      packetsPerSecond: packets.length / timeWindow || 0,
      bytesPerSecond: totalBytes / timeWindow || 0,
      protocols,
      topTalkers: talkers,
      errorPackets: packets.filter(p => p.error).length,
      tcpConnections: Object.values(tcpStates).reduce((sum, count) => sum + count, 0),
      retransmissions,
      connectionStates: tcpStates
    });
  };

  
  // Update the monitoring toggle
  const toggleMonitoring = () => {
    if (!isMonitoring) {
      startTimeRef.current = Date.now();
      startMonitoring();
    } else {
      stopMonitoring();
    }
  };
  
  useEffect(() => {
    // Update stats whenever networkData changes
    if (networkData.length > 0) {
      updateStats(networkData);
    }
  }, [networkData]);
  
  const handleFilterChange = (e) => {
    setFilters(e.target.value.toLowerCase());
  };
  
  const filteredData = useMemo(() => {
    if (!filters) return networkData;
    
    return networkData.filter(packet => {
      // Convert packet data to lowercase string for searching
      const packetString = [
        packet.protocol,
        packet.address || packet.srcIP,
        packet.destination || packet.dstIP,
        packet.srcPort,
        packet.dstPort,
        Object.entries(packet.flags || {})
          .filter(([_, value]) => value)
          .map(([flag]) => flag)
          .join(' '),
        packet.bytes
      ].join(' ').toLowerCase();

      // Split filters by space and check if ALL parts match
      return filters.split(' ').every(filter => {
        // Handle special filters
        if (filter.includes('ip.addr==')) {
          const ip = filter.split('==')[1].trim();
          return (packet.address || packet.srcIP)?.includes(ip) || 
                 (packet.destination || packet.dstIP)?.includes(ip);
        }
        
        if (filter.includes('tcp.flags.')) {
          const flag = filter.split('.')[2].toUpperCase();
          return packet.flags && packet.flags[flag];
        }

        if (filter.startsWith('tcp')) {
          return packet.protocol === 'TCP';
        }

        if (filter.startsWith('dns')) {
          return packet.protocol === 'DNS';
        }

        if (filter.startsWith('http')) {
          return packet.protocol === 'HTTP';
        }

        // Default case: check if filter exists anywhere in packet string
        return packetString.includes(filter);
      });
    });
  }, [networkData, filters]);

  const getPacketDetails = (packet) => {
    const details = [];
    
    if (packet.protocol === 'TCP') {
      details.push(`State: ${packet.state || 'UNKNOWN'}`);
      if (packet.flags?.RTR) {
        details.push('Retransmission');
      }
    }

    if (packet.flags) {
      const flagStrings = Object.entries(packet.flags)
        .filter(([_, value]) => value)
        .map(([flag]) => {
          switch(flag) {
            case 'SYN': return packet.flags.ACK ? 'SYN-ACK' : 'SYN';
            case 'FIN': return packet.flags.ACK ? 'FIN-ACK' : 'FIN';
            case 'ACK': return packet.flags.SYN || packet.flags.FIN ? '' : 'ACK';
            default: return flag;
          }
        })
        .filter(Boolean);
      details.push(`Flags: ${flagStrings.join(' ')}`);
    }
    if (packet.payload) {
      if (packet.protocol === 'HTTP') {
        details.push(packet.payload.type === 'request' 
          ? `${packet.payload.method} ${packet.payload.resource}`
          : `${packet.payload.statusCode} ${packet.payload.headers['Content-Type'] || ''}`
        );
      } else if (packet.protocol === 'DNS') {
        details.push(`${packet.payload.type} ${packet.payload.queryType} ${packet.payload.domain}`);
      }
    }
    details.push(`Ports: ${packet.srcPort} → ${packet.dstPort}`);

    if (packet.protocol === 'TCP' && packet.sequenceNumber) {
      details.push(`Seq=${packet.sequenceNumber} Ack=${packet.expectedAck || 'N/A'}`);
    }

    return details.join(' | ');
  };

  const getRowColor = (packet, index) => {
    if (packet.protocol === 'TCP') {
      if (packet.flags?.RTR) return 'bg-red-100/50';
      if (packet.state === 'SYN_SENT') return 'bg-yellow-950/20';
      if (packet.state === 'SYN_RECEIVED') return 'bg-yellow-900/20';
      if (packet.state === 'FIN_WAIT_1' || packet.state === 'FIN_WAIT_2') return 'bg-red-950/20';
      return 'bg-black/20';
    }
    if (packet.protocol === 'DNS') return 'bg-sky-950/30';
    if (packet.protocol === 'HTTP') return 'bg-emerald-950/30';
    return index % 2 === 0 ? 'bg-black/10' : 'bg-black/5';
  };
  
  const getTextColor = (protocol, flags) => {
    const colors = {
      TCP: flags?.SYN ? 'text-yellow-500' :
           flags?.FIN ? 'text-red-500' :
           flags?.ACK ? 'text-green-600' : ` ${isDarkMode?"text-white":"text-black"} `,
      UDP: ` ${isDarkMode?"text-white":"text-black"} `,
      DNS: 'text-sky-600',
      HTTP: 'text-emerald-600',
      HTTPS: 'text-emerald-600'
    };
    return colors[protocol] || 'text-black';
  };

  const renderDetails = (packet) => (
    <div className="space-y-1">
      <div className="text-blue-600">Frame {packet.packetId}: {packet.bytes} bytes</div>
      <div className="pl-4 text-blue-600">Ethernet II</div>
      <div className="pl-8">Source MAC: {packet.srcMAC}</div>
      <div className="pl-8">Destination MAC: {packet.dstMAC}</div>
      <div className="pl-8">Source: {packet.srcIP || packet.address}</div>
      <div className="pl-8">Destination: {packet.dstIP || packet.destination}</div>
      <div className="pl-4 text-green-600">
        {packet.protocol} ({packet.srcPort} → {packet.dstPort})
        {packet.flags && (
          <div className="pl-4">
            Flags: {Object.entries(packet.flags)
              .filter(([_, value]) => value)
              .map(([flag]) => flag)
              .join(', ')}
          </div>
        )}
        {packet.payload && (
          <div className="pl-4 mt-2">
            <div className="font-bold">Payload:</div>
            <pre className="pl-4 text-sm">
              {JSON.stringify(packet.payload, null, 2)}
            </pre>
          </div>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      if (isMonitoring) {
        stopMonitoring();
      }
    };
  }, []);

  return (
    <div className={`h-screen flex flex-col ${isDarkMode?"bg-customBodyDarken1":"bg-white"} `}>
      <div className={`flex items-center gap-1 p-1 ${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-200"}  border-b border-gray-300`}>
      <button
          onClick={toggleMonitoring}
          className="p-1 hover:bg-gray-300 rounded"
        >
          {isMonitoring ? <Square size={16} /> : <Play size={16} />}
      </button>

      <div className={`flex items-center gap-1 px-2 ${isDarkMode?"text-black":""} `}>
        <input
          type="text"
          placeholder="Filter (e.g., tcp ip.addr==192.168.1.1 syn)"
          value={filters}
          onChange={handleFilterChange}
          className="px-2 py-1 border border-gray-300 rounded text-sm w-96"
        />
      </div>

      <div className="ml-auto flex items-center gap-4 text-sm">
        <div>
          Packets: {stats.totalPackets} | 
          PPS: {Math.round(stats.packetsPerSecond)} | 
          Bytes/s: {Math.round(stats.bytesPerSecond)}
        </div>
        <div>
          TCP Connections: {stats.tcpConnections} | 
          Retransmissions: {stats.retransmissions}
        </div>
        <div>
          Active States: 
          {stats.connectionStates.ESTABLISHED} EST | 
          {stats.connectionStates.SYN_SENT + stats.connectionStates.SYN_RECEIVED} SYNC
        </div>
      </div>
    </div>
      
      <div className="flex-1 overflow-hidden">
        <div className  ="h-1/2 overflow-auto border-b border-gray-300">
          <table className="w-full border-collapse text-sm">
            <thead>
              <tr className={`${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-100"}`}>
                <th className="px-4 py-1 text-left font-medium border-r border-gray-300">No.</th>
                <th className="px-4 py-1 text-left font-medium border-r border-gray-300">Time</th>
                <th className="px-4 py-1 text-left font-medium border-r border-gray-300">Source</th>
                <th className="px-4 py-1 text-left font-medium border-r border-gray-300">Destination</th>
                <th className="px-4 py-1 text-left font-medium border-r border-gray-300">Protocol</th>
                <th className="px-4 py-1 text-left font-medium border-r border-gray-300">Length</th>
                <th className="px-4 py-1 text-left font-medium">Info</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((packet, index) => (
                <tr
                  key={packet.packetId}
                  className={`${getRowColor(packet, index)} cursor-pointer
                    ${selectedPacket?.packetId === packet.packetId ? 'bg-blue-100' : ''}
                    hover:bg-blue-50`}
                  onClick={() => setSelectedPacket(packet)}
                >
                  <td className="px-4 py-1 border-r border-gray-200">{packet.packetId}</td>
                  <td className="px-4 py-1 border-r border-gray-200">
                    {new Date(packet.timestamp).toLocaleTimeString()}
                  </td>
                  <td className="px-4 py-1 border-r border-gray-200">
                    {`${packet.srcIP || packet.address}:${packet.srcPort}`}
                  </td>
                  <td className="px-4 py-1 border-r border-gray-200">
                    {`${packet.dstIP || packet.destination}:${packet.dstPort}`}
                  </td>
                  <td className={`px-4 py-1 border-r border-gray-200 ${getTextColor(packet.protocol, packet.flags)}`}>
                    {packet.protocol}
                  </td>
                  <td className="px-4 py-1 border-r border-gray-200">{packet.bytes}</td>
                  <td className="px-4 py-1">{getPacketDetails(packet)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {selectedPacket && (
          <div className="h-1/2 overflow-y-auto border-t border-gray-300 font-mono text-sm p-2">
            {renderDetails(selectedPacket)}
            {viewConfig.showHex && (
              <div className="mt-4 font-mono">
                {Array.from({ length: Math.ceil(selectedPacket.bytes / 16) }).map((_, i) => (
                  <div key={i} className="flex gap-4">
                    <span className="text-gray-500 w-16">
                      {(i * 16).toString(16).padStart(4, '0')}
                    </span>
                    <span className="flex-1">
                      {Array.from({ length: 16 }).map((_, j) => (
                        <span key={j} className="mx-1">
                          {Math.floor(Math.random() * 256).toString(16).padStart(2, '0')}
                        </span>
                      ))}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PacketAnalyzer;