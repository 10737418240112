import React, { useState, useEffect, useRef, Suspense } from 'react';
import parse from 'html-react-parser';
import {
  Play,
  RefreshCw,
  AlertCircle,
  FileText,
  Code,
  Terminal,
  CheckCircle,
  Loader,
  X,
  Eye,
  HelpCircle,
} from 'lucide-react';
import axios from 'axios';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import options from '../../Components/Lessons/OptionsQuill';
import { CodingModal } from '../../Components/Lessons/Coding/CodingModal';
import { useTheme } from '../../Contexts/ThemeContext';
import ReusableEditor from "../../Components/Challenge/Editor/ReusableEditor";
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';

// Lazy-loaded components
const Confetti = React.lazy(() => import('react-confetti'));
const MonacoEditor = React.lazy(() => import('@monaco-editor/react'));

export default function CodingLesson({ lesson, isSubscribed }) {
  const [code, setCode] = useState(lesson.initial_code || '');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(lesson.description ? 'description' : 'ide');
  const [isCorrect, setIsCorrect] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [inputPrompts, setInputPrompts] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [showInputModal, setShowInputModal] = useState(false);
  const [showExpectedOutput, setShowExpectedOutput] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const outputRef = useRef(null);
  const firstInputRef = useRef(null);
  const [runCount, setRunCount] = useState(0);
  const [showAdPopup, setShowAdPopup] = useState(false);
  const {isDarkMode} = useTheme();
  useEffect(() => {
    setCode(lesson.initial_code || '');
  }, [lesson]);

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  useEffect(() => {
    if (inputPrompts.length > 0) {
      setInputValues(Array(inputPrompts.length).fill(''));
    }
  }, [inputPrompts]);

  useEffect(() => {
    if (showInputModal && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [showInputModal]);

  useEffect(() => {
    if (runCount >= 6) {
      setShowAdPopup(true);
      setRunCount(0); // Reset the counter after showing the ad
    }
  }, [runCount]);

  const TabButton = ({ tab, icon: Icon, label }) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`py-2 px-4 flex items-center justify-center transition-colors duration-200 text-base ${
        activeTab === tab
          ? 'text-blue-600 border-b-2 border-blue-600'
          : `${isDarkMode?"text-white":"text-gray-600"} hover:text-blue-600`
      }`}
    >
      <Icon size={20} className="mr-2" />
      <span className={`font-medium hidden lg:block`}>{label}</span>
    </button>
  );

  const executeCode = async (inputs) => {
    try {
      if (lesson.expected_output) {
        const verificationResult = await verifyCodeAPI(
          code,
          lesson.expected_output,
          lesson.language || 'python',
          lesson.version || '3.x',
          inputs
        );

        if (verificationResult.correct) {
          setIsCorrect(true);
          setOutput(verificationResult.output);
          setShowConfetti(true);
          setTimeout(() => setShowConfetti(false), 3000);
        } else {
          setIsCorrect(false);
          setError(verificationResult.message);
          setOutput(verificationResult.output || '');
        }
      } else {
        // Use EMKC API if no expected output
        const response = await axios.post('https://emkc.org/api/v2/piston/execute', {
          language: lesson.language || 'python',
          version: lesson.version || '3.x',
          files: [{
            content: code
          }],
          stdin: inputs.join('\n')
        });

        if (response.data.run.output) {
          setOutput(response.data.run.output);
        } else if (response.data.run.stderr) {
          setError(response.data.run.stderr);
        }
      }
    } catch (err) {
      setError(
        `Error: ${err.response ? err.response.data.message : err.message}`
      );
      setOutput('');
    } finally {
      setIsLoading(false);
      setShowInputModal(false);
    }
  };

  const verifyCodeAPI = async (code, expectedOutput, language, version, inputs = []) => {
    const payload = {
      code,
      expectedOutput,
      language,
      version,
      inputs,
    };

    const response = await axios.post(
      'https://codeforcambodia.codes/codecademy/compile/verify',
      payload
    );
    return response.data;
  };

  const extractInputPrompts = (codeContent) => {
    let inputRegex;
    const programmingLanguage = lesson.language.toLowerCase();

    switch (programmingLanguage) {
      case 'python':
        inputRegex = /(?:\w+\s*=\s*)?input\s*\(\s*(?:'([^']*)'|"([^"]*)")?\s*\)/g;
        break;
      case 'java':
        inputRegex = /(?:\w+\s*=\s*)?scanner\.next(?:Int|Double|Line|Boolean)\s*\(\s*\)/gi;
        break;
      case 'c++':
        inputRegex = /(?:cin\s*>>\s*\w+;?)/g;
        break;
      case 'c':
        inputRegex = /scanf\s*\(\s*"[^"]+"\s*,\s*&\w+\s*\)/g;
        break;
      case 'c#':
        inputRegex = /(?:\w+\s*=\s*)?Console\.ReadLine\s*\(\s*\)/g;
        break;
      default:
        inputRegex = /(?:\w+\s*=\s*)?input\s*\(\s*(?:'([^']*)'|"([^"]*)")?\s*\)/g;
    }

    const prompts = [];
    let match;
    while ((match = inputRegex.exec(codeContent)) !== null) {
      const promptText = match[1] || match[2] || '';
      prompts.push({ prompt: promptText });
    }
    return prompts;
  };

  const runCode = async () => {
    setIsLoading(true);
    setOutput('');
    setError(null);
    setIsCorrect(false);
    setShowConfetti(false);
    setActiveTab('output');

    if (!isSubscribed) {
      setRunCount((prevCount) => prevCount + 1);
      setTimeout(() => setRunCount(0), 60000); // Reset the counter after 1 minute
    }

    const prompts = extractInputPrompts(code);
    setInputPrompts(prompts);

    if (prompts.length > 0) {
      setShowInputModal(true);
    } else {
      executeCode([]);
    }
  };

  const handleInputsSubmit = () => {
    const allInputsFilled = inputValues.every((input) => input.trim() !== '');
    if (!allInputsFilled) {
      setError('Please fill in all input fields.');
      return;
    }
    setIsSubmitting(true);
    executeCode(inputValues);
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
    if (error) setError(null);
  };

  const resetCode = () => {
    setCode(lesson.initial_code || '');
    setIsCorrect(false);
    setIsLoading(false);
    setShowConfetti(false);
    setError(null);
    setOutput('');
  };

  return (
    <div className={`flex flex-col ${isDarkMode?"bg-customBody text-customBodyText":"bg-white text-gray-800"} font-mono lg:h-screen pb-24 md:pb-12 lg:px-0 px-2`}>
      {showConfetti && (
        <Suspense fallback={<div>Loading...</div>}>
          <Confetti />
        </Suspense>
      )}

      {!isSubscribed && showAdPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-md shadow-xl border border-gray-300 overflow-hidden">
            <div className="p-4">
              <h2 className="text-lg font-semibold text-gray-700 Nokora">ជាវសមាជិកឥឡូវនេះ!</h2>
              <ul className="mt-2 space-y-2 text-gray-600 Nokora">
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  រៀនបានគ្រប់មេរៀន
                </li>
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  លែងមានការផ្សាយពាណិជ្ជកម្ម
                </li>
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  ជួយគាំទ្រវិស័យ ICT នៅកម្ពុជា
                </li>
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  ត្រឹម 2.99$ បើបង់ឆ្នាំ
                </li>
              </ul>
              <div className="mt-4 flex space-x-3">
                <button
                  onClick={() => setShowAdPopup(false)}
                  className="flex-1 px-4 py-2 border border-gray-300 rounded hover:bg-gray-50 transition-colors duration-200 Nokora"
                >
                  បិទ
                </button>
                <button
                  onClick={() => window.location.href = '/subscription'}
                  className="flex-1 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors duration-200 Nokora"
                >
                  ជាវឥឡូវនេះ
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isSubscribed ? <CodeForCambodiaHeader /> : <FreeTrialHeader />}

      <div className="flex-grow flex flex-col lg:flex-row overflow-hidden">
        {/* Tabs for mobile view */}
        <div className={`lg:hidden flex justify-between p-2  ${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-100"} border-b border-gray-200`}>
          {lesson.description && <TabButton tab="description" icon={FileText} label="Instructions" />}
          <TabButton tab="ide" icon={Code} label="Code Editor" />
          <TabButton tab="output" icon={Terminal} label="Output" />
        </div>

        {/* Instructions Panel */}
        {lesson.description && (
          <div
            className={`w-full lg:w-1/3 flex flex-col border-r border-gray-200 ${
              activeTab !== 'description' && 'hidden lg:flex'
            }`}
          >
            <div className="hidden lg:block flex-none p-2 bg-gray-100 border-b border-gray-200">
              <TabButton tab="description" icon={FileText} label="Instructions" />
            </div>
            <div className="flex-grow overflow-auto p-6 max-h-[calc(100vh-12rem)]">
              <div className="prose max-w-none text-base overflow-y-auto Nokora">
                {parse(lesson.description || '', options)}
              </div>
            </div>
          </div>
        )}

        {/* Code Editor Panel */}
        <div
          className={`w-full ${lesson.description ? 'lg:w-1/3' : 'lg:w-1/2'} flex flex-col border-r h-full border-gray-200 ${
            activeTab !== 'ide' && 'hidden lg:flex'
          }`}
        >
          <div className={`flex-none flex items-center justify-between p-2 ${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-100 border-b border-gray-200 "} `}>
            <div className="hidden lg:block">
              <TabButton tab="ide" icon={Code} label="Code Editor" />
            </div>
            <div className="flex items-center">
              <span className={`text-base font-medium ${isDarkMode?"text-white":"text-gray-600"}  mr-2`}>
                {lesson.language || 'Python'}
              </span>
              <span className="text-sm bg-gray-200 text-gray-700 px-2 py-1 rounded">
                v{lesson.version || '3.x'}
              </span>
            </div>
            <button
              className="text-gray-500 hover:text-blue-600 transition-colors duration-200"
              onClick={resetCode}
            >
              <RefreshCw size={20} />
            </button>
          </div>
          <div className="flex-grow relative flex overflow-hidden bg-white h-screen">
            <Suspense fallback={<div>Loading editor...</div>}>
            {/* implement ReusableEditor */}
              <ReusableEditor
                activeCodeTab={lesson.language} //Used activeCodeTab for Program Language 
                code={code}
                theme={`${isDarkMode?"vs-dark":"vs-light"}`}
                options={{
                  fontSize: 14,
                  minimap: { enabled: false },
                  scrollBeyondLastLine: false,
                }}
                onChange={(value) => setCode(value)}
              />

              {/* here */}
              {/* <MonacoEditor
                height="100%"
                language={lesson.language || 'python'}
                value={code}
                theme="vs-light"
                options={{
                  fontSize: 14,
                  minimap: { enabled: false },
                  scrollBeyondLastLine: false,
                }}
                onChange={(value) => setCode(value)}
              /> */}
              
            </Suspense>
          </div>
          <div className={`flex-none p-4 ${isDarkMode?"bg-customBody":"bg-gray-100"} border-t border-gray-200`}>
            <button
              onClick={runCode}
              disabled={isLoading}
              className={`w-full bg-blue-600 text-white px-4 py-3 rounded text-base hover:bg-blue-700 transition-colors duration-200 font-semibold flex items-center justify-center ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? (
                <RefreshCw size={20} className="mr-2 animate-spin" />
              ) : (
                <Play size={20} className="mr-2" />
              )}
              {isLoading ? 'Running...' : 'Run Code'}
            </button>
          </div>
        </div>

        {/* Output Panel */}
        <div
          className={`w-full ${lesson.description ? 'lg:w-1/3' : 'lg:w-1/2'} flex flex-col lg:h-full h-screen ${
            activeTab !== 'output' && 'hidden lg:flex'
          }`}
        >
          <div className={`hidden lg:block flex-none p-2 ${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-100 border-b border-gray-200"} `}>
            <TabButton tab="output" icon={Terminal} label="Output" />
          </div>
          <div className={`flex-grow overflow-hidden bg-customBodyDarken2 text-green-500 p-4 font-mono relative`}>
            <div className="flex items-center justify-between mb-2">
              <div className="flex space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
              </div>
              <div className="text-xs text-gray-500">Terminal</div>
            </div>
            {isLoading ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                <Loader size={24} className="animate-spin mr-2" />
                <span className="text-base">Running code...</span>
              </div>
            ) : (
              <div ref={outputRef} className="h-full overflow-auto">
                <pre className="whitespace-pre-wrap text-sm font-mono text-green-500">
                  {output}
                </pre>
                {error && (
                  <div className="text-red-500 mt-2 text-sm flex items-center">
                    <AlertCircle size={16} className="mr-1" />
                    {error}
                  </div>
                )}
                {isCorrect && (
                  <div className="text-green-500 mt-2 flex items-center">
                    <CheckCircle size={16} className="mr-1" />
                    Great job! You've completed the exercise successfully.
                  </div>
                )}
              </div>
            )}
          </div>
          {lesson.expected_output && (
            <div className={`${isDarkMode?"bg-customBodyDarken2":"bg-gray-100"} p-4 border-t border-gray-200 flex flex-row justify-between items-center`}>
              <button
                onClick={() => setShowExpectedOutput(!showExpectedOutput)}
                className="flex items-center text-blue-600 hover:text-blue-700 transition-colors duration-200"
              >
                <Eye size={16} className="mr-2" />
                {showExpectedOutput ? 'Hide' : 'Show'} Expected Output
              </button>
              {showExpectedOutput && (
                <pre className={`mt-2 p-2 ${isDarkMode?"bg-customBody":"bg-white"} border border-gray-300 rounded text-sm whitespace-pre-wrap`}>
                  {lesson.expected_output}
                </pre>
              )}
              <button
                onClick={() => setShowHelpModal(true)}
                className="ml-2 px-4 py-3 text-base font-semibold Nokora flex items-center text-gray-800"
              >
                <HelpCircle size={20} className="mr-2 text-gray-800" />
                កូដអត់ត្រូវ? ជួយ!
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Input Modal */}
      {showInputModal && (
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby="input-modal-title"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
        >
          <div className="bg-white rounded-lg w-full max-w-lg shadow-xl border border-gray-300 overflow-hidden max-h-[90vh] flex flex-col">
            <div className="flex-none flex items-center justify-between bg-gray-100 px-4 py-2 border-b border-gray-300">
              <h2 id="input-modal-title" className="text-lg font-semibold text-gray-700">
                Enter Inputs
              </h2>
              <button
                onClick={() => {
                  setShowInputModal(false);
                  setIsLoading(false);
                }}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                aria-label="Close modal"
              >
                <X size={20} />
              </button>
            </div>
            <div className="p-6 overflow-y-auto flex-grow">
              <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
                {inputPrompts.map((prompt, index) => (
                  <div key={index} className="mb-4">
                    <label className="block text-gray-700 mb-1">
                      {prompt.prompt || `Input ${index + 1}`}
                    </label>
                    <input
                      type="text"
                      ref={index === 0 ? firstInputRef : null}
                      className={`w-full px-3 py-2 border rounded focus:outline-none ${
                        error && inputValues[index].trim() === ''
                          ? 'border-red-500'
                          : 'border-gray-300'
                      }`}
                      value={inputValues[index] || ''}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      placeholder="Enter your input"
                    />
                  </div>
                ))}
                {error && (
                  <p className="text-red-500 text-sm">{error}</p>
                )}
              </form>
            </div>
            <div className="flex-none p-4 bg-gray-50 border-t border-gray-200">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors duration-200 flex items-center disabled:opacity-50 cursor-not-allowed"
                  onClick={handleInputsSubmit}
                  disabled={isSubmitting}
                >
                  <Play size={20} className="mr-2" />
                  {isSubmitting ? 'Running...' : 'Run Code'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Help Modal */}
      {showHelpModal && (
        <Suspense fallback={<div>Loading help...</div>}>
          <CodingModal showHelpModal={showHelpModal} setShowHelpModal={setShowHelpModal} />
        </Suspense>
      )}
    </div>
  );
}
