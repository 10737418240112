import React from 'react';
import { useTheme } from '../../Contexts/ThemeContext';

const InputField = ({ label, name, type, value, onChange, error, className }) => {
  const { isDarkMode } = useTheme();
  return (<div>
    <label htmlFor={name} className={`block text-sm font-medium ${isDarkMode?"text-customHeaderText " :"text-gray-700"} mb-1`}>
      <span className="text-orange-500">let</span> {label + ""} <span className="text-orange-500">=</span>
    </label>
    <input
      id={name}
      name={name}
      type={type}
      required
      value={value}
      onChange={onChange}
      className={className}
    />
    {error && (
      <p className="mt-1 text-xs text-red-600">{error}</p>
    )}
  </div>);
}


export default InputField;
