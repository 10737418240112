import React, { useEffect, useRef, useState } from 'react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css';

export default function TerminalEmulator({ serverState, commandHandler }) {
  const terminalRef = useRef(null);
  const terminalInstance = useRef(null);
  const fitAddonInstance = useRef(null);
  const [isContainerReady, setIsContainerReady] = useState(false);
  const printedLogsCount = useRef(0);
  const inputBuffer = useRef('');
  const commandHandlerRef = useRef(commandHandler);

  useEffect(() => {
    commandHandlerRef.current = commandHandler;
  }, [commandHandler]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (terminalRef.current) {
        setIsContainerReady(true);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isContainerReady || !terminalRef.current || terminalInstance.current) return;

    const term = new Terminal({
      cursorBlink: true,
      fontSize: 14,
      lineHeight: 1.2,
      fontFamily: "'JetBrains Mono', 'Fira Code', 'Cascadia Code', Menlo, monospace",
      rows: 15,
      cols: 80,
      scrollOnUserInput: true,
      theme: {
        background: '#1E1E1E',
        foreground: '#FFFFFF',
        black: '#1E1E1E',
        brightBlack: '#808080',
        red: '#F44747',
        brightRed: '#F44747',
        green: '#6A9955',
        brightGreen: '#6A9955',
        yellow: '#DCDCAA',
        brightYellow: '#DCDCAA',
        blue: '#569CD6',
        brightBlue: '#569CD6',
        magenta: '#C586C0',
        brightMagenta: '#C586C0',
        cyan: '#4EC9B0',
        brightCyan: '#4EC9B0',
        white: '#D4D4D4',
        brightWhite: '#FFFFFF',
        selectionBackground: '#264F78',
        cursor: '#FFFFFF',
        selectionForeground: '#FFFFFF',
        cursorAccent: '#000000',
      },
      allowTransparency: true,
      rendererType: 'canvas',
      scrollback: 5000,
      convertEol: true,
      disableStdin: false,
    });

    terminalInstance.current = term;
    term.open(terminalRef.current);

    const fit = new FitAddon();
    fitAddonInstance.current = fit;
    term.loadAddon(fit);

    term.write('\x1b[1;37m=== Terminal Initialized ===\x1b[0m\r\n');
    term.write('\x1b[1;36m● Express.js Terminal\x1b[0m\r\n\n');
    printPrompt(term);

    term.onKey(({ key, domEvent }) => {
      const char = key;
      const ev = domEvent;

      if (ev.key === 'Enter') {
        const command = inputBuffer.current.trim();
        inputBuffer.current = '';
        term.write('\r\n');

        if (command) {
          try {
            if (!commandHandlerRef.current) {
              term.writeln('\x1b[31mCommand handler not initialized\x1b[0m');
            } else {
              commandHandlerRef.current.handleCommand(command).then(result => {
                if (result) {
                  if (result.startsWith('Error:')) {
                    term.writeln('\x1b[31m' + result + '\x1b[0m');
                  } else {
                    term.writeln('\x1b[32m' + result + '\x1b[0m');
                  }
                }
              }).catch(err => {
                term.writeln('\x1b[31mError: ' + err.message + '\x1b[0m');
              });
            }
          } catch (err) {
            term.writeln('\x1b[31mError: ' + err.message + '\x1b[0m');
          }
        }
        printPrompt(term);
      } else if (ev.key === 'Backspace') {
        if (inputBuffer.current.length > 0) {
          term.write('\b \b');
          inputBuffer.current = inputBuffer.current.slice(0, -1);
        }
      } else if (ev.key.length === 1 && !ev.ctrlKey && !ev.altKey && !ev.metaKey) {
        inputBuffer.current += char;
        term.write(char);
      }
    });

    setTimeout(() => {
      if (fitAddonInstance.current) {
        try {
          fitAddonInstance.current.fit();
        } catch (e) {
          console.error('Initial fit failed:', e);
        }
      }
    }, 100);

    term.onScroll(() => {
      if (terminalRef.current) {
        const scrollPercentage = term.buffer.active.viewportY / (term.buffer.active.length - term.rows);
        terminalRef.current.scrollTop = scrollPercentage * terminalRef.current.scrollHeight;
      }
    });

    return () => {
      if (terminalInstance.current) {
        terminalInstance.current.dispose();
      }
    };
  }, [isContainerReady]);

  useEffect(() => {
    if (!terminalInstance.current) return;
    const term = terminalInstance.current;

    const newLogs = serverState.logs.slice(printedLogsCount.current);
    if (newLogs.length > 0) {
      term.write('\r\n');
      newLogs.forEach(log => {
        term.write('\x1b[36m' + log + '\x1b[0m\r\n');
      });
      printedLogsCount.current = serverState.logs.length;
      term.write('\r\n$ ');
    }
  }, [serverState.logs]);

  useEffect(() => {
    if (!isContainerReady || !fitAddonInstance.current) return;

    const handleResize = () => {
      if (fitAddonInstance.current) {
        try {
          fitAddonInstance.current.fit();
        } catch (e) {
          console.error('Resize fit failed:', e);
        }
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      setTimeout(handleResize, 0);
    });

    if (terminalRef.current) {
      resizeObserver.observe(terminalRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [isContainerReady]);

  const printPrompt = (term) => {
    term.write('\x1b[32m$\x1b[0m ');
  };

  return (
    <div className="relative w-full h-full bg-[#1E1E1E] border border-gray-800 shadow-lg">
      <div
        ref={terminalRef}
        className="absolute inset-0 p-4 font-mono w-full h-full "
        style={{
          opacity: isContainerReady ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
        }}
      />
    </div>
  );
}