import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, Sparkles, Settings, Crown, ChevronDown, ChevronUp, CreditCard } from 'lucide-react';
import { fetchSubscriptionStatus, getLastKnownSubscription } from '../Stripe/fetchSubscriptionStatus';
import { useTheme} from "../../Contexts/ThemeContext"
export default function SubscriptionBanner() {
    const {isDarkMode} = useTheme();
    const [hasSubscription, setHasSubscription] = useState(() => {
        const cached = getLastKnownSubscription();
        return cached?.hasSubscription || false;
    });
    const [hasHadTrial, setHasHadTrial] = useState(() => {
        const cached = getLastKnownSubscription();
        return cached?.hasHadTrial || false;
    });
    const [showReason, setShowReason] = useState(false);
    const navigate = useNavigate();

    const checkSubscription = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            const data = await fetchSubscriptionStatus(token);
            setHasSubscription(data.hasSubscription);
            setHasHadTrial(data.hasHadTrial);
        } catch (err) {
            console.error('Error checking subscription:', err);
        }
    };

    useEffect(() => {
        // Only fetch if cache is invalid
        if (!getLastKnownSubscription()) {
            console.log('fetch new check')
            checkSubscription();
        } else {
            console.log('use cache')
        }
    }, []);

    if (hasSubscription) {
        return (
            <div className="bg-gradient-to-r from-yellow-50 via-amber-50 to-yellow-50 border-b border-yellow-200 font-mono relative overflow-hidden">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,_rgba(250,204,21,0.1),transparent)] pointer-events-none" />
                <div className="px-4 py-3 md:py-2.5 relative">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0">
                        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4">
                            <div className="flex flex-wrap gap-2">
                                <div className="bg-gradient-to-r from-amber-500 to-yellow-500 text-white px-3 py-1 rounded-md text-sm font-medium inline-flex items-center shadow-sm">
                                    <Crown className="w-4 h-4 mr-1" />
                                    Pro Member
                                </div>
                            </div>
                            <span className="text-amber-900 text-sm md:text-base font-medium">
                                Enjoying unlimited pro features
                            </span>
                        </div>
                        <button 
                            onClick={() => navigate('/subscription')}
                            className="bg-gradient-to-r from-amber-500 to-yellow-500 hover:from-amber-600 hover:to-yellow-600 text-white px-6 py-1.5 rounded-md text-sm font-medium transition-all duration-200 flex items-center justify-center space-x-2 shadow-md hover:shadow-lg w-full md:w-auto group"
                        >
                            <Settings className="w-4 h-4 group-hover:rotate-45 transition-transform duration-200" />
                            <span>Manage Membership</span>
                        </button>
                    </div>
                </div>
                <div className="absolute top-0 right-0 w-64 h-full opacity-10 bg-[radial-gradient(circle_at_50%_50%,_rgba(250,204,21,1),transparent)] pointer-events-none" />
            </div>
        );
    }

    return (
        <div className={` ${isDarkMode?"bg-customBodyDarken1":"bg-gray-50 border-b border-gray-200 "}  font-mono`}>
            <div className="px-4 py-3 md:py-2.5">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0">
                    <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4">
                        <div className="flex flex-wrap gap-2">
                            <div className=" Nokora bg-blue-600 text-white px-3 py-1 rounded-md text-sm font-medium inline-flex items-center">
                                <Clock className="w-4 h-4 mr-1" />
                                តម្លៃពិសេស
                            </div>
                            <div className="bg-emerald-100 text-emerald-700 px-3 py-1 rounded-md text-sm font-medium">
                                57% OFF
                            </div>
                        </div>
                        <span className={`${isDarkMode?"text-white":"text-gray-600"}  text-sm md:text-base Nokora`}>
                            រៀនគ្រប់ថ្នាក់ត្រឹម <span className="text-blue-600 font-semibold">$2.99/month</span> <span className={` ${isDarkMode?"text-gray-200":"text-gray-500"}  text-xs Nokora`}>បើបង់ឆ្នាំ</span>
                        </span>
                    </div>
                    <button 
                        onClick={() => navigate('/subscription')}
                        className="bg-blue-600 hover:bg-blue-700 Nokora text-white px-4 py-1.5 rounded-md text-sm font-medium transition-colors flex items-center justify-center space-x-2 shadow-sm w-full md:w-auto"
                    >
                        <CreditCard className="w-4 h-4" />
                        <span>
                            បង់ឥឡូវ
                        </span>
                    </button>
                </div>
            </div>
            <button 
                onClick={() => setShowReason(!showReason)}
                className="w-full px-4 py-2 bg-gradient-to-r from-amber-100 to-yellow-100 hover:from-amber-200 hover:to-yellow-200 border-t border-b border-amber-200 transition-colors duration-200 flex items-center justify-between"
            >
                <span className="text-amber-900 font-medium text-sm Nokora">ហាក ក្រែង CodeForCambodia ថា free អី? 🤔</span>
                {showReason ? (
                    <ChevronUp className="w-5 h-5 text-amber-700" />
                ) : (
                    <ChevronDown className="w-5 h-5 text-amber-700" />
                )}
            </button>
            {showReason && (
                <div className={`px-4 py-2 ${isDarkMode?"bg-customBodyDarken1AndHalf":"bg-gray-100 border-t border-gray-200"} `}>
                    <p className={`text-sm  Nokora ${isDarkMode?"text-white":"text-gray-600"}`}>ហេតុអ្វីបានជាយើងក្លាយជា Hybrid?</p>
                    <ul className={`mt-2 space-y-2 text-sm ${isDarkMode?"text-customBodyText":"text-gray-600"}  Nokora`}>
                        <li>• ថ្នាក់កម្រិតដំបូងច្រើននៅតែ FREE: HTML+CSS, Python, C#, C++, C, Java, etc...</li>

                        <li>• យើងចង់ធ្វើឱ្យ website កាន់តែប្រសើរឡើងជាមួយនឹងការបង្កើន server អោយកាន់តែខ្លាំង</li>
                        <li>• យើងចង់ពង្រីកក្រុមការងារ និងបង្កើនមេរៀនថ្មីៗដែលមានប្រយោជន៍ និងកម្រិតខ្ពស់ដូចជា AI, Machine Learning, APIs, Data Science, etc...</li>
                        <li>• យើងចង់វិនិយោគលើការស្រាវជ្រាវបច្ចេកវិទ្យាថ្មីៗសម្រាប់ website</li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export const clearSubscriptionCache = () => {
    localStorage.removeItem('lastKnownSubscription');
};