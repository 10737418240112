import React, { useState, useEffect } from "react"
import { Code, Terminal, FileCode, Database, Globe, Server, Smartphone } from "lucide-react"
import ExpressLesson from "../../Pages/Contents/ExpressLesson"
import CodingLesson from "../../Pages/Contents/CodingLesson"
import FlutterLesson from "../../Pages/Contents/FlutterLesson"
import JupyterLesson from "../../Pages/Contents/JupyterLesson"
import SQLLesson from "../../Pages/Contents/SQLLesson"
import PHPLesson from "../../Pages/Contents/PHPLesson"
import NetworkingLesson from "../../Pages/Contents/NetworkingLesson"
import { useTheme } from "../../Contexts/ThemeContext"
const learningFeatures = [
  {
    icon: Code,
    title: 'Programming Fundamentals',
    description: 'ស្វែងយល់ពីមូលដ្ឋានគ្រឹះនៃការសរសេរកម្មវិធីជាមួយនឹងលំហាត់អន្តរកម្ម។ អនុវត្តអថេរ រង្វិលជុំ អនុគមន៍ និងច្រើនទៀត ដោយទទួលបានមតិត្រឡប់ភ្លាមៗ។',
    simulation: <CodingLesson lesson={{
      language: 'python',
      initial_code: `def calculate_fibonacci(n):
    if n <= 1:
        return n
    else:
        a, b = 0, 1
        for _ in range(2, n + 1):
            a, b = b, a + b
        return b

# Calculate first 10 Fibonacci numbers
for i in range(10):
    print(f"Fibonacci({i}) = {calculate_fibonacci(i)}")`,
    }} isSubscribed={true} />
  },
  {
    icon: Server,
    title: 'PHP Development',
    description: 'រៀនអភិវឌ្ឍន៍គេហទំព័រជាមួយ PHP តាមរយៈលំហាត់ជាក់ស្តែង។ បង្កើតគេហទំព័រឌីណាមិក និងស្វែងយល់ពីគោលគំនិតនៃការសរសេរកម្មវិធីខាងម៉ាស៊ីនមេ។',
    simulation: <PHPLesson isSubscribed={true} lesson={{
      sample_output: `<?php
// Define some variables
$name = "John";
$age = 25;
$languages = array("PHP", "JavaScript", "Python");

// Print a greeting
echo "Hello, my name is $name!<br>";
echo "I am $age years old.<br>";

// Print languages using a loop
echo "I know the following languages:<br>";
foreach($languages as $lang) {
    echo "- $lang<br>";
}

// Calculate and print a simple math operation
$result = 10 * 5;
echo "<br>10 x 5 = $result";
?>`,
      starter_php: `<?php
// Define some variables
$name = "John"; 
$age = 25;
$languages = array("PHP", "JavaScript", "Python");

// Print a greeting
echo "Hello, my name is $name!<br>";
echo "I am $age years old.<br>";

// Print languages using a loop
echo "I know the following languages:<br>";
foreach($languages as $lang) {
    echo "- $lang<br>";
}

// Calculate and print a simple math operation
$result = 10 * 5;
echo "<br>10 x 5 = $result";
?>`,
    }} />
  },

  {
    icon: FileCode,
    title: 'AI & Machine Learning',
    description: 'ស្វែងយល់ពីវិទ្យាសាស្ត្រទិន្នន័យ និងការសរសេរកម្មវិធី Python ដោយប្រើ Jupyter notebooks។ អនុវត្តការវិភាគទិន្នន័យ ការបង្ហាញរូបភាព និងគោលគំនិតម៉ាស៊ីនសិក្សា។',
    simulation: <JupyterLesson isSubscribed={true} />
  },
  {
    icon: Smartphone,
    title: 'Flutter Mobile App Development',
    description: 'បង្កើតកម្មវិធីចល័តពហុវេទិកាជាមួយ Flutter។ រៀនអភិវឌ្ឍន៍ UI គ្រប់គ្រងស្ថានភាព និងរបៀបបង្កើតកម្មវិធីដែលមានរូបរាងស្អាត។',
    simulation: <FlutterLesson
      lesson={{
        lesson_name: 'Hello, World!',
      }}
      isSubscribed={true} />
  },
  {
    icon: Database,
    title: 'SQL & Databases',
    description: 'អនុវត្តសំណួរ SQL និងការគ្រប់គ្រងមូលដ្ឋានទិន្នន័យក្នុងបរិយាកាសអន្តរកម្ម។ ជំនាញប្រតិបត្តិការ CRUD និងទំនាក់ទំនងមូលដ្ឋានទិន្នន័យស្មុគស្មាញ។',
    simulation: <SQLLesson isSubscribed={true} lesson={
      {
        initial_data: `CREATE TABLE users (
          id INT PRIMARY KEY AUTO_INCREMENT,
          name VARCHAR(255) NOT NULL,
          email VARCHAR(255) UNIQUE NOT NULL,
          created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
          status ENUM('active', 'inactive', 'banned') DEFAULT 'active'
        );

        CREATE TABLE posts (
          id INT PRIMARY KEY AUTO_INCREMENT, 
          user_id INT NOT NULL,
          title VARCHAR(255) NOT NULL,
          content TEXT,
          views INT DEFAULT 0,
          created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
          FOREIGN KEY (user_id) REFERENCES users(id)
        );

        INSERT INTO users (name, email) VALUES
          ('John Doe', 'john@example.com'),
          ('Jane Smith', 'jane@example.com');
          
        INSERT INTO posts (user_id, title, content) VALUES
          (1, 'First Post', 'Hello World!'),
          (2, 'Introduction', 'Nice to meet everyone!');`,
        starter_sql: `-- Write a query to get all posts with their author names
SELECT p.title, p.content, u.name as author
FROM posts p
JOIN users u ON p.user_id = u.id
WHERE u.status = 'active'
ORDER BY p.created_at DESC;`,
      }
    } />
  },
  {
    icon: Globe,
    title: 'Networking & Cybersecurity',

    description: '',
    simulation: <NetworkingLesson isSubscribed={true} />
  }


]

export default function HandsOnLearning() {
  const [selectedFeature, setSelectedFeature] = useState(learningFeatures[0])
  const { isDarkMode } = useTheme();
  const getCodeExample = (feature) => (`
  // ${feature.title} Example
  function ${feature.title.replace(/\s+/g, '')}() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      // Simulating ${feature.title} process
      const interval = setInterval(() => {
        setProgress(prev => (prev < 100 ? prev + 10 : 100));
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    return (
      <div className="${feature.title.toLowerCase().replace(/\s+/g, '-')}">
        <h2>${feature.title}</h2>
        <p>${feature.description}</p>
        <ProgressBar value={progress} />
      </div>
    );
  }
  `)

  return (
    <div className={`${isDarkMode ? "bg-customBody" : "bg-white"} py-8 sm:py-16 font-mono`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Line numbers - hidden on mobile */}
        <div className="absolute left-0 top-0 bottom-0 w-12 hidden sm:flex flex-col items-end pr-4 text-gray-400 text-xs border-r border-gray-200">
          {Array.from({ length: 30 }, (_, i) => (
            <div key={i} className="leading-6">{i + 1}</div>
          ))}
        </div>

        <div className="sm:pl-16">
          {/* Import statement */}
          <div className="text-gray-500 mb-6 text-sm sm:text-base">
            <Code className="inline-block mr-2" size={16} />
            <span className="text-purple-600">import</span>{' '}
            <span className="text-blue-600">{'{ HandsOnLearning }'}</span>{' '}
            <span className="text-purple-600">from</span>{' '}
            <span className="text-orange-500">'@/components'</span>;
          </div>

          <h2 className={`text-2xl sm:text-3xl font-bold text-center mb-2  `}>
            Learn By Doing
          </h2>
          <h3 className={`text-3xl sm:text-4xl Nokora font-bold text-center mb-8 sm:mb-12 ${isDarkMode ? "text-white" : "text-gray-800"}`}>
            រៀនដោយធ្វើ
          </h3>

          <div className="flex flex-col gap-8">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4">
              {learningFeatures.map((feature, index) => (
                <div
                  key={index}
                  className={`p-4 rounded-lg cursor-pointer transition-all duration-300 hover:-translate-y-1 
                    ${selectedFeature === feature
                      ? isDarkMode
                        ? 'bg-customBodyDarken2 border-2 border-customBodyDarken shadow-md transform -translate-y-1'
                        : 'bg-blue-100 border-2 border-blue-500 shadow-lg'
                      : isDarkMode
                        ? 'bg-customBody hover:bg-customBodyDarken1 hover:shadow-sm'
                        : 'bg-gray-100 hover:bg-gray-200 hover:shadow-md'
                    }
                    `}
                  onClick={() => setSelectedFeature(feature)}
                >
                  <div className="flex flex-col items-center text-center">
                    <feature.icon className="w-8 h-8 mb-2 text-blue-600" />
                    <h4 className={`text-sm font-semibold ${isDarkMode ? "text-gray-300" : "text-gray-800"}`}>{feature.title}</h4>
                  </div>
                </div>
              ))}
            </div>

            {selectedFeature && (
              <div className="bg-gray-100 rounded-lg overflow-hidden border border-gray-300 shadow-lg">
                <div className={`${isDarkMode ? "bg-customBodyDarken2" : "bg-gray-200"} px-4 py-2 flex justify-between items-center`}>
                  <span className={`${isDarkMode? "text-gray-300": "text-gray-700"} text-sm sm:text-base`}>{selectedFeature.title} Simulator</span>
                  <div className="flex space-x-2">
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-500"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-500"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-500"></div>
                  </div>
                </div>
                <div className={`p-4 ${isDarkMode? "bg-customBody" : "bg-white"}`}>
                  <div className={`${isDarkMode? "text-gray-400" : " text-gray-800"} h-100`}>
                    
                    {selectedFeature.simulation}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Export statement */}
          <div className="text-gray-500 mt-6 text-sm sm:text-base">
            <span className="text-purple-600">export default</span>{' '}
            <span className="text-blue-600">HandsOnLearning</span>;
          </div>
        </div>
      </div>
    </div>
  )
}