import React from 'react'
import { Shield, Eye, Database, Lock, Trash2, Globe, MessageCircle } from 'lucide-react'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import { useTheme } from '../Contexts/ThemeContext'
export default function PrivacyPolicyPage() {
  const {isDarkMode} = useTheme()
  return (
    <div className={`font-mono ${isDarkMode?"bg-customBody":"bg-white"} `}>
      <CodeForCambodiaHeader />
      <div className={`max-w-4xl mx-auto min-h-screen ${isDarkMode?"bg-customBody text-customHeaderText":"bg-white"}  py-12 px-4 sm:px-6 lg:px-8`}>
        <div className="text-center mb-12">
          <h1 className={`text-4xl font-bold ${isDarkMode?" text-customHeaderText":"text-gray-900"}  mb-4`}>{'<PrivacyPolicy />'}</h1>
          <p className={`text-xl  ${isDarkMode?"text-customBodyText":"text-gray-600"}`}>// Protecting your data is our priority</p>
        </div>

        <div className="space-y-8">
          <section className="border-2 border-gray-300 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Eye className="mr-2" />
              {'function dataCollection() {'}
            </h2>
            <p className={`${isDarkMode?" text-customHeaderText":"text-gray-700"} mb-4`} >
              {`// We collect the following types of information:`}
            </p>
            <ul className={`list-disc pl-6 ${isDarkMode?"text-customBodyText":"text-gray-700"}  mb-4`}>
              <li>Personal information (e.g., name, email address) when you register</li>
              <li>Usage data (e.g., pages visited, time spent on site)</li>
              <li>Device information (e.g., IP address, browser type)</li>
            </ul>
            <p className={`${isDarkMode?"text-customHeaderText":"text-gray-700"}`}>{'}'}</p>
          </section>

          <section className="border-2 border-gray-300 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Database className="mr-2" />
              {'function dataUsage() {'}
            </h2>
            <p className={`${isDarkMode?" text-customHeaderText":"text-gray-700"} mb-4`} >
              {`// We use your data to:`}
            </p>
            <ul className={`list-disc pl-6 ${isDarkMode?"text-customBodyText":"text-gray-700"}  mb-4`}>
              <li>Provide and improve our services</li>
              <li>Personalize your experience</li>
              <li>Communicate with you about our programs and updates</li>
            </ul>
            <p className={`${isDarkMode?"text-customHeaderText":"text-gray-700"}`}>{'}'}</p>
          </section>

          <section className="border-2 border-gray-300 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Lock className="mr-2" />
              {'function dataProtection() {'}
            </h2>
            <p className={`${isDarkMode?" text-customHeaderText":"text-gray-700"} mb-4`} >
              {`// We implement various security measures to protect your data:`}
            </p>
            <ul className={`list-disc pl-6 ${isDarkMode?"text-customBodyText":"text-gray-700"}  mb-4`}>
              <li>Encryption of sensitive information</li>
              <li>Regular security audits</li>
              <li>Limited access to personal information by employees</li>
            </ul>
            <p className={`${isDarkMode?"text-customHeaderText":"text-gray-700"}`}>{'}'}</p>
          </section>

          <section className="border-2 border-gray-300 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Globe className="mr-2" />
              {'function dataSharing() {'}
            </h2>
            <p className={`${isDarkMode?" text-customHeaderText":"text-gray-700"} mb-4`} >
              {`// We may share your information with:`}
            </p>
            <ul className={`list-disc pl-6 ${isDarkMode?"text-customBodyText":"text-gray-700"}  mb-4`}>
              <li>Service providers who assist in our operations</li>
              <li>Law enforcement when required by law</li>
              <li>Partners with your explicit consent</li>
            </ul>
            <p className={`${isDarkMode?"text-customHeaderText":"text-gray-700"}`}>{'}'}</p>
          </section>

          <section className="border-2 border-gray-300 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Shield className="mr-2" />
              {'function userRights() {'}
            </h2>
            <p className={`${isDarkMode?" text-customHeaderText":"text-gray-700"} mb-4`} >
              {`// You have the right to:`}
            </p>
            <ul className={`list-disc pl-6 ${isDarkMode?"text-customBodyText":"text-gray-700"}  mb-4`}>
              <li>Access and update your personal information</li>
              <li>Request deletion of your data</li>
              <li>Opt-out of marketing communications</li>
            </ul>
            <p className={`${isDarkMode?"text-customHeaderText":"text-gray-700"}`}>{'}'}</p>
          </section>

        </div>

        <div className={`mt-12 text-center ${isDarkMode?"text-customBodyText":"text-gray-600"} `}>
          <p>Last updated: {new Date().toLocaleDateString()}</p>
          <p>{'// CodeForCambodia reserves the right to update this policy at any time.'}</p>
        </div>
      </div>
    </div>
  )
}